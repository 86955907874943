import MonthSelect from "../../home/components/MonthSelect";
import GraphLine from "../../home/components/LineGraph";
import SlantArrow from "../../../../../assets/icons/SlantArrow.svg";
import GrowthIcon from "../../../../../assets/icons/GrowthIcon.svg";
import EmptyState from "../../../../../components/ui/EmptyState";

const data = [15000, 18000, 22000, 19000, 25000];

function StatisticsSection() {
  const handleMonth = (_arg: string) => {};

  const growthArray = [
    {
      title: "Earning growth (This year)",
      icon: SlantArrow,
      increaseRate: "+98.1%",
    },
    {
      title: "Earning growth (Last year)",
      icon: SlantArrow,
      increaseRate: "+98.1%",
    },
    {
      title: "Earning growth (Next 5 year)",
      icon: SlantArrow,
      increaseRate: "+98.1%",
    },
    {
      title: "Earnings",
      icon: SlantArrow,
      increaseRate: "+98.1%",
    },
    {
      title: "Market cap",
      icon: SlantArrow,
      increaseRate: "+98.1%",
    },
  ];

  return (
    <div className="w-full relative z-[1] overflow-x-auto">
      <div className="h-full grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-[2rem]">
        <div className="lg:col-span-3 md:col-span-2 col-span-1  bg-white border border-gs-borderInput rounded-[14.5px]">
          <div className="flex justify-between items-end pl-5 pr-3 pt-4">
            <p className="text-[16px] font-semibold text-[#0A112F]">
              Portfolio Performance{" "}
            </p>
            <MonthSelect setMonth={(data) => handleMonth(data)} />
          </div>
          {/* <h2 className="pl-5 text-[27px] font-bold py-1">₦0</h2> */}
          <div className="w-full pb-3">
            <GraphLine data={data} />
          </div>
        </div>

        <div className="lg:col-span-2 col-span-1 bg-white border border-gs-borderInput rounded-[14.5px]">
          <div className="px-5 pt-4 pb-6">
            <p className="text-[16px] font-semibold text-gs-text">
              Growth & Valuation
            </p>
            <hr className="border border-[rgba(220,228,232,0.40)] mt-[17px] mb-[24px]" />
            {growthArray.length ? (
              <div className="flex flex-col gap-[18px]">
                {growthArray.map((investment, i) => (
                  <>
                    <div className="flex justify-between items-center" key={i}>
                      <p className="font-medium text-gs-subSubText">
                        {investment.title}
                      </p>
                      <div className="flex items-center gap-[13px]">
                        <img src={investment.icon} alt="arrow" />
                        <p className="manrope text-[15px] font-semibold text-[#0A9B4E]">
                          {investment.increaseRate}
                        </p>
                      </div>
                    </div>
                    {i !== growthArray.length - 1 && (
                      <hr className="border border-[rgba(220,228,232,0.40)]" />
                    )}
                  </>
                ))}
              </div>
            ) : (
              <EmptyState emptyText="No valuation yet" emptyIcon={GrowthIcon} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsSection;
