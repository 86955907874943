import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter, } from "react-router-dom";
import  store  from "./redux/store";
import { routes } from "./utils/routes";
import AppMiddleware from './utils/AppMiddleware';



const router = createBrowserRouter(routes);

function App() {

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppMiddleware>
          <RouterProvider router={router} />
        </AppMiddleware>
      </Provider>
      {/* <PushNotification /> */}
    </React.StrictMode>
    );
};


export default App;
