// add navigation links to the array 

export const navlinks = [
    {
        to: 'home',
        name: 'Home',
        icon: 'iconHome.svg',
        sub_links: null
    },
    {
        to: 'wallet',
        name: 'Wallet',
        icon: 'iconWallet.svg',
        sub_links: null
    },
    {
        to: 'transfer',
        name: 'Transfer',
        icon: 'iconTransfer.svg',
        sub_links: null
    },
    {
        to: 'p2p',
        name: 'P2P',
        icon: 'iconLoan.svg',
        sub_links: null
    },
    {
        to: 'investment',
        name: 'Invest',
        icon: 'iconInvest.svg',
        sub_links: null
    },
    // {
    //     to: '#',
    //     name: 'Projects',
    //     icon: 'iconProject.svg',
    //     sub_links: [
    //         {
    //             to: 'projects',
    //             name: 'My Projects',
    //             icon: 'iconCircle.svg',
    //         },
    //         {
    //             to: 'marketplace',
    //             name: 'Marketplace',
    //             icon: 'iconCircle.svg',
    //         },
    //     ]
    // },
    {
        to: 'messages',
        name: 'Messages',
        icon: 'iconMessage.svg',
        sub_links: null
    },
    {
        to: 'settings',
        name: 'Settings',
        icon: 'iconSetting.svg',
        sub_links: null
    },
    
]



// for auth page 
export const authPretexts = [
    { 
        headerText: "Let’s empower your financial task today with Findash.", 
        subText: "The one-stop platform for all financial management of small and medium-sized business."
    },
    { 
        headerText: "Let’s empower your financial task today with Findash.", 
        subText: "The one-stop platform for all financial management of small and medium-sized business."
    },
    { 
        headerText: "Let’s empower your financial task today with Findash.", 
        subText: "The one-stop platform for all financial management of small and medium-sized business."
    },
    { 
        headerText: "Let’s empower your financial task today with Findash.", 
        subText: "The one-stop platform for all financial management of small and medium-sized business."
    },
];




export const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']