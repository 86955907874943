import React, {ButtonHTMLAttributes} from "react";

// Define an interface to extend the button type
interface ExtendedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    category: "link" | "button";
    to?: string;
    text?: any;
    isInverted: boolean;
    handleClick?: () => void;
    btnStyle?: string;
    btnIcon?: string;
    disabled?: boolean;
}

function Button({
                    category,
                    to,
                    text,
                    isInverted,
                    handleClick,
                    btnStyle,
                    type,
                    btnIcon,
                    disabled
                }: ExtendedButtonProps) {
    if (category === "link") {
        return (
            <a
                href={to}
                onClick={handleClick}
                className={`${btnStyle} ${
                    isInverted ? "bg-white " : "bg-gs-primary text-white"
                } border-[1px] rounded-[12px] text-[14px] cursor-pointer`}
            >
                {text}
            </a>
        );
    }
    if (category === "button") {
        return (
            <button
                type={type}
                onClick={handleClick}
                disabled={disabled}
                className={`${btnStyle} ${
                    isInverted
                        ? "bg-white text-gs-primary border-gs-primary"
                        : "bg-gs-primary text-white"
                } border rounded-[12px] text-[14px] cursor-pointer`}
            >
                {btnIcon && <img src={btnIcon} alt="icon"/>}
                {text}
            </button>
        );
    }
    return <></>;
}

export default Button;
