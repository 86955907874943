function getDateString(date: any) {
    if(!date) return;
    // get time in the format --> 'November 08 , 2023'
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return formattedDate;
}

export default getDateString;