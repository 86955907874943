import { useRef } from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import copyImg from "../../../../../assets/icons/copy-outline-green.svg";
import { handleCopy } from "../../../../../components/custom-hooks";

interface RemoveModalProps {
    setActiveFaTab: (_arg:number) => void,
    closeModal: () => void;
}

const code = '234567';

function CopyCodeModal({ closeModal, setActiveFaTab }: RemoveModalProps) {
    const codeRef: any = useRef(null)
    const btnStyle =  "w-full px-[20px] py-[12px] font-[500] text-[15px] leading-[25.5px] rounded-[10px]";

    return (
        <div>
            <div className="w-fit float-right mb-[1.2rem]">
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <div className="w-fit clear-both px-[50px] mx-auto py-[17px] flex jusitfy-center items-center gap-x-2 rounded-[14px] bg-gs-primaryLess1 text-gs-primary">
                <p ref={codeRef} className="tracking-[1rem] text-[20px] leading-[25.5px] font-[400]"> {code} </p> {/* generated code here */}
                <img onClick={() => handleCopy(codeRef, closeModal())} src={copyImg} alt="copy" className="hover:cursor-pointer" />
            </div>

            <h3 className="text-[18px] text-center font-[700] leading-[32px] text-gs-text mt-[3rem] mb-[.7rem]">Add manually</h3>

            <p className="w-full text-center text-[15.5px] font-[400] tracking-wide leading-[27.5px] text-gs-subSubText mt-2.5">
            Tap to copy the key above and paste it in your authenticator to start generating tokens. Hit done, when you’ve added the key above successfully.
            </p>

            <div className="lg:w-[80%] mx-auto lg:flex grid gap-x-5 mt-7">
                <button onClick={() => setActiveFaTab(2)} className={`${btnStyle} bg-gs-primaryLess1 text-gs-primary`}>Scan QR</button>
                <button onClick={() => setActiveFaTab(4)} className={`${btnStyle} bg-gs-primary text-white`}>Done</button>
            </div>
        </div>
    )
}


export default CopyCodeModal;