import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "../../../components/ui";
import UploadButton from "../../../assets/icons/UploadButton.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import NotificationService from "../../../services/notification.service";
import { ScaleLoader } from "react-spinners";


const  countriesArr = require('../../../utils/countries.json');

interface Values {
  gender: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  address: string;
  occupation: string;
  dateOfBirth: string;
}

// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-[12px] leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";
const rowContainerStyling =
  "flex flex-col md:flex-row max-w-[467px] w-full gap-7 justify-between";

// FORM VALIDATION
const ValidationSchema = Yup.object({
  gender: Yup.string().required("Please select an option"),
  country: Yup.string().required("Please select an option"),
  state: Yup.string().required("Please select an option"),
  city: Yup.string().required("City is required"),
  zipCode: Yup.string().required("Zip Code is required"),
  address: Yup.string().required("Address is required"),
  occupation: Yup.string().required("Occupation is required"),
  dateOfBirth: Yup.string().required("Date of Birth is required"),
  // image: Yup.mixed().required("Image is required"),
});

const initialValues: Values = {
  gender: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  address: "",
  occupation: "",
  dateOfBirth: "",
};

// const url = process.env.REACT_APP_CLOUDINARY_URL

function AccountSetupFormStepTwo({ onNextStep, setStepOneData }: any) {
  const [imageContent, setImageContent] = useState<any>(null);
  const [imageError, setImageError] = useState<null | string>(null);
  const [ selectedCountry, setSelectedCountry ] = useState<string>('');
  const [ selectedState, setSelectedState ] = useState<any>({})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(selectedCountry !== ''){
      const filteredCountryData = countriesArr.find((country: any) => country.name.toLowerCase() === selectedCountry.toLowerCase());
      setSelectedState(filteredCountryData);
      // console.log(filteredCountryData);
    }
  }, [selectedCountry]);


  const handleForm = async(values: Values) => {
    if(!imageContent){
      setImageError('Image is required!');
      return;
    }

    const file = imageContent;
    const formData: any = new FormData();

    setLoading(true)
    try {
      formData.append("file", file);
      formData.append("upload_preset", "ize5x0kg");
      const res = await axios.post("https://api.cloudinary.com/v1_1/okafohenrie/image/upload", formData);
      setLoading(false);

      if(res?.statusText.toLowerCase() != 'ok') return;
        
      const payload = {...values, utilityBillUrl: res?.data?.secure_url}
      setStepOneData(payload)
      onNextStep();

    }catch(err: any){
      setLoading(false);
      NotificationService.error({
        message: 'Failed!',
        addedText: err?.message
      })
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleForm}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="py-8 flex flex-col gap-6">
          {/* GENDER */}
          <div className={labelContainerStyling}>
            <div className="flex gap-x-3">
              <label htmlFor="gender" className={labelStyling}>
                Gender
              </label>
              {errors.gender && touched.gender ? (
                <p className={errorStyling}>{errors.gender}</p>
              ) : null}
            </div>
            <div className={inputContainerStyling}>
              <Field
                as="select"
                id="gender"
                name="gender"
                className={fieldStyling}
              >
                <option value="" disabled selected hidden>
                  Select gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Field>
            </div>
          </div>
          {/* ROW 1 */}
          <div className={rowContainerStyling}>
            {/* COUNTRY */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="country" className={labelStyling}>
                  Country/Region
                </label>
                {errors.country && touched.country ? (
                  <p className={errorStyling}>{errors.country}**</p>
                ) : null}
              </div>
              <div className={inputContainerStyling}>
                <Field
                  as="select"
                  id="country"
                  name="country"
                  className={`${fieldStyling} hover:cursor-pointer`}
                  value={selectedCountry}
                  onChange={(e: any) => {
                    setFieldValue('country', e.target.value)
                    setSelectedCountry(e.target.value)
                  }}
                >
                  <option value="" disabled selected hidden>
                    Select country
                  </option>
                  {countriesArr.map((country: any) => (
                    <option 
                      className="capitalize" 
                      key={country.id} 
                      value={country.name}
                      >
                        {country.name}
                      </option>
                  ))}
                </Field>
              </div>
            </div>

            {/* STATE */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="state" className={labelStyling}>
                  State/Province
                </label>
                {errors.state && touched.state ? (
                  <p className={errorStyling}>{errors.state}**</p>
                ) : null}
              </div>
              <div className={inputContainerStyling}>
                <Field
                  as="select"
                  id="state"
                  name="state"
                  className={`${fieldStyling} hover:cursor-pointer`}
                >
                  <option value="" disabled selected hidden>
                    Select state
                  </option>
                  {selectedState?.states?.map((availableState: any) => (
                    <option 
                      className="capitalize" 
                      key={availableState.id} 
                      value={availableState.name}
                      onClick={() => setSelectedState(availableState)}
                      >
                      {availableState.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>

          {/* ROW 2 */}
          <div className={rowContainerStyling}>
            {/* CITY */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="city" className={labelStyling}>
                  City
                </label>
                {errors.city && touched.city ? (
                  <p className={errorStyling}>{errors.city}</p>
                ) : null}
              </div>
              <div className={inputContainerStyling}>
                <Field
                  id="city"
                  name="city"
                  placeholder="Enter city"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>

            {/* ZIP CODE */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="zipCode" className={labelStyling}>
                  Zip Code
                </label>
                {errors.zipCode && touched.zipCode ? (
                  <p className={errorStyling}>{errors.zipCode}</p>
                ) : null}
              </div>
              
              <div className={inputContainerStyling}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  placeholder="Enter zip code"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>
          </div>

          {/* ADDRESS */}
          <div className={labelContainerStyling}>
            <div className="flex gap-x-3">
              <label htmlFor="address" className={labelStyling}>
                Address
              </label>
              {errors.address && touched.address ? (
                <p className={errorStyling}>{errors.address}</p>
              ) : null}
            </div>
            <div className={inputContainerStyling}>
              <Field
                id="address"
                name="address"
                placeholder="Enter address"
                type="address"
                className={fieldStyling}
              />
            </div>
          </div>

          {/* ROW 3 */}
          <div className={rowContainerStyling}>
            {/* DATE OF BIRTH */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="dateOfBirth" className={labelStyling}>
                  Date of Birth
                </label>
                {errors.dateOfBirth && touched.dateOfBirth ? (
                  <p className={errorStyling}>{errors.dateOfBirth}</p>
                ) : null}
              </div>
              <div className={inputContainerStyling}>
                <Field
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeholder="Enter dateOfBirth"
                  type="date"
                  className={`${fieldStyling} uppercase`}
                />
              </div>
            </div>

            {/* OCCUPATION */}
            <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
              <div className="flex gap-x-3">
                <label htmlFor="occupation" className={labelStyling}>
                  Occupation
                </label>
                {errors.occupation && touched.occupation ? (
                  <p className={errorStyling}>{errors.occupation}</p>
                ) : null}
              </div>
              <div className={inputContainerStyling}>
                <Field
                  id="occupation"
                  name="occupation"
                  placeholder="Enter occupation"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>
          </div>

          {/* IMAGE UPLOAD */}
          <div className={`${labelContainerStyling} w-full`}>
            <label htmlFor="image" className={labelStyling}>
              Upload a recent utility bill (.Png,Pdf,Jpg,)
              {imageError ? (
                <p className={errorStyling}>{imageError}</p>
              ) : null}
              
              <div className={`${inputContainerStyling} mt-[10px]`}>
                <input
                  name="image"
                  id="image"
                  accept="image/*"
                  type="file"
                  className="hidden"
                  onChange={(e: any) => setImageContent(e.target.files[0])}
                />
                <div className="flex items-center justify-between">
                  <p className={fieldStyling}>{imageContent ? imageContent?.name : "Upload utility bill"}</p>
                  <img
                    src={UploadButton}
                    alt="upload"
                    className="w-[78px] h-[33px]"
                  />
                </div>
              </div>
            </label>
          </div>

          <div className="bg-gs-shadesOfBlue98 py-3 px-[43px] rounded-lg border border-dashed border-gs-mainColorPrimary">
            <h3 className="text-gs-text text-sm font-bold leading-[18.2px]">
              Note
            </h3>
            <ul className="list-disc text-gs-text text-sm leading-[27.5px] font-light">
              <li>Upload a recent bill from past 3 months</li>
              <li>Max file size 3mb</li>
            </ul>
          </div>

          <Button
            isInverted={false}
            type="submit"
            // handleClick={onNextStep}
            text={loading ? <ScaleLoader color='#fff' height={12} width={4} /> : "Continue"}
            category="button"
            btnStyle="w-full py-3 px-6"
          />
        </Form>
      )}
    </Formik>
  );
}

export default AccountSetupFormStepTwo;
