import { Field } from "formik";
import { ChangeEvent, useState } from "react";
import ShowPassword from "../../assets/icons/eye-slash.svg";
import HidePassword from "../../assets/icons/eye.svg";

interface PasswordInputProps {
    handlePasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

function PasswordInput({ handlePasswordChange, placeholder }: PasswordInputProps) {
    const [showPassword, setShowPassword] = useState(false);

   const inputContainerStyling = "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
   const fieldStyling = "w-full outline-none text-sm leading-[14px] text-gs-subText";

    return (
        <div className={`${inputContainerStyling} flex items-center`}>
            <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder={placeholder || '**********'}
                className={fieldStyling}
                onChange={handlePasswordChange}
            />
            <img
                src={showPassword ? ShowPassword : HidePassword}
                alt="Show Password"
                className="w-6 h-6"
                onClick={() => setShowPassword((prev: boolean) => !prev)}
            />
        </div>
    )
}

export default PasswordInput;