import { Field, Form, Formik } from "formik";
import { Button } from "../../../components/ui";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthService from "../../../services/auth.service";
import NotificationService from "../../../services/notification.service";
import ls from 'localstorage-slim';
import { ScaleLoader } from "react-spinners";

interface Values {
  email?: string;
}

// INITIAL FORM DATA 
const initialValues: Values = { email: "" };

// FORM VALIDATION
const ValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-sm leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";


  // LOCAL
ls.config.encrypt = true;

// COMPONENT
function ResetPasswordForm() {
  const navigate = useNavigate();
  const authService = new AuthService();

  const [loading, setLoading] = useState(false);

  const handleReset = async(values: Values) => {
    if(!values.email) return;

    setLoading(true);
    try{
      const response = await authService.forgotPassword({ email: values.email});
      setLoading(false);
      if(!response.status){
        NotificationService.error({
          message: 'Password recovery failed!',
          addedText: response?.message
        });
        return;
      }
      ls.set('gs-pwd-otp', response.data);
      ls.set('gs-u-email', values.email)
      navigate("/auth/reset-code");
    }catch(err){
      setLoading(false);
      console.log(err)
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleReset}
    >
      {({ errors, touched }) => (
        <Form className="py-8 flex flex-col gap-6">
          {/* EMAIL */}
          <div className={labelContainerStyling}>
            <label htmlFor="email" className={labelStyling}>
              Email
            </label>
            {errors.email && touched.email ? (
              <p className={errorStyling}>{errors.email}</p>
            ) : null}
            <div className={inputContainerStyling}>
              <Field
                id="email"
                name="email"
                placeholder="Enter your email address"
                type="email"
                className={fieldStyling}
              />
            </div>
          </div>

          <Button
            isInverted={false}
            // handleClick={handleReset}
            type="submit"
            text={loading ? <ScaleLoader color='#fff' height={12} width={4} /> : "Continue"}
            category="button"
            btnStyle="w-full py-3 px-6"
          />
        </Form>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
