import { useState } from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { PasswordInput } from "../../../../../components/ui";

interface UpdatePasswordModalProps {
    data?: any,
    closeModal: () => void;
}

const initialState = {
    old_password: '',
    new_password: '',
    confirm_new_password: ''
}

function UpdatePasswordModal({ closeModal }: UpdatePasswordModalProps){
    const [ formData, setFormData ] = useState(initialState);
    const [ error, setError ] = useState<string>('')

    const btnStyle = "w-[47%] text-[15px] rounded-[14px] text-center px-[14px] py-[12px] tracking-wide font-[500]";
    const labelStyle = "text-[14px] font-[300] leading-[14px] text-gs-text mb-2"

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(formData.new_password !== formData.confirm_new_password){
            setError('Passwords must match');
            return;
        }else{
            setError('');
            // make api request 
        }


    }

    return (
        <div>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem]">Change Password</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="grid mt-8">
                    <label className={`${labelStyle}`}>Enter your old password</label>
                    <PasswordInput 
                        handlePasswordChange={(e) => setFormData({...formData, old_password: e.target.value})}
                        placeholder="Old password"
                    />
                </div>
                <div className="grid mt-7">
                    <label className={`${labelStyle}`}>Enter your new password</label>
                    <PasswordInput 
                        handlePasswordChange={(e) => setFormData({...formData, new_password: e.target.value})}
                        placeholder="New password"
                    />
                </div>
                <div className="grid mt-7">
                    <label className={`${labelStyle}`}>Confirm  your new password</label>
                    <PasswordInput 
                        handlePasswordChange={(e) => setFormData({...formData, confirm_new_password: e.target.value})}
                        placeholder="Confirm password"
                    />
                    {error !== '' && <small className="text-gs-red">{error}</small>}
                </div>

                <div className="flex flex-wrap  justify-between mt-7">
                    <button onClick={() => closeModal()} className={`${btnStyle} text-gs-primary bg-gs-primaryLess1 `}>Cancel</button>
                    <button type="submit" className={`${btnStyle} text-white bg-gs-primary`}>Change password</button>
                </div>
            </form>

        </div>
    )
}

export default UpdatePasswordModal;