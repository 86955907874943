import {useState} from "react";

const isCompleted = true;

interface AccountSetupVerificationProps {
    accountCreated: boolean;
    createAccount: () => Promise<void>;
}

const AccountSetupVerification:
    React.FC<AccountSetupVerificationProps>
    = ({accountCreated, createAccount}) => {

    const [isLoading, setIsLoading] = useState(false); // State for loading popup

    const numberTagStyle = `h-[15px] w-[15px] text-[8px] justify-center flex items-center rounded-full text-white ${isCompleted ? 'bg-gs-primary' : 'bg-gs-subText'}`;
    const stepperBoxStyle = `flex gap-x-3 items-center shadow shadow-[#D1E2F2]/[.08] px-[10px] py-[8px] rounded-[8px] ${isCompleted ? 'bg-[#E4E4E7]' : 'bg-white'}`;
    const textTagStyle = `text-[14px] font-[500] text-gs-text tracking-wide ${isCompleted ? 'text-gs-text' : 'text-gray-400'}`

    const handleButtonClick = async () => {
        setIsLoading(true); // Show loader popup

        // Call the passed createAccount function and wait for it to complete
        await createAccount();

        setIsLoading(false); // Hide the loader after process completes
    };

    const Steps = () => {
        return (
            <div className="relative top-[3rem]">
                <hr className="w-[50%] border-[0.5] border-gs-subText"/>
                <div className="flex gap-x-[3rem] absolute -top-[18px] z-[3]">
                    <div className={`${stepperBoxStyle}`}>
                        <div className={`${numberTagStyle}`}>1</div>
                        <p className={`${textTagStyle}`}>Identity Verification</p>
                    </div>

                    <div className={`${stepperBoxStyle}`}>
                        <div className={`${numberTagStyle}`}>2</div>
                        <p className={`${textTagStyle}`}>Document Verification</p>
                    </div>

                    {accountCreated ? (
                        <div className={`${stepperBoxStyle}`}>
                            <div className={`${numberTagStyle}`}>3</div>
                            <p className={`${textTagStyle}`}>Account Created</p>
                        </div>
                    ) : (
                        <button
                            className={`${stepperBoxStyle} ${isLoading ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-200 text-white cursor-pointer hover:bg-green-100'} transition-colors duration-300`}
                            onClick={handleButtonClick}
                            disabled={isLoading}
                        >
                            <div className={`${numberTagStyle}`}>3</div>
                            <p className={`${textTagStyle}`}>Create Account</p>
                        </button>
                    )}
                </div>
            </div>
        )
    }


    return (
        <div className="w-full h-[194px] rounded-[10px] account-verification-bg mb-7">
            <div className="px-[1.7rem] py-[1.5rem]">
                <h2 className="text-[24px] text-white font-bold leading-[30px] mb-3">Finish your account set up
                    (2/3)</h2>
                <p className="text-[14px] text-white leading-[21px] font-[500]">Complete your document verification to
                    perform transaction</p>
                <Steps/>
            </div>

            {/* Loader Popup */}
            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg flex flex-col items-center">
                        <div className="loader mb-3">
                            {/* You can replace this with an actual spinner or CSS loader */}
                            <svg className="animate-spin h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </div>
                        <p className="text-gray-700">Processing...</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AccountSetupVerification;
