function LimitsTab() {
    const pLight = "font-[300]  leading-[20px] text-gs-subText tracking-wide";
    const pDark = "font-[600] text-[16px] leading-[30px] text-gs-text tracking-wide";

    return (
        <div>
            <div className="py-[2.2rem] px-[1.8rem] bg-white border border-gs-borderInput rounded-[8px] grid gap-y-[2.1rem]">
                <div>
                    <h2 className="text-[18px] font-[800] leading-[30px] font-gs-text">GreenSwift to GreenSwift</h2>
                    <p className={`${pLight} text-[14px]`}>Limits for spending money from balances </p>
                </div>
                <div className="flex flex-wrap justify-between">
                    <div>
                        <p className={`${pLight} text-[16px]`}>Single  Transfer limit </p>
                        <p className={`${pDark}`}> ₦1,000,000.00</p>
                    </div>
                    <div>
                        <p className={`${pLight} text-[16px]`}>Single  Transfer limit </p>
                        <p className={`${pDark} text-right`}> ₦1,000,000.00</p>
                    </div>
                </div>
                <div>
                    <p className={`${pLight} text-[16px]`}>Single Receiving </p>
                    <p className={`${pDark}`}> Unlimited</p>
                </div>
            </div>

            <div className="py-[2.2rem] px-[1.8rem] bg-white border border-gs-borderInput rounded-[8px] grid gap-y-[2.1rem] mt-[2rem]">
                <div>
                    <h2 className="text-[18px] font-[800] leading-[30px] font-gs-text">GreenSwift to Other banks</h2>
                    <p className={`${pLight} text-[14px]`}>Limits for spending money from balances </p>
                </div>
                <div className="flex flex-wrap justify-between">
                    <div>
                        <p className={`${pLight} text-[16px]`}>Single  Transfer limit </p>
                        <p className={`${pDark}`}> ₦200,000.00</p>
                    </div>
                    <div>
                        <p className={`${pLight} text-[16px]`}>Single  Transfer limit </p>
                        <p className={`${pDark} text-right`}> ₦1,000,000.00</p>
                    </div>
                </div>
                <div>
                    <p className={`${pLight} text-[16px]`}>Single Receiving </p>
                    <p className={`${pDark}`}> Unlimited</p>
                </div>
            </div>
        </div>
    )
}

export default LimitsTab;