import { useEffect } from "react";
import { AppLayout } from "../../../../components/layouts";
import NotificationService from "../../../../services/notification.service";
import InvestmentTabs from "./components/InvestmentTabs";
import TotalInvestment from "./components/TotalInvestment";
import { useDispatch } from "react-redux";
import UserService from "../../../../services/user.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";

function Investment() {
  const dispatch = useDispatch(); 
  const userService = new UserService();

  const getUserInfo = async() => {
    try {
      const response = await userService.getUserInfo();
      if(response.status){
        dispatch(setUserInfo(response.data));
      }
    }catch(err: any){
      NotificationService.error({
        message: err.message
      })
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Investment
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <TotalInvestment />
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <InvestmentTabs />
      </div>
    </AppLayout>
  );
}

export default Investment;
