import { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { Button } from "../../../../../components/ui";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border  rounded-[15px] p-[12px] outline-gs-primary";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
}

function SwapFundModal({ closeModal }: TransferModalProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);

    const [ swapFrom, setSwapFrom ] = useState(userInfo.localCurrency);
    const [ swapTo, setSwapTo ] = useState(userInfo.foreignCurrency);

    useEffect(() => {
        if(swapFrom === userInfo.localCurrency){
            setSwapTo(userInfo.foreignCurrency);
        }
        if(swapFrom === userInfo.foreignCurrency){
            setSwapTo(userInfo.localCurrency);
        }
    }, [swapFrom])

    useEffect(() => {
        if(swapTo === userInfo.localCurrency){
            setSwapFrom(userInfo.foreignCurrency);
        }
        if(swapTo === userInfo.foreignCurrency){
            setSwapFrom(userInfo.localCurrency);
        }
    }, [swapTo])

    const handleSwap = (e: any) => {
        e.preventDefault();
    }

    return (
        <form onSubmit={handleSwap}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem] ">Swap funds</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <div className="mt-7">
                <label className={`${labelStyle}`}>Card balance</label>
                <div className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>              
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">Loan wallet</p>
                    <p className={`${balanceStyle}`}>{userInfo.localCurrencySymbol}{userInfo.localBalance}</p>
                </div>
            </div>

            <div className="flex mt-7 items-center gap-x-5 w-full">
                <div className="w-full">
                    <label className={`${labelStyle}`}>Swap from</label>
                    <select 
                        onChange={(e: any) => setSwapFrom(e.target.value)}
                        value={swapFrom}
                        className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                            <option value={userInfo.localCurrency}>{userInfo.localCurrency}</option>
                            <option value={userInfo.foreignCurrency}>{userInfo.foreignCurrency}</option>
                    </select>
                </div>

                <div className="w-full">
                    <label className={`${labelStyle}`}>Swap to</label>
                    <select 
                        onChange={(e: any) => setSwapTo(e.target.value)}
                        value={swapTo}
                        className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                            <option value={userInfo.localCurrency}>{userInfo.localCurrency}</option>
                            <option value={userInfo.foreignCurrency}>{userInfo.foreignCurrency}</option>
                    </select>
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Amount to Swap</label>
                <div className={`${inputStyle} border-gs-borderInput gap-x-3`}>
                    <div>&#8358;</div>
                    <input 
                        type="text" 
                        required
                        pattern="[0-9]*"
                        className="w-full outline-none text-gs-subText" 
                        placeholder="0.00" />
                </div>
            </div>

            <div className="flex flex-wrap lg:justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                    />
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}  
                    type="submit"    
                    text="Swap" 
                    />

            </div>
        </form>
    )
}

export default SwapFundModal;