import React, { useEffect, useRef } from 'react';
import Chart, { ChartConfiguration, ChartOptions } from 'chart.js/auto';

interface GraphProps {
  data: number[];
}

const DonutGraph: React.FC<GraphProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);


  const createGradient = (ctx: CanvasRenderingContext2D, colors: string[]) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    colors.forEach((color, index) => {
      gradient.addColorStop(index / (colors.length - 1), color);
    });
    return gradient;
  };
  

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          const chartConfig: ChartConfiguration = {
            type: 'doughnut',
            data: {
              labels: ['Jan', 'Feb', 'Mar', ],
              datasets: [{
                label: 'Money',
                data: data,   
                borderWidth: 0,        
                backgroundColor: [
                    '#0B7C42',
                    '#31B099',
                    '#204D37'
                ],
              }],
            },
            options: {
                // rotation: Math.PI * 2, // Set rotation to half of a circle (180 degrees)
                // circumference: Math.PI, // Set circumference to half of a circle (180 degrees)
                // cutout: '60%', // Adjust the cutout percentage as needed
                aspectRatio: 2,
                cutout: '60%',
                responsive: true,
                circumference: 180,
                rotation: 270,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            } as ChartOptions,
          };

          // Destroy existing chart instance with a small delay
          if (chartInstance.current) {
            setTimeout(() => {
              chartInstance.current?.destroy();
            }, 1000000000);
          }

          // Create a new chart instance
          chartInstance.current = new Chart(ctx, chartConfig);
        }
      }
    };

    createChart();

    // Cleanup function to destroy the chart instance when the component is unmounted
    return () => {
      destroyChart();
    };
  }, [data]);

  // Add a destroyChart method
  const destroyChart = () => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
  };

  return (  
    <div style={{ width: '100%',}}>
      <canvas ref={chartRef}></canvas>
    </div>
   
  );
};

export default DonutGraph;

