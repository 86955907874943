import logo from "../../../assets/icons/gs-logo.svg";
import facebook from "../../../assets/icons/facebook.svg";
import twitter from "../../../assets/icons/twitter-gs.svg";
import instagram from "../../../assets/icons/instagram.svg";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function Footer(){
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <footer
            className="bg-white py-[4rem] md:px-[4rem] px-[2rem]"
        >
            <div className="lg:flex grid gap-y-[2.5rem] justify-between">
                <div className="md:order-1 order-2 grid gap-y-[1.5rem]">
                    <div className="flex gap-x-3">
                        {/* facebook  */}
                        <a data-aos="fade-up" href="/">
                            <img src={facebook} alt="facebook" />
                        </a>
                        {/* instagram  */}
                        <a data-aos="fade-up" href="/">
                            <img src={instagram} alt="instagram" />
                        </a>
                        {/* twitter  */}
                        <a data-aos="fade-up" href="/">
                            <img src={twitter} alt="twitter" />
                        </a>
                    </div>
                    <p data-aos="fade-right" className="text-[#6C7278] font-extralight text-[15px]">Your future, your finances, your way.</p>
                </div>
                <div className="md:order-2 order-1 md:flex grid gap-x-[3rem] gap-y-[2rem]">
                    <div>
                        <h4 data-aos="fade-up" className="font-semibold text-[17px] mb-5 tracking-wide">Contacts</h4>
                        <ul data-aos="fade-up" className="text-[#6C7278] font-extralight text-[15px] grid gap-y-3">
                            <li>Support@gmail.com</li>
                            <li>+234 798 4567</li>
                        </ul>
                    </div>
                    <div>
                        <h4 data-aos="fade-up" className="font-bold text-[17px] mb-5">Legal details</h4>
                        <ul data-aos="fade-up" className="text-[#6C7278] font-extralight text-[15px] grid gap-y-3">
                            <li>Terms & Conditions</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
            <img data-aos="fade-right" src={logo} alt="logo" className="w-[200px] mt-[5rem]" />
            <p  className="md:mt-[6rem] mt-[2rem] text-center text-[#6C7278] text-[15px] tracking-wide font-extralight">©2023 GreenSwift. All rights reserved</p>
        </footer>
    )
}

export default Footer;