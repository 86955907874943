import { useNavigate } from "react-router-dom";
import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import VerificationForm from "../components/VerificationForm";
import ls from 'localstorage-slim';
import NotificationService from "../../../services/notification.service";


// LOCAL
ls.config.encrypt = true;

function ResetCode() {
  const navigate = useNavigate();

  const reset_otp = ls.get('gs-pwd-otp');

  const handleVerification = async(_arg: number | string) => {
    if(reset_otp?.toString() === _arg){
      navigate("/auth/create-new-password");
    }else{
      NotificationService.error({
        message: 'Invalid OTP, retry!'
      })
    }
  };

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader
            title="Reset code"
            subtitle="To Reset password, kindly enter the code sent your  mail"
          />
          <VerificationForm
            text="Continue"
            handleVerification={(data) => handleVerification(data)}
          />
        </div>
      </div>
    </AuthLayout>
  );
}

export default ResetCode;
