import { useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import SignUpForm from "../components/SignUpForm";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';

function SignUp() {
    useEffect(() => {
        AOS.init();
      }, []);

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full">
        <div data-aos="fade-left"  className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader title="Create your account" />
          <SignUpForm />
          <div>
            <p className="text-center font-semibold text-gs-subSubText leading-5">
              Already have an account?{" "}
              <Link to="/auth/signin" className="text-gs-primary font-medium">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SignUp;
