import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import backIcon from "../../../../../assets/icons/arrow-left.svg";
import {Button} from "../../../../../components/ui";
import {useEffect, useState} from "react";
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import ls from 'localstorage-slim';
import {ScaleLoader} from "react-spinners";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border rounded-[15px]";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
    setTransferTab: (data: number) => void;
    setTransferType: (data: string) => void;
}

interface TransferDataProps {
    accountNumber: string,
    accountBank: string,
    bankName: string,
    amount: number,
    saveBeneficiary: boolean,
    accountName: string,
    narration?: string,
    currency: string,
    callbackUrl?: string,
    debitCurrency: string,
    pin: string
}

const initialValues = {
    accountNumber: "",
    accountBank: '044',
    bankName: "Access Bank",
    amount: 0.00,
    saveBeneficiary: false,
    accountName: "",
    narration: "",
    currency: "",
    callbackUrl: "http://localhost:3000/payment/success",
    debitCurrency: "",
    pin: ""
}

// LOCAL
ls.config.encrypt = true;

function TransferToBank({closeModal, setTransferTab, setTransferType}: TransferModalProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo)
    const userService = new UserService();
    const [formData, setFormData] = useState(initialValues);
    const [loading, setLoading] = useState(false)
    const [banks, setBanks] = useState([]);
    const [selectedBankName, setSelectedBankName] = useState('');
    const [selectedBankCode, setSelectedBankCode] = useState('');
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [transactionCharge, setTransactionCharge] = useState(0.00)

    useEffect(() => {
        getBanks();
    }, [])

    const getBanks = async () => {
        try {
            const response = await userService.getAllBanks();
            if (!response) {
                NotificationService.warn({
                    message: response?.message
                })
                return
            }
            ;
            setBanks(response.data)
        } catch (err: any) {
            NotificationService.error({
                message: err?.message
            });
        }
    }

    const handleAmountChange = async (event: any) => {
        const value = event.target.value;
        const pattern = /^\d+(\.\d+)?$/;

        if (pattern.test(value) || value === '') {
            setIsAmountValid(true);
            setFormData({...formData, amount: value})
            try {
                const response = await userService.transactionCharge({amount: value});
                if (!response) return;
                setTransactionCharge(response.data.amount);
            } catch (err) {
                console.log(err)
            }
        } else {
            setIsAmountValid(false);
        }
    }

    const handleBankChange = (event: any) => {
        const bankId = event.target.value;
        const bank: any = banks.find((item: { id: string, code: string, name: string }) => item.code === bankId);
        setSelectedBankName(bank.name);
        setSelectedBankCode(bank.code);
    }

    useEffect(() => {
        if (selectedBankCode !== '') {
            setFormData({...formData, accountBank: selectedBankCode})
        }
    }, [selectedBankCode])

    useEffect(() => {
        if (selectedBankName !== '') {
            setFormData({...formData, bankName: selectedBankName})
        }
    }, [selectedBankName])

    // Function to verify the account name
    const verifyAccountName = async () => {
        if (formData.accountNumber.length == 10 && selectedBankCode) {
            try {
                const response = await userService.verifyAccount({
                    accountNumber: formData.accountNumber,
                    "accountBank": selectedBankCode
                });

                if (response?.data?.accountName) {
                    setFormData({
                        ...formData,
                        accountName: response.data.accountName
                    });
                } else {
                    NotificationService.warn({
                        message: "Unable to verify account name."
                    });
                }
            } catch (err: any) {
                NotificationService.error({
                    message: "Verification failed!",
                    addedText: err?.message
                });
            }
        }
    };

    // Trigger account name verification when both accountNumber and bankCode are available
    useEffect(() => {
        if (formData.accountNumber && selectedBankCode) {
            verifyAccountName();
        }
    }, [formData.accountNumber, selectedBankCode]);

    const handlePay = async (event: any) => {
        event.preventDefault();
        if (Number(formData.amount) > Number(userInfo.walletBalance)) {
            NotificationService.warn({
                message: 'Insufficient balance'
            });
            return;
        }
        if (Math.round(formData.amount) === 0) {
            NotificationService.warn({
                message: 'Amount must be significant'
            });
            return;
        }
        const values = {...formData, currency: userInfo.localCurrency, debitCurrency: userInfo.localCurrency};
        setLoading(true)
        try {
            await userService.sendPinAsOTP()
                .then((res) => {
                    setLoading(false);
                    ls.set('_tix', values);
                    NotificationService.success({message: res.message})
                    setTransferTab(4)
                    setTransferType('bank');
                }).catch((err) => {
                    setLoading(false);
                    NotificationService.error({
                        message: 'Transfer failed!',
                        addedText: err?.message
                    });
                    return;
                })
        } catch (err: any) {
            setLoading(false);
            NotificationService.error({
                message: 'Transfer failed!',
                addedText: err?.message
            });
            return;
        }
    }

    return (
        <form onSubmit={handlePay}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <div className="text-[18px] font-bold leading-[1rem] flex items-center gap-x-3">
                    <img src={backIcon} alt="back" onClick={() => setTransferTab(0)} className="hover:cursor-pointer"/>
                    back
                </div>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()}
                    className="hover:cursor-pointer"
                />
            </div>

            <h2 className="text-[18px] font-[700] tracking-wide">Send to bank account</h2>

            <div className="mt-7">
                <label className={`${labelStyle}`}>Balance to send from</label>
                <div className={`${inputStyle} p-[12px] border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">Wallet Balance</p>
                    <p className={`${balanceStyle}`}>{userInfo.localCurrencySymbol}{userInfo.walletBalance}</p>
                </div>
            </div>

            <div className="mt-4">
                <div className="flex justify-between items-end mb-1.5">
                    <label className={`${labelStyle}`}>Amount to Send</label>
                    <label
                        className="px-3 py-[0.2rem] bg-[#E4B46C]/[.3] text-[12px] tracking-wide rounded-[16px]">{userInfo.localCurrencySymbol}{transactionCharge}</label>
                </div>
                <div
                    className={`${inputStyle} p-[12px] ${isAmountValid ? 'border-gs-borderInput' : 'border-red-600'} gap-x-3`}>
                    <div className="text-gs-subText">&#8358;</div>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        placeholder="0.00"
                        onChange={handleAmountChange}
                        required
                    />
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Bank</label>
                <select onChange={handleBankChange}
                        className={`${inputStyle} px-[12px] py-[15px] border-gs-borderInput outline-none text-[13.5px] tracking-wide `}>
                    {banks?.length > 0 &&
                        <>
                            {banks.map((bank: { id: number, code: string, name: string }) => (
                                <option key={bank.id} value={bank.code}
                                        className="font-[500] leading-[14px] tracking-wide">{bank.name}</option>
                            ))}
                        </>
                    }
                </select>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Account Number</label>
                <input
                    type="text"
                    placeholder="20234567890"
                    pattern="[0-9]*"
                    required
                    maxLength={10}
                    onChange={(e: any) => setFormData({...formData, accountNumber: e.target.value})}
                    className={`${inputStyle} px-[12px] py-[15px] border-gs-borderInput outline-none text-[14px] font-[300] text-gs-text`}
                />
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Account Name</label>
                <input
                    type="text"
                    placeholder="Verifying..."
                    value={formData.accountName}
                    required
                    readOnly
                    className={`${inputStyle} px-[12px] py-[15px] border-gs-borderInput outline-none text-[14px] font-[300] bg-[#098543]/[.04] text-gs-subText`}
                />
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Description</label>
                <textarea
                    rows={1}
                    placeholder="Enter description here"
                    required
                    onChange={(e: any) => setFormData({...formData, narration: e.target.value})}
                    className={`${inputStyle} resize-none w-full px-[12px] py-[15px] border-gs-borderInput outline-none text-[14px] font-[300] `}
                ></textarea>
            </div>

            <div className="flex flex-wrap lg:justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] order-2 lg:order-1 py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                />
                <Button
                    btnStyle="px-[50px] order-1 lg:order-2 py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}
                    type="submit"
                    text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Transfer"}
                />
            </div>
        </form>
    )
}

export default TransferToBank;
