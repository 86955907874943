import { getDate12HoursFormat } from "../../../../../components/custom-hooks";

interface SingleMessageProps {
    id: string;
    text: string;
    date: Date;
    status: string;
}
export interface MessageProps {
    message: SingleMessageProps;
}

function SingleMessage({ message }: MessageProps) {
    const messageSent = "rounded-l-[20px] rounded-b-[20px] bg-gs-primary/[.1] float-right mb-2";
    const messageRecieved = "rounded-r-[20px] rounded-b-[20px] bg-gs-cardBg/[.05] float-left mb-6";


    return (
        <div className={`w-fit clear-both max-w-[80%] p-[15px] ${message.status === 'sent' ? messageSent : messageRecieved}`}>
            <p className="text-[14px] font-[400] text-gs-text">{message.text}</p>
            <p className="flex justify-end text-[11px] text-gs-subText font-[400] mt-1">{getDate12HoursFormat(message.date)}</p>
        </div>
    )
}

export default SingleMessage;