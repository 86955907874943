import { AuthLayout } from "../../../components/layouts";

function ForgotPassword() {
    return (
        <AuthLayout>
            Forgot Password
        </AuthLayout>
    )
}


export default ForgotPassword;