import { useState } from "react";
import eye from "../../../../../assets/icons/eye.svg";
import eyeSlash from "../../../../../assets/icons/eye-slash.svg";
import loanIcon from "../../../../../assets/icons/on.iconLoan.svg";
import depositIcon from "../../../../../assets/icons/depositIconWhite.svg";
import { CustomModal } from "../../../../../components/ui";
import RepayLoanModal from "./ModalRepayLoan";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import ModaRequestLoan from "./ModaRequestLoan";

const balance = "0.00";

function LoanBalanceTab() {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo)
  const [isVisible, setIsVisible] = useState(true);
  const [showRepayModal, setShowRepayModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const toggleIcon = isVisible ? eyeSlash : eye;
  const btnStyle =
    "flex items-center gap-x-2 px-[20px] py-[10px] border border-gs-primary rounded-[14px] text-[14.5px] tracking-wide";

  return (
    <>
      <div className="lg:w-2/5 w-full bg-gs-grayLess1 rounded-[10px] py-[30px] grid gap-y-6">
        <h2 className="text-center text-[15px] font-[500] leading-[20px]">
          LOAN BALANCE
        </h2>
        <div className="flex items-center justify-center gap-x-3">
          <div className="border-0 outline-0 tracking-wider text-right bg-transparent text-[28px] leading-[30px] font-extrabold">
            {isVisible ? `${userInfo?.localCurrencySymbol}${userInfo?.loanBalance}` : "*****"}
          </div>
          <button onClick={() => setIsVisible(!isVisible)}>
            <img src={toggleIcon} alt="toggle-icon" />
          </button>
        </div>

        <div className="flex justify-center gap-x-7">
          <button
            className={`${btnStyle} bg-gs-primary text-white`}
            onClick={() => setShowRepayModal(true)}
          >
            <p>Repay loan</p>
            <img src={depositIcon} alt="repay" />
          </button>
          <button
            className={`${btnStyle} bg-white text-gs-primary`}
            onClick={() => setShowRequestModal(true)}
          >
            <p>Request loan</p>
            <img src={loanIcon} alt="request loan" />
          </button>
        </div>
      </div>
      {showRepayModal && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowRepayModal(false)}
        >
          <RepayLoanModal closeModal={() => setShowRepayModal(false)} />
        </CustomModal>
      )}

      {showRequestModal && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowRequestModal(false)}
        >
          <ModaRequestLoan closeModal={() => setShowRequestModal(false)} />
        </CustomModal>
      )}
    </>
  );
}

export default LoanBalanceTab;
