import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import ChatList from "./components/ChatList";
import ChatDisplay from "./components/ChatDisplay";
import { useDispatch } from "react-redux";
import UserService from "../../../../services/user.service";
import NotificationService from "../../../../services/notification.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";

const chats: any = [
    {id: '1', userImage:'', name: 'John Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 0},
    {id: '2', userImage:'', name: 'Jane Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 4},
    {id: '3', userImage:'', name: 'John Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 2},
    {id: '4', userImage:'', name: 'Jane Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 0},
    {id: '5', userImage:'', name: 'Jane Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 14},
    {id: '6', userImage:'', name: 'John Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 2},
    {id: '7', userImage:'', name: 'Jane Doe', msg: 'Hey what is the  progress of the project ?', time: new Date(), unread: 6},
]

function Messages(){
    const [ isSelected, setIsSelected ] = useState('');
    
    const dispatch = useDispatch(); 
    const userService = new UserService();
  
    const getUserInfo = async() => {
      try {
        const response = await userService.getUserInfo();
        if(response.status){
          dispatch(setUserInfo(response.data));
        }
      }catch(err: any){
        NotificationService.error({
          message: err.message
        })
      }
    }
  
    useEffect(() => {
      getUserInfo();
    }, [])

    
    const handleSearch = (_arg: string) => {};

    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">Messages</h2>}    
        >
             <div className=" px-[2.5rem] py-[3rem] md:w-full w-fit">
                <div className="grid lg:grid-cols-9 grid-cols-1 py-[2rem] bg-white border border-gs-borderInput rounded-[8px]">
                    <div className="lg:col-span-4 col-span-1">
                        <ChatList 
                            chats={chats}
                            handleSearch={(data: string) => handleSearch(data)}
                            isSelected={isSelected}
                            setIsSelected={(data: string) => setIsSelected(data)}
                        />
                    </div>
                    <div className="lg:col-span-5 col-span-1">
                       <ChatDisplay />
                    </div>
                </div>
             </div>
        </AppLayout>
    )
}


export default Messages;