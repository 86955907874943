
// redux/store.ts
import { configureStore } from '@reduxjs/toolkit';

// Import your reducers here
// import myReducer from './myReducer';
import authReducer from './reducers/authReducer';

const store = configureStore({
  reducer: {
    // Add your reducers here
    // mySlice: myReducer,
    auth: authReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;