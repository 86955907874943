import React, { useEffect, useState } from "react";
import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import { Link, useNavigate } from "react-router-dom";
import AccountSetupStepIndicator from "../components/AccountSetupStepIndicator";
import AccountSetupSteps from "../components/AccountSetupSteps";
import NotificationService from "../../../services/notification.service";
import UserService from "../../../services/user.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux/reducers/authReducer";
import ls from 'localstorage-slim';

// LOCAL
ls.config.encrypt = true;

function AccountSetup() {
  const [currentStep, setCurrentStep] = useState(0);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userService = new UserService();

 
  const handleSetValues = (data: any) => {
    setValues({...values, ...data})
  }

  const handleSetValuesTwo = (data: any) => {
    setValues({...values, idImgUrl: data})
  }

  const handleNextStepColor = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStepColor = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleSkipPreviousStepColor = () => {
    setCurrentStep(currentStep - 2);
  };

  const handleSubmit = async() => {
    // show a warning message if values object is empty
    if(Object.entries(values).length === 0){
      NotificationService.warn({
        message: 'Ensure that no field is empty!'
      })
      return;
    }

    setLoading(true);
    try {
      const response = await userService.accountSetup(values);
      setLoading(false);

      if(!response.status){
        NotificationService.error({
          message: 'Account setup failed!',
          addedText: response?.message
        });
        return;
      }

      NotificationService.success({
        message: 'Account setup successful!',
      });
      dispatch(setUserInfo(response?.data));
      ls.set('gs-token', response?.data?.token);
      navigate('/app/home');
      console.log('user data', response?.data)

    }catch(err: any){
      setLoading(false)
      NotificationService.error({
        message: 'Failed!',
        addedText: err?.message
      })
    }
  }


  return (
    <AuthLayout>
      <div className="flex flex-col gap-[26px] justify-center items-center w-full">
        <AccountSetupStepIndicator currentStep={currentStep} />
        <div className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader title="Almost there! Finish account setup" />
          <AccountSetupSteps 
            stepOneData={handleSetValues} 
            stepTwoData={handleSetValuesTwo}
            handleNextStepColor={handleNextStepColor} 
            handlePreviousStepColor={handlePreviousStepColor} 
            handleSkipPreviousStepColor={handlePreviousStepColor}
            handleSubmit={handleSubmit}
            values={values}
            loading={loading}
            />
          <div>
            <p className="text-center font-semibold text-gs-subSubText leading-5">
              Already have an account?{" "}
              <Link to="/auth/signin" className="text-gs-primary font-medium">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default AccountSetup;
