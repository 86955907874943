import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import ArrowLeft from "../../../../../assets/icons/arrow-left.svg";
import { Button } from "../../../../../components/ui";

interface ModalProps {
  closeModal: () => void;
  onNextStep: () => void;
  onPrevStep: () => void;
}

function InvestmentAmountModalStep2({
  closeModal,
  onPrevStep,
  onNextStep,
}: ModalProps) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex gap-[6px] cursor-pointer" onClick={onPrevStep}>
          <img src={ArrowLeft} alt="Back Icon" />
          <p>back</p>
        </div>
        <div>
          <img
            src={closeIcon}
            alt="close"
            onClick={() => closeModal()}
            className="cursor-pointer"
          />
        </div>
      </div>
      <p className="text-gs-text text-lg font-bold mt-6">
        Review Investment plan
      </p>
      <p className="font-bold text-gs-primary mt-6">
        Saphore Inc Capital Money Market Fund
      </p>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-between items-center">
        <p className="font-medium text-gs-subText">Value</p>
        <p className="text-gs-text font-medium">₦ 100,000.00</p>
      </div>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-between items-center">
        <p className="font-medium text-gs-subText">Units</p>
        <p className="text-gs-text font-medium">100,000 units</p>
      </div>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-between items-center">
        <p className="font-medium text-gs-subText">Processing fee</p>
        <p className="text-gs-text font-medium">₦ 20.00</p>
      </div>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-between items-center">
        <p className="font-medium text-gs-subText">Total</p>
        <p className="text-gs-text font-bold text-lg">100,200.00</p>
      </div>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-between items-center">
        <p className="font-medium text-gs-subText">Payment</p>
        <p className="text-gs-primary font-medium">Wallet</p>
      </div>
      <hr className="border-[#f5f5f5] my-4" />
      <div className="flex justify-end">
        <Button
          text="Pay"
          isInverted={false}
          category="button"
          btnStyle="py-3 px-6 rounded-[14px] max-w-[95px] w-full"
          handleClick={onNextStep}
        />
      </div>
    </div>
  );
}

export default InvestmentAmountModalStep2;
