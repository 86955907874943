// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import newCard from "../../../assets/icons/new-card.svg";
import currencyCard from "../../../assets/icons/currency-card.svg";
import analysisCard from "../../../assets/icons/analysis-card.svg";
import { useEffect } from 'react';

function SectionOne() {

    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <section
        className="h-[100vh] w-full pt-[8rem] px-[2rem] md:px-0  md:mt-0 mt-[32.5rem] mb-[8rem]"
        >
            <h2 data-aos="fade-up" className="text-center md:text-[45px] text-[40px] leading-[60px] font-bold mb-3 w-fit mx-auto">What we offer</h2>
            <p data-aos="fade-up" className="text-center text-[18px] text-[#6C7278] w-fit mx-auto">Explore our range of cutting-edge solutions designed to simplify your financial journey.</p>
            <div className="grid md:grid-cols-3 grid-cols-1 items-end md:px-[4rem] gap-[1.5rem] mt-[3.7rem]">

                <div data-aos="" className="col-span-1 bg-gs-grayLess1 rounded-[30px] pt-7 pb-4 px-7 h-fit">
                    <h3 className="text-[30px] font-semibold mb-2">Investments Plan</h3>
                    <p className="text-[16px] text-[#6C7278] font-[300] mb-5 leading-8">Invest with flexibility and ease. Get access to the juiciest opportunities in top industries. </p>
                    <img src={newCard} alt="new" />
                </div>

                <div data-aos="fade-up" className="col-span-1 bg-gs-primary rounded-[30px] py-7 px-7 h-fit">
                    <h3 className="text-[30px] font-semibold mb-2 text-white">Multi-currency</h3>
                    <p className="text-[16px] text-white font-[300] mb-[3.5rem] leading-8">Save, invest, and transact in several currencies. It’s swift, diverse, seamless and smart.</p>
                    <img src={currencyCard} alt="currency" />
                </div>

                <div data-aos="fade-up" className="col-span-1 bg-gs-grayLess1 rounded-[30px] py-7  px-7 h-fit">
                    <h3 className="text-[30px] font-semibold mb-2">Financial Analysis</h3>
                    <p className="text-[16px] text-[#6C7278] font-[300] mb-5 leading-8">Get accurate finance information. Hack financial intelligence and useful data analytics. </p>
                    <img src={analysisCard} alt="analyse" />
                </div>
            </div>
        </section>
    )
}

export default SectionOne;