import { AppLayout } from "../../../../components/layouts";
import SlantArrow from "../../../../assets/icons/SlantArrow.svg";
import ArrowRight from "../../../../assets/icons/arrow-right.svg";
import { Link } from "react-router-dom";
import funds from "../../../../utils/funds";
import UserService from "../../../../services/user.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import { useEffect } from "react";

interface Investment {
  id?: number;
  logo?: string;
  company?: string;
  companyDesc?: string;
  amount?: string;
  returnPercent?: string;
  riskIcon?: string;
  risk?: string;
}

function Marketplace() {
  const dispatch = useDispatch(); 
  const userService = new UserService();

  const getUserInfo = async() => {
    try {
      const response = await userService.getUserInfo();
      if(response.status){
        dispatch(setUserInfo(response.data));
      }
    }catch(err: any){
      NotificationService.error({
        message: err.message
      })
    }
  }

  useEffect(() => {
    getUserInfo();
  }, [])


  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Marketplace
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <div className="grid grid-cols-3 gap-x-11 gap-y-5">
          {funds.map((investment: Investment, i: number) => (
            <div
              key={i}
              className="p-[21px] border border-gs-borderInput bg-gs-grayLess1 rounded-[7px] flex flex-col gap-[14px]"
            >
              <div className="flex items-center gap-2 justify-end">
                <img src={investment.riskIcon} alt="risk level" />
                <p
                  className={`${
                    investment.risk === "Very High"
                      ? "!text-[#C84308]"
                      : "text-gs-subSubText"
                  } font-medium text-xs`}
                >
                  {investment.risk}
                </p>
              </div>
              <div>
                <img src={investment.logo} alt="company logo" />
              </div>
              <p className="text-gs-text font-bold">{investment.company}</p>
              <p className="text-xs text-gs-subSubText">
                {investment.companyDesc}
              </p>
              <hr className="border-gs-greyscaleGrey200" />
              <div className="flex justify-between items-center">
                <p className="text-gs-subSubText">Return</p>
                <div className="bg-[rgba(9,133,67,0.17)] flex items-center px-1 py-[3px] rounded-[6px]">
                  <img src={SlantArrow} alt="arrow" />
                  <p className="font-semibold text-[15px] manrope text-[#0A9B4E]">
                    {investment.returnPercent}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-gs-primary">View Details</p>
                <Link to={`/app/marketplace/${investment.id}`}>
                  <img
                    src={ArrowRight}
                    alt="View Details"
                    className="cursor-pointer"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AppLayout>
  );
}

export default Marketplace;
