import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ScrollArrow from "../../../../../assets/icons/arrow-circle-right.svg";
import SlantArrow from "../../../../../assets/icons/SlantArrow.svg";
import funds from "../../../../../utils/funds";
import { Link } from "react-router-dom";
import EmptyState from "../../../../../components/ui/EmptyState";

function PortfolioSlider() {
  const NextArrow = ({ onClick }: any) => {
    return (
      <div
        className="w-[33px] h-[33px] flex flex-col justify-center items-center rounded-full z-10 absolute top-[50%] -translate-y-[50%] right-0 cursor-pointer"
        onClick={onClick}
      >
        <img src={ScrollArrow} alt="Next" className="w-[33px] h-[33px]" />
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
  };

  return (
    <>
      {funds.length ? (
        <Slider {...settings}>
          {funds.map((investment, i) => (
            <Link
              to={`/app/investment/invest-more/${investment.id}`}
              key={i}
              className="max-w-[208px] w-full pt-[17px] pl-[14px] pr-[20px] pb-[20px] border border-gs-borderInput rounded-[13px] space-y-[31px] cursor-pointer"
            >
              <div className="flex gap-[22px]">
                <img
                  src={investment.logo}
                  alt="logo"
                  className="w-[27px] h-[27px]"
                />
                <p className="font-bold text-sm text-gs-text">
                  {investment.shortName}
                </p>
              </div>
              <div>
                <p className="manrope text-[13px] text-gs-subText font-medium mt-5">
                  Portfolio
                </p>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-gs-text font-extrabold">
                    ₦2,000,000.00
                  </p>
                  <div className="bg-[rgba(9,133,67,0.17)] flex items-center px-1 py-[3px] rounded-[6px]">
                    <img
                      src={SlantArrow}
                      alt="arrow"
                      className="w-[7px] h-[7px]"
                    />
                    <p className="font-semibold text-[9px] manrope text-[#F26222]">
                      {investment.returnPercent}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      ) : (
        <EmptyState
          emptyText="You have not made any  Investment yet"
          className="!mt-0 !text-sm"
        />
      )}
    </>
  );
}

export default PortfolioSlider;
