import VerificationForm from "../../../../auth/components/VerificationForm";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import {ScaleLoader} from "react-spinners";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
    handlePinVerification: (_arg: string | number) => void;
    loading: boolean,
}

function ConfirmTransaction({closeModal, handlePinVerification, loading}: TransferModalProps) {


    const handleVerification = (_arg: number | string) => {
        handlePinVerification(_arg)
    }

    return (
        <div>
            <div className="flex relative flex-col justify-center items-center w-full h-full ">
                {/* <div className="bg-white px-10 w-full rounded-2xl relative"> */}
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()}
                    className="hover:cursor-pointer absolute -right-2 -top-3"/>

                <h2 className="text-[22px] font-[700] tracking-normal mb-3 text-center">Enter code to confirm
                    transaction </h2>
                <p className="text-[14px] font-[300] text-center leading-[22px] text-gs-subSubText mb-3">Enter code sent
                    to your email to authorize transaction</p>
                <VerificationForm
                    text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Authorize Transaction"}
                    handleVerification={(data) => handleVerification(data)}
                    loading={loading}
                />
                {/* </div> */}
            </div>
        </div>
    )
}


export default ConfirmTransaction;
