import { useState } from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { Button } from "../../../../../components/ui";
import NotificationService from "../../../../../services/notification.service";

interface ModalProps {
  closeModal: () => void;
  onNextStep: () => void;
}

function InvestmentAmountModalStep1({ closeModal, onNextStep }: ModalProps) {
  const [investmentAmount, setInvestmentAmount] = useState("");
  const handleNext = () => {
    if (investmentAmount.trim() === "") {
      NotificationService.warn({
        message: "Please enter Amount",
        // addedText: "User logged in successfully",
        position: "bottom-left",
        delay: 3000,
      });
      return;
    }
    onNextStep();
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-gs-text text-lg font-bold">
          How much do you want to invest
        </p>
        <img
          src={closeIcon}
          alt="close"
          onClick={() => closeModal()}
          className="cursor-pointer"
        />
      </div>
      <div className="mt-6">
        <input
          value={investmentAmount}
          onChange={(e) => setInvestmentAmount(e.target.value)}
          type="text"
          className="w-full pl-3 pr-6 py-3 border rounded-[15px] border-gs-borderInput outline-none"
          placeholder="NGN 0.00"
        />
      </div>
      <p className="text-gs-subSubText text-sm font-light mt-[10px]">
        This amount specified here is what you want to invest{" "}
      </p>
      <div className="flex gap-[10px] justify-end mt-[39px]">
        <Button
          isInverted
          text="Cancel"
          category="link"
          btnStyle="py-3 px-6 rounded-[14px]"
          handleClick={() => closeModal()}
        />
        <Button
          text="Invest"
          isInverted={false}
          category="button"
          btnStyle="py-3 px-6 rounded-[14px]"
          handleClick={handleNext}
        />
      </div>
    </div>
  );
}

export default InvestmentAmountModalStep1;
