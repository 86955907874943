import Show from "../../../../../assets/icons/eye.svg";
import InvestmentChart from "../../../../../assets/icons/InvestmentChart.svg";
import PortfolioSlider from "./PortfolioSlider";

function PortfolioValue() {
  return (
    <div className="grid grid-cols-3 gap-8">
      {/* PORTFOLIO VALUE */}
      <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem] space-y-[70px]">
        <div>
          <p className="text-gs-subText font-medium">Portfolio value</p>
          <div className="flex gap-[15px] mt-5">
            <p className="text-gs-text text-2xl font-extrabold">₦0.00</p>
            <img src={Show} alt="Eye" />
          </div>
        </div>
        <div className="flex justify-between items-center align-bottom">
          <div className="flex gap-[17px]">
            <img src={InvestmentChart} alt="Chart" />
            <div>
              <p className="text-gs-subText font-medium text-xs">
                All time profit
              </p>
              <p className="text-gs-primary text-sm font-extrabold">₦0.00</p>
            </div>
          </div>
          <div className="flex gap-[17px]">
            <img src={InvestmentChart} alt="Chart" />
            <div>
              <p className="text-gs-subText font-medium text-xs">
                All time profit
              </p>
              <p className="text-gs-primary text-sm font-extrabold">₦0.00</p>
            </div>
          </div>
        </div>
      </div>

      {/* PORTFOLIO */}
      <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white py-[1.5rem] pl-[1.5rem] col-span-2 h-[239px]">
        <p className="mb-[25px] text-gs-text font-bold">Your Portfoilo</p>
        <PortfolioSlider />
      </div>
    </div>
  );
}

export default PortfolioValue;
