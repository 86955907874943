import ProjectInformationForm from "./ProjectInformationForm";

interface Props {
  onNextStep: () => void;
}

function ProjectInformation({ onNextStep }: Props) {
  return (
    <div>
      <h3 className="text-center text-gs-text text-2xl font-bold mb-8">
        Project Information
      </h3>
      <ProjectInformationForm onNextStep={onNextStep} />
    </div>
  );
}

export default ProjectInformation;
