import { AppLayout } from "../../../../components/layouts";
import ProjectAnalysisSection from "./components/ProjectAnalysis";
import ProjectEmptyState from "../../../../assets/icons/ProjectEmptyState.svg";
import ProjectTransactions from "./components/ProjectTransactions";
import { useDispatch } from "react-redux";
import UserService from "../../../../services/user.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import { useEffect } from "react";


const transactions: any = [
  {
    productName: "Green Hub operation",
    productId: "#PROJ -1234",
    date: new Date(),
    type: "Individual",
    milestone: "Stage 0/4",
    status: "PENDING",
  },
  {
    productName: "Green Hub operation",
    productId: "#PROJ -1234",
    date: new Date(),
    type: "Individual",
    milestone: "Stage 2/4",
    status: "APPROVED",
  },
  {
    productName: "Green Hub operation",
    productId: "#PROJ -1234",
    date: new Date(),
    type: "Individual",
    milestone: "Stage 2/4",
    status: "APPROVED",
  },
  {
    productName: "Green Hub operation",
    productId: "#PROJ -1234",
    date: new Date(),
    type: "Individual",
    milestone: "Stage 2/4",
    status: "APPROVED",
  },
  {
    productName: "Green Hub operation",
    productId: "#PROJ -1234",
    date: new Date(),
    type: "Individual",
    milestone: "Stage 2/4",
    status: "APPROVED",
  },
];

function MyProjects() {
  const dispatch = useDispatch(); 
  const userService = new UserService();

  const getUserInfo = async() => {
    try {
      const response = await userService.getUserInfo();
      if(response.status){
        dispatch(setUserInfo(response.data));
      }
    }catch(err: any){
      NotificationService.error({
        message: err.message
      })
    }
  }

  useEffect(() => {
    getUserInfo();
  }, [])

  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          My Projects
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <ProjectAnalysisSection />
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <ProjectTransactions
          data={transactions}
          emptyText="You have not added any project  yet"
          emptyTextMain="No Project"
          emptyIcon={ProjectEmptyState}
          title="Projects"
        />
      </div>
    </AppLayout>
  );
}

export default MyProjects;
