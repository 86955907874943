import SlantArrow from "../../../../../assets/icons/SlantArrow.svg";
import ArrowRight from "../../../../../assets/icons/arrow-right.svg";
import funds from "../../../../../utils/funds";

interface Investment {
  logo?: string;
  company?: string;
  companyDesc?: string;
  amount?: string;
  returnPercent?: string;
  riskIcon?: string;
  risk?: string;
}

function RecommendedFunds() {
  return (
    <div className="grid grid-cols-3 gap-x-11 gap-y-5">
      {funds.map((investment: Investment, i: number) => (
        <div
          key={i}
          className="p-[21px] border border-gs-borderInput bg-gs-grayLess1 rounded-[7px] flex flex-col gap-[14px]"
        >
          <div className="flex items-center gap-2 justify-end">
            <img src={investment.riskIcon} alt="risk level" />
            <p
              className={`${
                investment.risk === "Very High"
                  ? "!text-[#C84308]"
                  : "text-gs-subSubText"
              } font-medium text-xs`}
            >
              {investment.risk}
            </p>
          </div>
          <div>
            <img src={investment.logo} alt="company logo" />
          </div>
          <p className="text-gs-text font-bold">{investment.company}</p>
          <p className="text-xs text-gs-subSubText">{investment.companyDesc}</p>
          <hr className="border-gs-greyscaleGrey200" />
          <div className="flex justify-between items-center">
            <p className="text-gs-subSubText">Return</p>
            <div className="bg-[rgba(9,133,67,0.17)] flex items-center px-1 py-[3px] rounded-[6px]">
              <img src={SlantArrow} alt="arrow" />
              <p className="font-semibold text-[15px] manrope text-[#0A9B4E]">
                {investment.returnPercent}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-gs-primary">View Details</p>
            <img
              src={ArrowRight}
              alt="View Details"
              className="cursor-pointer"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecommendedFunds;
