import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "../../../components/ui";
import * as Yup from "yup";
import ShowPassword from "../../../assets/icons/eye-slash.svg";
import HidePassword from "../../../assets/icons/eye.svg";
import { Link, useNavigate, } from "react-router-dom";
import NotificationService from "../../../services/notification.service";
import AuthService from "../../../services/auth.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux/reducers/authReducer";
import ls from 'localstorage-slim';
import { ScaleLoader } from "react-spinners";

interface Values {
  email: string;
  password: string;
  ip?: string,
  location?: string,
  device?: string
}

// FORM VALIDATION
const initialValues: Values = { email: "", password: "", location: "", device: "", ip: "" };

const LoginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

// LOCAL
ls.config.encrypt = true;

// COMPONENT
function SignInForm() {
  const navigate = useNavigate();
  const authService = new AuthService();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const labelContainerStyling = "flex flex-col gap-[10px]";
  const labelStyling = "text-sm leading-[14px] text-gs-text";
  const errorStyling = "text-sm leading-[14px] text-red-700";
  const inputContainerStyling =
    "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
  const fieldStyling =
    "w-full outline-none text-sm leading-[14px] text-gs-subText";

    const handleSignIn = async(values: Values) => {
      if(!values.email && !values.password) return;

      setLoading(true);
      try{
        const response = await authService.login(values);
        setLoading(false);
        console.log(response);

        if(!response.status){
          NotificationService.error({
            message: 'Login Failed!',
            addedText: response.message
          });
          return;
        }

        NotificationService.success({
          message: response.message
        });

        if(response.data.setupComplete){
          dispatch(setUserInfo(response?.data));
          ls.set('gs-token', response?.data?.token);
          navigate('/app/home');
        }else{
          ls.set('gs-token', response?.data?.token);
          navigate("/auth/account-setup");
        }
          // localStorage.setItem('gs', response?.data?.token);
        
      }catch(err: any){
        setLoading(false);
        NotificationService.error({
          message: 'Login Failed!',
          addedText: err?.message
        });
        return;
      }

        // NotificationService.success({
        //   message: 'Login Successful!'
        // })
        // navigate('/app/home');
      // }else {
      //   NotificationService.error({
      //     message: 'Login failed!',
      //     addedText: "login credentials incorrect, try again"
      //   })
      // }
    }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={handleSignIn}
    >
      {({ errors, touched }) => (
        <Form className="py-8 flex flex-col gap-6">
          {/* EMAIL */}
          <div className={labelContainerStyling}>
            <label htmlFor="email" className={labelStyling}>
              Email
            </label>
            {errors.email && touched.email ? (
              <p className={errorStyling}>{errors.email}</p>
            ) : null}
            <div className={inputContainerStyling}>
              <Field
                id="email"
                name="email"
                placeholder="Enter your email address"
                type="email"
                className={fieldStyling}
              />
            </div>
          </div>

          {/* PASSWORD */}
          <div className={labelContainerStyling}>
            <label htmlFor="password" className={labelStyling}>
              Password
            </label>
            {errors.password && touched.password ? (
              <p className={errorStyling}>{errors.password}</p>
            ) : null}
            <div className={`${inputContainerStyling} flex`}>
              <Field
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="*******"
                className={fieldStyling}
              />
              <img
                src={showPassword ? ShowPassword : HidePassword}
                alt="Show Password"
                className="w-6 h-6"
                onClick={() => setShowPassword((prev: boolean) => !prev)}
              />
            </div>
          </div>

          <div>
            <Link to="/auth/reset-password" className="text-gs-primary">
              Forgot Password
            </Link>
          </div>

          <Button
            isInverted={false}
            type="submit"
            text={loading ? <ScaleLoader color='#fff' height={12} width={4} /> :"Login"}
            category="button"
            btnStyle="w-full py-3 px-6"
          />
        </Form>
      )}
    </Formik>
  );
}

export default SignInForm;
