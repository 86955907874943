import React, {useState} from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import {Button} from "../../../../../components/ui";
import userService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import UserService from "../../../../../services/user.service";
import {ScaleLoader} from "react-spinners"; // Import your user service

type Props = {
    closeModal: () => void;
};

const inputStyle = "flex items-center w-full border rounded-[15px]";
const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const formatNumber = (value: number | string) => {
    if (value === '' || isNaN(Number(value))) return '';
    return Number(value).toLocaleString('en-US');
};

const parseNumber = (value: string) => {
    return Number(value.replace(/,/g, ''));
};

function RepayLoanModal({closeModal}: Props) {
    const [amount, setAmount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = parseNumber(value);
        setAmount(numericValue);
    };

    const handlePay = async () => {
        if (!amount) {
            NotificationService.error({message: "Please enter a valid amount."});
            return;
        }
        const userService = new UserService(); // Initialize your user service here

        setIsLoading(true);

        try {
            const response = await userService.repayLoan({amount: parseFloat(String(amount))});
            setIsLoading(false);

            if (response.status) {
                NotificationService.success({message: "Loan repayment successful!"});
                closeModal();
                window.location.reload();
            } else {
                NotificationService.error({message: response?.message || "Loan repayment failed."});
                closeModal();
            }
        } catch (error) {
            setIsLoading(false);
            NotificationService.error({message: "An error occurred. Please try again."});
            closeModal();
        }
    };

    return (
        <div className="grid">
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem]">
                    How much do you want to repay
                </h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()}
                    className="hover:cursor-pointer"
                />
            </div>

            <div className="mt-7">
                <label className={`${labelStyle}`}>Loan amount</label>
                <div
                    className={`${inputStyle} p-[12px] 'border-gs-borderInput' gap-x-3`}>
                    <div className="text-gs-subText">&#8358;</div>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        placeholder="0.00"
                        value={formatNumber(amount)}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>

            <p className="font-[300] text-[15px] mt-7 leading-[22px] text-gs-subSubText">
                The amount specified here is what you want to repay.
            </p>

            <div className="flex flex-wrap lg:justify-end gap-2 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                />
                <Button
                    btnStyle="px-[70px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}
                    handleClick={handlePay}
                    text={isLoading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Pay"}
                    disabled={isLoading} // Disable button while loading
                />
            </div>
        </div>
    );
}

export default RepayLoanModal;
