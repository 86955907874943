import React from "react";
import { Switch } from "@mui/material";

interface CustomSwitchType
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: any;
  handleChange?: () => void;
  checked?: boolean;
}

const CustomSwitch = (props: CustomSwitchType) => {
  const { handleChange, checked } = props;
  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      size={"small"}
      color={"success"}
      sx={{
        "& .MuiSwitch-track": {
          backgroundColor: "#E3E5E5",
        },
      }}
    />
  );
};

export default CustomSwitch;
