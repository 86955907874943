import userAvatar from '../../../../../assets/images/user-avatar.png';
import { getDate12HoursFormat } from '../../../../../components/custom-hooks';

export interface ChatProps {
    id: string;
    userImg?: string;
    name?: string;
    msg?: string;
    unread?: number;
    time?: any;
}

interface ChatItemProps {
    isSelected: string;
    setIsSelected: (arg: string) => void;
    chat: ChatProps;
}

function ChatItem({ isSelected, setIsSelected, chat }: ChatItemProps) {
    return (
        <div 
            onClick={() => setIsSelected(chat.id)} 
            className={`w-full flex items-center justify-between py-4 border-b border-gs-cardBg/[.03] hover:cursor-pointer hover:bg-gs-cardBg/[.03] hover:pl-1.5 hover:rounded-md pr-3  ${isSelected === chat.id && 'bg-gs-cardBg/[0.03] pl-3.5 pr-4 rounded-md'}`}
            >
            <div className="flex items-center gap-x-3">
                <div className="h-[45px] w-[45px] rounded-full flex items-center">
                    <img src={userAvatar} alt="user" className="h-[45px] w-[45px] rounded-full" />
                </div>
                <div className="grid gap-y-1">
                    <p className="text-[15px] leading-[24px] font-[700] text-gs-text">{ chat.name }</p>
                    <p className="text-[13px] leading-[21px] font-[300] text-gs-subText">{ chat.msg }</p>
                </div>
            </div>
            <div className="grid gap-y-1">
                <p className="text-[13px] leading-[21px] font-[300] text-gs-subText">{getDate12HoursFormat(chat.time)}</p>   
                { chat.unread !== 0 &&
                <div className="flex justify-end">
                    <p className='rounded-[4px] bg-gs-primary/[.1] text-gs-primary px-2 py-1 text-[11.5px] w-fit'>{ chat.unread }</p>
                </div>
                }
            </div>
        </div>
    )
}

export default ChatItem;