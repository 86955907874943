import attachmentIcon from "../../../../../assets/icons/attachment-icon.svg";
import emojiIcon from "../../../../../assets/icons/emoji-icon.svg";
import sendIcon from "../../../../../assets/icons/send-icon.svg";
import userAvatar from '../../../../../assets/images/user-avatar.png';
import SingleMessage, { MessageProps } from "./SingleMessage";

const messages = [
    { id: '1', text: 'Hey what is the  progress of the project?', status: 'recieved', date: new Date()},
    { id: '2', text: 'We are making progress, i will share with you the milestone document', status: 'sent', date: new Date()},
    { id: '3', text: 'Did you get my message?', status: 'sent', date: new Date()},
    { id: '1', text: 'Hey what is the  progress of the project?', status: 'recieved', date: new Date()},
    { id: '3', text: 'Did you get my message?', status: 'sent', date: new Date()},
    { id: '1', text: 'Hey what is the  progress of the project?', status: 'recieved', date: new Date()},
]

function ChatDisplay() {
    return (
        <div className="px-[2rem] relative h-[70vh]">
            <div className="w-full flex items-center gap-x-3 border-b-2 border-gs-cardBg/[.03] py-2.5">
                <div className="h-[45px] w-[45px] rounded-full flex items-center">
                    <img src={userAvatar} alt="user" className="h-[45px] w-[45px] rounded-full" />
                </div>
                <p className="text-[15px] leading-[24px] font-[700] text-gs-text ">John Doe</p>
            </div>

            {/* chats display  */}
            <div className="w-full bg-white h-[55vh] overflow-y-auto pb-[2rem] pt-[.5rem]">
                {messages.map((message: any) => (
                    <SingleMessage
                        message={message}
                        key={message.id}
                    />
                ))}
            </div>

            {/* chat input  */}
            <div className="w-full flex items-center gap-x-2.5 bg-gs-cardBg/[.03] border border-gs-cardBg/[.05] py-[2px] rounded-md pl-4 pr-7">
                <img src={attachmentIcon} alt="attachments" />
                <textarea
                    className="w-full bg-transparent resize-none py-3 text-[14px] outline-none font-[300]" 
                    placeholder="Reply message"
                    rows={1}></textarea>
                <div className="flex gap-x-2 items-center">
                    <img src={emojiIcon} alt="emoji" />
                    <img src={sendIcon} alt="send" className="bg-gs-primary px-2.5 py-1 rounded-[6px]" />
                </div>
            </div>
        </div>
    )
}

export default ChatDisplay;