import { useRef, useState } from "react";
import angleUp from "../../../../../assets/icons/angleUp.svg";
import angleDown from "../../../../../assets/icons/angleDown.svg";
import copyIcon from "../../../../../assets/icons/copyWhite.svg";
import copyGreenIcon from "../../../../../assets/icons/copyGreen.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { handleCopy } from "../../../../../components/custom-hooks";

const account = {
    bank: 'GreenSwift Bank ',
    number: '1234567890',
    holder: 'Chris Morgan'
}

function AccountDetails() {
    const acctNumRef: any = useRef(null);
    const bankNameRef: any = useRef(null);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const [ showMoreDetails, setShowMoreDetails ] = useState(false);

    const labelStyle = "text-[12px] tracking-wide leading-[16px] font-[500] text-gs-subText";
    const copyBtnStyle = "text-gs-primary border border-gs-primaryLess2/[.8] px-[10px] py-[8px] flex items-center gap-x-2 rounded-[7px] hover:cursor-pointer";
    const pStyle = "text-[14px] leading-[22px] font-bold flex items-center gap-x-1";

    return (
        <div className="w-full border border-gs-primaryLess2/[.2] rounded-[12px] p-5">
            <div onClick={() => setShowMoreDetails(!showMoreDetails)}  className="flex justify-between items-center">
                <h2 className="text-[16px] leading-[16px] font-semibold">Bank Account details </h2>

                <div  className="flex items-center gap-x-3">
                    <div 
                    onClick={handleCopy}
                    className={`${copyBtnStyle}`}>
                        <p className="text-[12px]">Copy Account Details</p>
                        <img src={copyIcon} alt="copy" />
                    </div>
                    <img 
                        src={showMoreDetails ? angleUp : angleDown} 
                        alt="toggle" 
                        className="w-[18px]" />
                </div>
            </div>

            { showMoreDetails &&
                <div className="mt-5">
                    <div className="lg:flex grid lg:gap-x-[6rem]">
                        <div className="grid">
                            <label className={`${labelStyle}`}>ACCOUNT HOLDER</label>
                            <p className={`${pStyle} capitalize`}>{userInfo.fullName}</p>
                        </div>
                        <div className="grid">
                            <label className={`${labelStyle}`}>ACCOUNT NUMBER</label>
                            <p className={`${pStyle}`}>
                                <p ref={acctNumRef}>{userInfo.Accounts[0].accountNumber || '**********'}</p>
                                <img 
                                    src={copyGreenIcon} 
                                    onClick={() => handleCopy(acctNumRef)} 
                                    alt="copy" 
                                    className="hover:cursor-pointer" />
                            </p>
                        </div>
                        <div className="grid">
                            <label className={`${labelStyle}`}>BANK NAME</label>
                            <p className={`${pStyle}`}>
                                <p ref={bankNameRef}>{userInfo.Accounts[0].bankName || account.bank}</p>
                                <img src={copyGreenIcon} onClick={() => handleCopy(bankNameRef)} alt="copy" className="hover:cursor-pointer" />
                            </p>
                        </div>
                    </div>
                    <div className="border border-dashed border-gs-blue bg-gs-blueLess1 rounded-[8px] mt-5 p-7">
                        <p className="text-[15px] font-bold mb-3 tracking-wide">Note</p>
                        <ul className="text-[15px] list-disc ml-5">
                            <li className="font-extralight">The account can only receive funds in <span className="font-semibold">Nigerian Naira (NGN).</span></li>
                            <li className="font-extralight">Payments will take a few mins to reflect. </li>
                            <li className="font-extralight">There are no additional fees on deposits.</li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}


export default AccountDetails;