import { useState } from "react";
import InvestmentAmountModalStep1 from "./InvestmentAmountModalStep1";
import InvestmentAmountModalStep2 from "./InvestmentAmountModalStep2";
import SubmissionSuccess from "../../../../../components/ui/SubmissionSuccess";

interface ModalProps {
  closeModal: () => void;
}

function InvestmentModal({ closeModal }: ModalProps) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderFormSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <InvestmentAmountModalStep1
            closeModal={closeModal}
            onNextStep={handleNextStep}
          />
        );
      case 1:
        return (
          <InvestmentAmountModalStep2
            closeModal={closeModal}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 2:
        return (
          <SubmissionSuccess
            title="You made that Money Move!"
            subTitle="Your investment was successful"
            closeModal={closeModal}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderFormSteps()}</>;
}
export default InvestmentModal;
