import { NavLink } from "react-router-dom";
import { MonthSelect } from "./components";
import DonutGraph from "./components/DonutGraph";
import GraphLine from "./components/LineGraph";

const data = [15000, 18000, 22000, 19000, 25000];
const donut_data = [1500, 2200, 2500];

function StatisticsSection() {
  const handleMonth = (_arg: string) => {};

  return (
    <div className="w-full relative z-[1] overflow-x-auto">
      <div className="h-full grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-[2rem]">
        <div className="lg:col-span-3 md:col-span-2 col-span-1  bg-white border border-gs-borderInput rounded-[14.5px]">
          <div className="flex justify-between items-end pl-5 pr-3 pt-4">
            <p className="text-[16px] font-semibold text-[#0A112F]">Analysis</p>
            <MonthSelect setMonth={(data) => handleMonth(data)} />
          </div>
          <h2 className="pl-5 text-[27px] font-bold py-1">₦0</h2>
          <div className="w-full pb-3">
            <GraphLine data={data} />
          </div>
        </div>

        <div className="lg:col-span-2 col-span-1 bg-white border border-gs-borderInput rounded-[14.5px]">
          {/* loan summary header  */}
          <div className="flex justify-between items-start px-5 pt-4 mb-2">
            <p className="text-[16px] font-semibold text-[#0A112F]">
              Loan Summary
            </p>
            <NavLink
              to=""
              className="text-gs-primary text-[12px] font-normal tracking-wide"
            >
              View details
            </NavLink>
          </div>
          {/* loan summary details  */}
          <div className="grid gap-y-3 pl-5 pr-3">
            <div className="border-l-[3px] border-gs-primary px-2">
              <p className="text-[11px] font-normal">Total payment</p>
              <p className="text-[19px] font-[500]">₦0.00</p>
            </div>
            <div className="border-l-[3px] border-[#31B099] px-2">
              <p className="text-[11px] font-normal">Total outstanding</p>
              <p className="text-[19px] font-[500]">₦0.00</p>
            </div>
            <div className="border-l-[3px] border-gs-primary px-2">
              <p className="text-[11px] font-normal">Total paid</p>
              <p className="text-[19px] font-[500]">₦0.00</p>
            </div>
          </div>

          {/* radial chart  */}
          <div className="px-5">
            <DonutGraph data={donut_data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsSection;
