import { useState } from "react";
import ProjectInformation from "./ProjectInformation";
import ProjectFundingDetails from "./ProjectFundingDetails";
import ProjectDocument from "./ProjectDocument";
import ProjectPreview from "./ProjectPreview";

function NewProjectFormStep() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderFormSteps = () => {
    switch (activeStep) {
      case 0:
        return <ProjectInformation onNextStep={handleNextStep} />;
      case 1:
        return (
          <ProjectFundingDetails
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
          />
        );
      case 2:
        return (
          <ProjectDocument
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
          />
        );
      case 3:
        return (
          <ProjectPreview
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderFormSteps()}</>;
}
export default NewProjectFormStep;
