import { AppLayout } from "../../../../components/layouts";
import backIcon from "../../../../assets/icons/arrow-left-grey.svg";
import { useNavigate } from "react-router-dom";
import { TransactionTable } from "../../components";
import emptyTransactionIcon from "../../../../assets/icons/no-transaction.svg";
import { FilterInput } from "../../../../components/ui";
import { useEffect, useState } from "react";
import NotificationService from "../../../../services/notification.service";
import UserService from "../../../../services/user.service";



const options = [
    { value: 'show-all', name: 'Show All' },
    { value: 'debit', name: 'Debit' },
    { value: 'credit', name: 'Credit' },
    // { value: 'greenswift', name: 'Greenswift' },
    // { value: 'bank', name: 'Bank' },
]

function TransactionHistory() {
    const navigate = useNavigate();
    const userService = new UserService();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ pageNum, setPageNum ] = useState(1);

    // get transaction by page number
    // useEffect(() => {
    //     getTransactions(pageNum);
    // }, [pageNum])

    useEffect(() => {
        getTransactions();
    }, [])

    const getTransactions = async() => {
        setLoading(true)
        try{
            const response = await userService.allTransactions();
            setLoading(false);
            if(!response.status){
                NotificationService.error({
                    message: response?.message
                })
                return;
            }
            setTransactions(response.data.data)
        }catch(err: any){
            setLoading(false);
            NotificationService.error({
                message: err?.message
            })
        }
    }

    const getTransactionsByFilterKey = async(_arg: string) => {
        setLoading(true)
        try{
            const response = await userService.allTransactionsByFilterKey(_arg);
            setLoading(false);
            if(!response.status){
                NotificationService.error({
                    message: response?.message
                })
                return;
            }
            setTransactions(response.data.data)
        }catch(err: any){
            setLoading(false);
            NotificationService.error({
                message: err?.message
            })
        }
    }

    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">Transactions</h2>}
        >
            <div className="px-[2rem] py-[3rem]">
                <div className="text-[16px] text-gs-subText hover:cursor-pointer font-normal leading-[1rem] flex items-center gap-x-3 py-2">
                    <img src={backIcon} alt="back" onClick={() => navigate(-1)} className="hover:cursor-pointer" />
                    Back
                </div>
                <div className="w-full flex justify-between mt-1 py-5">
                    <div className="flex gap-x-5 items-center">
                        <label className="text-[16px] text-black">Filter by</label>
                        <div>
                            <FilterInput options={options} />
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white px-[1.5rem] mt-3">
                    <TransactionTable 
                        data={transactions}
                        emptyIcon={emptyTransactionIcon}
                        emptyText="You have not made any transaction yet"
                        emptyTextMain="No Transactions"
                        onClick={true}
                    />
                </div>
            </div>
        </AppLayout>
    )
}

export default TransactionHistory;