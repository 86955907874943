import { ReactNode } from "react"
import LeftPane from "./LeftPane"
import RightPane from "./RightPane";

interface LayoutProps {
    children: ReactNode,
    text: any
}

function AppLayout({ text, children }: LayoutProps) {
    return (
        <div className="w-full h-[100vh] grid lg:grid-cols-6 md:grid-cols-7 grid-cols-7">
            <div className="lg:col-span-1 md:col-span-1 col-span-1 shadow h-full">
                <LeftPane />
            </div>
            <div className="lg:col-span-5 md:col-span-6 col-span-6 h-full relative"> 
                <div className="absolute top-0 w-full border-b border-b-gs-borderInput">
                    <RightPane text={text} />
                </div>
                <div className="mt-[4.7rem] bg-[#FCFDFD] h-[90vh] overflow-y-auto ">{children}</div>
            </div>
        </div>
    )
}

export default AppLayout;