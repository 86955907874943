function getTimeFormat(date: any) {
    if(!date) return;
    const newDate = new Date(date);
    const formattedTime = newDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

    return formattedTime;
}

export default getTimeFormat;