import { useState } from "react";
import Caution from "../../../../../assets/icons/CautionIcon.svg";
import Close from "../../../../../assets/icons/closex.svg";
import Icon1 from "../../../../../assets/icons/Icon.svg";
import Icon2 from "../../../../../assets/icons/Icon-1.svg";
import Icon3 from "../../../../../assets/icons/Icon-2.svg";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import UploadIcon from "../../../../../assets/icons/UploadIcon.svg";
import { Button, CustomModal } from "../../../../../components/ui";

interface Props {
  onNextStep: () => void;
  onPrevStep: () => void;
}

function ProjectDocument({ onNextStep, onPrevStep }: Props) {
  const [showModal, setShowModal] = useState(false);

  const projectDocument = [
    {
      icon: Icon1,
      name: "Any additional relevant documents",
      description: "Not submitted",
    },
    {
      icon: Icon2,
      name: "Any additional relevant documents",
      description: "Not submitted",
    },
    {
      icon: Icon3,
      name: "Any additional relevant documents",
      description: "Not submitted",
    },
  ];
  const [notification, setNotification] = useState(true);
  return (
    <div>
      {notification && (
        <div className="max-w-[672px] w-full m-auto bg-[#FEEDDA] rounded-xl py-4 px-6 flex gap-[315px]">
          <div className="flex gap-8">
            <img src={Caution} alt="caution" />
            <p>Please upload these documents</p>
          </div>
          <img
            src={Close}
            alt="close"
            className="cursor-pointer"
            onClick={() => setNotification(false)}
          />
        </div>
      )}
      <div className="mt-[41px]">
        <h3 className="text-center text-gs-text text-2xl font-bold mb-8">
          Project document
        </h3>
        <div className="max-w-[720px] m-auto w-full border border-gs-borderInput rounded-[14.5px] bg-white py-[50px] px-8">
          <div className="space-y-4 mb-8">
            {projectDocument.map((doc, i) => (
              <div
                key={i}
                className="flex py-5 px-4 border border-dashed border-gs-grey300 rounded-[10px] justify-between items-center"
              >
                <div className="flex gap-2">
                  <img src={doc.icon} alt="icon" />
                  <div className="max-w-[447px] w-full">
                    <p className="text-gs-grey900 font-medium">{doc.name}</p>
                    <p className="text-sm text-[#FBB25C]">{doc.description}</p>
                  </div>
                </div>
                <Button
                  isInverted={true}
                  text="Upload"
                  category="link"
                  btnStyle="py-[10px] px-5 !rounded-[100px] text-gs-primary"
                  handleClick={() => setShowModal(true)}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-[15px]">
            <Button
              isInverted={true}
              text="Back"
              category="link"
              btnStyle="py-3 px-[51px] text-gs-text"
              handleClick={onPrevStep}
            />
            <Button
              isInverted={false}
              type="submit"
              text="Next"
              category="button"
              btnStyle="py-3 px-[51px]"
              handleClick={onNextStep}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowModal(false)}
        >
          <div className="flex justify-between items-center">
            <p className="font-semibold text-xl">Additional document </p>
            <img
              src={closeIcon}
              alt="close"
              onClick={() => setShowModal(false)}
              className="cursor-pointer"
            />
          </div>
          <label htmlFor="fileUpload">
            <div className="bg-gs-shadesOfBlue98 mt-6 py-[56px] px-[80px] rounded-lg border border-dashed border-gs-mainColorPrimary w-full flex flex-col gap-6 items-center">
              <input
                name="fileUpload"
                id="fileUpload"
                accept=".pdf, .doc, .docx"
                type="file"
                className="hidden"
              />
              <img src={UploadIcon} alt="upload" />
              <p className="font-medium text-gs-primary">
                Click here or drag file to upload
              </p>
            </div>
          </label>
          <p className="text-sm text-[#9096A2] my-4">
            Supported formats: PDF, docs
          </p>
          <div className="flex justify-end">
            <Button
              isInverted={false}
              type="submit"
              text="Upload"
              category="button"
              btnStyle="py-3 px-[51px]"
            />
          </div>
        </CustomModal>
      )}
    </div>
  );
}

export default ProjectDocument;
