import { useEffect, useState } from "react";

function AccountSetupStepIndicator({ currentStep }: any) {
  const [currentStepColor, setCurrentStepColor] = useState(0);

  useEffect(() => {
    setCurrentStepColor(currentStep);
  }, [currentStep]);

  const indicatorContainer =
    "bg-white flex items-center gap-[11px] py-[10px] px-2 rounded-lg shadow-[4px_4px_184px_0_rgba(209,226,242,0.88)]";
  const indicatorNumberContainer =
    "bg-gs-subText w-[17px] h-[17px] flex flex-col justify-center items-center rounded-full";
  const indicatorNumber = "text-white text-[7.6px] leading-[12.2px]";
  const indicatorDescription =
    "font-medium text-sm leading-[22.4px] text-gs-text";

  return (
    <div className="flex items-center">
      <div className={indicatorContainer}>
        <div
          className={`${indicatorNumberContainer} ${
            currentStepColor >= 0 ? "!bg-gs-primary" : ""
          } `}
        >
          <p className={indicatorNumber}>1</p>
        </div>
        <p className={indicatorDescription}>Identity verification</p>
      </div>
      <hr className="border border-gs-primary w-[34px]" />
      <div className={indicatorContainer}>
        <div
          className={`${indicatorNumberContainer} ${
            currentStepColor >= 1 ? "!bg-gs-primary" : ""
          } `}
        >
          <p className={indicatorNumber}>2</p>
        </div>
        <p className={indicatorDescription}>Document verification</p>
      </div>
      <hr className="border border-gs-primary w-[34px]" />
      <div className={indicatorContainer}>
        <div
          className={`${indicatorNumberContainer} ${
            currentStepColor === 2 ? "!bg-gs-primary" : ""
          } `}
        >
          <p className={indicatorNumber}>3</p>
        </div>
        <p className={indicatorDescription}>KYC Review</p>
      </div>
    </div>
  );
}

export default AccountSetupStepIndicator;
