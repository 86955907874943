// Function to generate a unique random 4-character number
function generateUniqueNumber() {
    const min = 1000; // Minimum value
    const max = 999999999999999999999; // Maximum value
    let uniqueNumbers = new Set(); // Set to store generated numbers

    while (true) {
        // Generate a random number within the specified range
        let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        // Convert the number to a string and pad with leading zeros if necessary
        let randomNumberString = randomNumber.toString();
        while (randomNumberString.length < 4) {
            randomNumberString = "0" + randomNumberString;
        }

        if(randomNumberString.length > 4){
            randomNumberString = randomNumberString.slice(0,5)
        }

        // Check if the generated number is unique
        if (!uniqueNumbers.has(randomNumberString)) {
            // Add the number to the set of unique numbers
            uniqueNumbers.add(randomNumberString);
            // Return the unique number
            return randomNumberString;
        }
    }
}

export default generateUniqueNumber;