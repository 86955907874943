import React, { useState } from "react";

import toast_success from "../../assets/icons/toast.success.svg";
import toast_error from "../../assets/icons/toast.error.svg";
import toast_warn from "../../assets/icons/toast.warn.svg";

export interface ToastModel {
  message: any;
  addedText?: any | null;
  position?:
    | "bottom-left"
    | "bottom-right"
    | "bottom-center"
    | "top-right"
    | "top-left"
    | "top-center";
  type: "success" | "error" | "warn" | "info";
  delay?: number;
}

function CustomNotification({ message, addedText, position, type }: ToastModel) {
  //   return and icon path based on the notification type
  const _toast_type = () => {
    if (type === "success") return toast_success;
    if (type === "error") return toast_error;
    // if(type === 'info') return '../../../'
    if (type === "warn") return toast_warn;
  };

  //   return a position style based on position entered
  const _toast_position = () => {
    if (position === "bottom-right") return "bottom-7 md:right-7 right-0";
    if (position === "bottom-left") return "bottom-7 md:left-7 left-0";
    if (position === "bottom-center")
      return "bottom-[6rem] md:left-[30%] left-0 right-0 md:right-[30%]";
    if (position === "top-right") return "top-7 md:right-7 right-0";
    if (position === "top-left") return "top-7 md:left-7 left-0";
    if (position === "top-center")
      return "top-[2.5rem] md:left-[30%] left-0 right-0 md:right-[30%]";
  };

  return (
    <div
      className={`
            ${
              type === "success"
                ? "bg-[#DEFFF5] border-[#1CBE8E]"
                : type === "error"
                ? "bg-[#FAEAEC] border-[#B22735]"
                : "bg-[#FFFBE5] border-[#FFE033]"
            } 
                p-4 lg:w-[30%] md:w-[40%] sm:w-[50%] w-[99%] rounded-[4px] shadow-sm shadow-gray-600/[.3] border-l-[4px] absolute z-[9999999999] translate-y-0 opacity-100 ${
                  position ? _toast_position() : "lg:top-5 top-1 lg:right-5 right-0"
                }
        `}
    >
      <p className="flex justify-between items-center">
        <span className="text-[17px] font-bold">{message}</span>
        <img src={_toast_type()} alt="toast icon" width={25} height={25} />
      </p>
      {addedText && <p className="text-[13px]">{addedText}</p>}
    </div>
  );
}

export default CustomNotification;
