import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReviewCard from './ReviewCard';
import userF from '../../../assets/images/author-f.png';
import userM from '../../../assets/images/author-m.png';
import next from '../../../assets/icons/arrow.svg';
import previous from '../../../assets/icons/larrow.svg';
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

const reviews = [
    {
        userName: "Mikasa Ackerman",
        userImg: userF,
        reviewText: 'Lorem ipsum dolor sit amet consectetur. Diam semper odio consectetur faucibus tristique nec vitae volutpat volutpat.',
        rating: 4.8
    },
    {
        userName: "Eren Yaeger",
        userImg: userM,
        reviewText: 'Lorem ipsum dolor sit amet consectetur. Risus morbi ultrices sit hac condimentum adipiscing. Risus auctor.',
        rating: 4.8
    },
    {
        userName: "Joe Michealson",
        userImg: userF,
        reviewText: 'Lorem ipsum dolor sit amet consectetur. Diam semper odio consectetur faucibus tristique nec vitae volutpat volutpat.',
        rating: 4.8
    },
    {
        userName: "Kun Aguero",
        userImg: userM,
        reviewText: 'Lorem ipsum dolor sit amet consectetur. Risus morbi ultrices sit hac condimentum adipiscing. Risus auctor.',
        rating: 4.8
    },
]

function SectionFour() {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 2 },
    }

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section     
        className="section-reviews"
        >
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-5 gap-y-5 ">
                <div className="md:order-1 order-2 col-span-1 ">
                    <div data-aos="fade-right" className='w-full relative  md:mt-[10rem] mt-[1rem] pl-6 mx-auto'>
                        <AliceCarousel
                            mouseTracking
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            autoPlayInterval={5000}
                            infinite={true}
                            keyboardNavigation={true}
                            renderPrevButton={() => {
                                return <img src={previous} className="absolute md:-left-[1.5rem] md:block hidden top-[8rem] " alt='previous' />
                            }}
                            renderNextButton={() => {
                                return <img src={next} className="absolute md:-right-[2.6rem] md:block hidden top-[6rem] hover:cursor-pointer" alt='next' />
                            }}
                        >
                            {reviews.map((review: any) => (
                                <ReviewCard
                                    userImg={review.userImg}
                                    name={review.userName}
                                    rating={review.rating}
                                    reviewText={review.reviewText}
                                />
                            ))}
                        </AliceCarousel>
                    </div>
                </div>
                <div className="md:order-2 order-1 col-span-1 ">
                    <div className="w-fit h-fit block items-center md:px-[5rem] px-[2rem]  md:pt-[8rem] md:pb-[5rem] pt-[2rem] pb-[1rem] ">
                        <div data-aos="fade-up" className="w-fit bg-[#353E49] tracking-wide rounded-[80px] px-7 py-1.5 flex items-center mt-[3rem] mb-5 font-extralight text-white">
                            <p>Testimonials from user 🤩</p>
                        </div>
                        <h2 data-aos="fade-up" className="font-bold mb-[1.5rem] md:text-[56px] text-white text-[35px] md:leading-[80px] leading-[60px]">User Experiences and Reviews</h2>
                        <p data-aos="fade-up" className="md:text-[18px] text-[15px] font-[300] md:mb-[2.7rem] text-white mb-[1.7rem] leading-8">We redefine your banking experience: some of our users had these to say about their experiences so far with greenswift. </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionFour