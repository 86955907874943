import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import dangerImg from "../../../../../assets/icons/danger-img.svg";

interface RemoveModalProps {
    data?: any,
    closeModal: () => void;
}

function CloseAccountModal({ closeModal, data }: RemoveModalProps) {
    const btnStyle =  "px-[35px] py-[12px] font-[500] text-[16px] leading-[25.5px] rounded-[10px]";

    const handleCloseAccount = () => {}

    return (
        <div>
            <div className="w-fit float-right mb-[1.2rem]">
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <img src={dangerImg} alt="remove" className="mx-auto clear-both" />

            <h3 className="text-[18px] text-center font-[700] leading-[32px] text-gs-text my-2">Are you sure ?</h3>

            <p className="text-center text-[16px] font-[400] tracking-wide leading-[27.5px] text-gs-subSubText mt-2.5">Are you sure you want to close this account</p>

            <div className="w-full flex justify-between mt-7">
                <button onClick={() => closeModal()} className={`bg-white text-gs-text ${btnStyle}`}>Cancel</button>
                <button onClick={handleCloseAccount} className={`${btnStyle} bg-[#FF2116] text-white`}>Yes, close my account</button>
            </div>
        </div>
    )
}


export default CloseAccountModal;