interface Props {
  title?: string;
  subtitle?: string;
}

function AuthFormHeader({ title, subtitle }: Props) {
  return (
    <div className="pt-3 pb-[23px] border-b border-gs-border">
      <h3 className="font-bold text-2xl leading-8 text-gs-text3">{title}</h3>
      <p className="font-normal text-base leading-4 text-gs-subSubText mt-[15px]">
        {subtitle}
      </p>
    </div>
  );
}

export default AuthFormHeader;
