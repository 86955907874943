import arrowWhite from "../../../../assets/icons/arrowWhite.svg";
import arrowInverse from "../../../../assets/icons/arrowInverse.svg";
import signalWhite from "../../../../assets/icons/signalWhite.svg";
import signalInverse from "../../../../assets/icons/signalInverse.svg";

function AnalysisSection() {
    const boxStyle = "col-span-1 p-[21.5px] h-[170px] flex flex-col justify-between items-start rounded-[14.5px]";
    const boxBottomItem = "w-full flex justify-between items-center"
    const textStyle = "text-[16px] font-semibold";
    const textBiggerStyle = "text-[27px] font-bold";

    return (
        <div className="w-full h-fit overflow-x-auto">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[2rem]">
                <div className={`${boxStyle} bg-gs-primary text-white`}>
                    <div className="flex items-center gap-x-3">
                        <img src={signalWhite} alt="signal-icon" />
                        <p className={`${textStyle} font-semibold`}>Total Investors</p>
                    </div>
                    <div className={`${boxBottomItem}`}>
                        <p className={`${textBiggerStyle}`}>0</p>
                        <img src={arrowWhite} alt="arrow" />
                    </div>
                </div>

                <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
                    <div className="flex items-center gap-x-3">
                        <img src={signalInverse} alt="signal-icon" />
                        <p className={`${textStyle} text-gs-subSubText`}>Total Loan</p>
                    </div>
                    <div className={`${boxBottomItem}`}>
                        <p className={`${textBiggerStyle}`}>0</p>
                        <img src={arrowInverse} alt="arrow" />
                    </div>
                </div>

                <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
                    <div className="flex items-center gap-x-3">
                        <img src={signalInverse} alt="signal-icon" />
                        <p className={`${textStyle} text-gs-subSubText`}>Total Product</p>
                    </div>
                    <div className={`${boxBottomItem}`}>
                        <p className={`${textBiggerStyle}`}>0</p>
                        <img src={arrowInverse} alt="arrow" />
                    </div>
                </div>

                <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
                    <div className="flex items-center gap-x-3">
                        <img src={signalInverse} alt="signal-icon" />
                        <p className={`${textStyle} text-gs-subSubText`}>Total Project</p>
                    </div>
                    <div className={`${boxBottomItem}`}>
                        <p className={`${textBiggerStyle}`}>0</p>
                        <img src={arrowInverse} alt="arrow" />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AnalysisSection;