import {
    Footer,
    Jumbotron,
    Nav,
    SectionFive,
    SectionFour,
    SectionOne,
    SectionSix,
    SectionThree,
    SectionTwo
} from "./components";

function LandingPage() {
    return (
        <div>
            <Nav />
            <Jumbotron /> 
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            {/* <SectionFive /> */}
            <SectionSix />
            <Footer />
        </div>
    )
}

export default LandingPage;