import { useRef, useState } from "react";
import angleUp from "../../../../../assets/icons/angleUp.svg";
import angleDown from "../../../../../assets/icons/angleDown.svg";
import copyIcon from "../../../../../assets/icons/copyWhite.svg";
import copyGreenIcon from "../../../../../assets/icons/copyGreen.svg";
import { handleCopy } from "../../../../../components/custom-hooks";

const account = {
  number: "6190********5868",
  holder: "Chris Morgan",
  cvv: "123",
  expiryDate: "09/26",
  billingAddress: "651 N Green hub Street,\nMiddletown, Delaware",
  zipCode: "1234567",
};

function CardDetails() {
  const cvvRef: any = useRef(null);
  const zipRef: any = useRef(null);
  const acctRef: any = useRef(null);
  const expiryRef: any = useRef(null);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const labelStyle =
    "text-[12px] tracking-wide leading-[16px] font-[500] text-gs-subText";
  const copyBtnStyle =
    "text-gs-primary border border-gs-primaryLess2/[.8] px-[10px] py-[8px] flex items-center gap-x-2 rounded-[7px] hover:cursor-pointer";
  const pStyle =
    "text-[14px] leading-[22px] font-bold flex items-center gap-x-1";

  return (
    <div className="w-full border border-gs-primaryLess2/[.2] rounded-[12px] p-5">
      <div
        onClick={() => setShowMoreDetails(!showMoreDetails)}
        className="flex justify-between items-center"
      >
        <h2 className="text-[16px] leading-[16px] font-semibold">
          Card details{" "}
        </h2>

        <div className="flex items-center gap-x-3">
          <div className={`${copyBtnStyle}`}>
            <p className="text-[12px]">Copy Card Details</p>
            <img src={copyIcon} alt="copy" />
          </div>
          <img
            src={showMoreDetails ? angleUp : angleDown}
            alt="toggle"
            className="w-[18px]"
          />
        </div>
      </div>

      {showMoreDetails && (
        <div className="mt-5">
          {/* row 1 */}
          <div className="lg:flex grid justify-between">
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>ACCOUNT HOLDER</label>
              <p className={`${pStyle}`}>{account.holder}</p>
            </div>
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>CARD NUMBER</label>
              <p className={`${pStyle}`}>
                <p ref={acctRef}>{account.number} </p>
                <img
                  src={copyGreenIcon}
                  onClick={() => handleCopy(acctRef)}
                  alt="copy"
                  className="hover:cursor-pointer"
                />
              </p>
            </div>
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>CVV</label>
              <p className={`${pStyle}`}>
                <p ref={cvvRef}>{account.cvv}</p>
                <img
                  src={copyGreenIcon}
                  onClick={() => handleCopy(cvvRef)}
                  alt="copy"
                  className="hover:cursor-pointer"
                />
              </p>
            </div>
          </div>

          {/* row 2 */}
          <div className="lg:flex justify-between grid mt-5">
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>EXPIRY DATE</label>
              <p className={`${pStyle}`}>
                <p ref={expiryRef}>{account.expiryDate}</p>
                <img
                  src={copyGreenIcon}
                  alt="copy"
                  onClick={() => handleCopy(expiryRef)}
                  className="hover:cursor-pointer"
                />
              </p>
            </div>
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>BILLING ADDRESS</label>
              {account.billingAddress.split("\n").map((line, index) => (
                <p key={index} className={`${pStyle}`}>
                  {line}
                </p>
              ))}
            </div>
            <div className="max-w-[208px] w-full grid">
              <label className={`${labelStyle}`}>ZIP CODE</label>
              <p className={`${pStyle}`}>
                <p ref={zipRef}>{account.zipCode}</p>
                <img
                  src={copyGreenIcon}
                  onClick={() => handleCopy(zipRef)}
                  alt="copy"
                  className="hover:cursor-pointer"
                />
              </p>
            </div>
          </div>
          <div className="border border-dashed border-gs-blue bg-gs-blueLess1 rounded-[8px] mt-5 p-7">
            <p className="text-[15px] font-bold mb-3 tracking-wide">Note</p>
            <ul className="text-[15px] list-disc ml-5">
              <li className="font-extralight">
                You can fund you card straight from your NGN wallet.
              </li>
              <li className="font-extralight">
                Payments will take a few mins to reflect.{" "}
              </li>
              <li className="font-extralight">
                There are no additional fees on deposits.
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardDetails;
