import AliceCarousel from "react-alice-carousel";
import logoWhite from "../../../assets/icons/logo-white.svg";
import { authPretexts } from "../../../utils/constants";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";


function LeftCol(){
    useEffect(() => {
        AOS.init();
      }, []);


    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    }


    return (
        <div className="auth-bg-img h-[100vh] w-full ">
            {/* logo  */}
            <img data-aos="fade-right" src={logoWhite} alt="logo" className="md:w-[210px] relative top-[50px] left-[50px]" />
            {/* slider */}

            <div data-aos="fade-right" className="auth-layout relative top-[49%] px-[50px]">
                <AliceCarousel
                    mouseTracking
                    responsive={responsive}
                    controlsStrategy="responsive"
                    autoPlay={true}
                    autoPlayInterval={8000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableButtonsControls={true}
                >
                    {authPretexts.map((pretext: any, index: number) => (
                        <div key={index}>
                            <h1 className="pretext-h1 text-[37px] tracking-wide font-bold text-white mb-2">{pretext.headerText}</h1>
                            <p className="pretext-p text-[18px] font-extralight text-white/[.9] ">{pretext.subText}</p>
                        </div>
                    ))}
                </AliceCarousel>
            </div>
        </div>
    )
}

export default LeftCol;