import React from 'react';
import { getDateString, getTimeFormat } from '../../../../components/custom-hooks';
import { TradeProps } from './_model';
import EmptyState from '../../../../components/ui/EmptyState';
import emptyIcon from '../../../../assets/icons/no-transaction.svg';


const tableHeaderStyle =
  "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";
const boxStyle = "px-[2rem] md:w-full w-fit";


interface Props {
  data: TradeProps[]
}

export default function OutgoingTrade({ data }: Props) {
  return (
    <div className="relative">
        <div className={boxStyle}>
            { data?.length > 0 ?
                <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5">
                {/* table header */}
                <li className="flex py-2.5">
                    <div className={`${tableHeaderStyle} w-[15%]`}>RECIPIENT ID</div>
                    <div className={`${tableHeaderStyle} w-[20%]`}>AMOUNT</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>FROM</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>TO</div>
                    <div className={`${tableHeaderStyle} w-[15%]`}>DATE</div>
                    <div
                    className={`${tableHeaderStyle} w-[20%] flex justify-center`}
                    >
                    STATUS
                    </div>
                </li>
                {data.map((item: TradeProps, index: number) => {
                    const statusStyle =
                    item.status === "pending"
                        ? "text-gs-amber bg-gs-amberLess1"
                        : item.status === "completed"
                        ? "text-gs-primary bg-gs-primaryLess1"
                        : "text-gs-red bg-gs-redLess1";
                    return (
                    <li
                        key={index}
                        className={`flex items-center py-2.5 border-t border-[#F5F5F5] `}
                        // onClick={() => handleClick(item)}
                    >
                        <div className={`${tableBodyStyle} w-[15%]`}>
                            {item?.initiatorUid}
                        </div>

                        <div
                        className={`${tableBodyStyle} w-[20%] text-gs-text font-semibold`}
                        >
                        {item.amount}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] capitalize `}>
                            {item.fromCurrency}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] capitalize `}>
                            {item.toCurrency}
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] flex justify-center capitalize font-semibold `}>
                        <p className="text-gs-text font-semibold">
                            {getDateString(item.updatedAt)}
                        </p>
                        <p className="text-gs-subText font-normal">
                            {getTimeFormat(item.updatedAt)}
                        </p>
                        </div>

                        <div className={`${tableBodyStyle} w-[15%] flex justify-center capitalize font-semibold `}>
                        <p className={`${statusStyle} w-fit px-[25px] py-[6px] rounded-[4px]`}>
                            {item.status}
                        </p>
                        </div>
                    </li>
                    );
                })}
                </ul> :
                <EmptyState
                    emptyIcon={emptyIcon}
                    emptyText="You have no Outgoing Trade history"
                    emptyTextMain="No Outgoing P2P Transaction"
                />
            }
        </div>
      {/* {toggle && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[85%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setToggle(false)}
        >
          <TransactionPopup
            setToggle={() => setToggle(false)}
            data={selected}
          />
        </CustomModal>
      )} */}
    </div>
  )
}
