import InvestmentLogo1 from "../assets/icons/InvestmentLogo1.svg";
import InvestmentLogo2 from "../assets/icons/InvestmentLogo2.svg";
import InvestmentLogo3 from "../assets/icons/InvestmentLogo3.svg";
import InvestmentLogo4 from "../assets/icons/InvestmentLogo4.svg";
import FireIcon from "../assets/icons/FireIcon.svg";
import ModerateFireIcon from "../assets/icons/ModerateFireIcon.svg";
import VeryHighFireIcon from "../assets/icons/VeryHighFireIcon.svg";

const funds = [
  {
    id: 1,
    logo: InvestmentLogo1,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: FireIcon,
    risk: "Conservative",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 2,
    logo: InvestmentLogo2,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: ModerateFireIcon,
    risk: "Moderate",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 3,
    logo: InvestmentLogo3,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 4,
    logo: InvestmentLogo4,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 5,
    logo: InvestmentLogo1,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: FireIcon,
    risk: "Conservative",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 6,
    logo: InvestmentLogo2,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: ModerateFireIcon,
    risk: "Moderate",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 7,
    logo: InvestmentLogo3,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 8,
    logo: InvestmentLogo4,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 9,
    logo: InvestmentLogo1,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: FireIcon,
    risk: "Conservative",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 10,
    logo: InvestmentLogo2,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: ModerateFireIcon,
    risk: "Moderate",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 11,
    logo: InvestmentLogo3,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
  {
    id: 12,
    logo: InvestmentLogo4,
    company: "Saphore Inc Capital Money Market Fund",
    shortName: "Saphore Inc",
    companyDesc: " Inc Capital Money Market Fund",
    amount: "₦0.00",
    returnPercent: "+10.1%",
    riskIcon: VeryHighFireIcon,
    risk: "Very High",
    growthYear1: "2020",
    growthYear2: "2021",
    growthYear3: "2022",
    growthYearPercent: "10.5%",
    about:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .",
    suitability:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae.",
    termOfUse:
      "Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo . Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio nunc ullamcorper mauris commodo .Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio.",
    current: "₦ 2.6b",
    currentValue: "₦0.00",
  },
];

export default funds;
