function getDateTimeExtended(_arg: string | Date) {
    // Assuming you have a Date object
    var originalDate = new Date(_arg); // Note: Month is zero-based (0 = January, 11 = December)

    // Define the options for formatting the date
    const options: any = {
        month: 'short', // abbreviated month name
        day: '2-digit', // two-digit day of the month
        year: 'numeric', // four-digit year
        // hour: 'numeric', // hour in 12-hour clock format
        // minute: 'numeric', // minute
        // hour12: true, // use 12-hour clock format
    };

    // Format the date using the specified options
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(originalDate);

    // Manually construct the desired format
    const response = formattedDate + ' - ' + originalDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return response;
}

export default getDateTimeExtended;