import LandingPage from "./landing-page";
import {
  Home,
  Investment,
  P2P,
  Marketplace,
  MyProjects,
  Settings,
  Transfer,
  Wallet,
  Messages,
  SingleInvestment,
  InvestmentPortfolio,
  InvestMoreSingle,
  NewProject,
  SingleMarketInvestment,
} from "./web-app/pages";

export {
  LandingPage,
  Home,
  Investment,
  P2P,
  Marketplace,
  MyProjects,
  Settings,
  Transfer,
  Wallet,
  Messages,
  SingleInvestment,
  InvestmentPortfolio,
  InvestMoreSingle,
  NewProject,
  SingleMarketInvestment,
};
