import SignIn from "./signin/SignIn";
import SignUp from "./signup/SignUp";
import OtpVerification from "./verification/OtpVerification";
import ForgotPassword from "./forgot-password/ForgotPassword";
import ResetPassword from "./reset-password/ResetPassword";
import CreateNewPassword from "./create-new-password/CreateNewPassword";
import AccountSetup from "./account-setup/AccountSetup";
import ResetCode from "../auth/reset-code/ResetCode";

export {
  SignIn,
  SignUp,
  OtpVerification,
  ForgotPassword,
  ResetPassword,
  CreateNewPassword,
  AccountSetup,
  ResetCode,
};
