import React from "react";
import { AppLayout } from "../../../../components/layouts";
import LeftArrow from "../../../../assets/icons/arrow-left-grey.svg";

import { useNavigate } from "react-router-dom";
import PortfolioValue from "./components/PortfolioValue";
import StatisticsSection from "./components/StatisticsSection";
import PortfolioLetter from "./components/PortfolioLetter";

function InvestmentPortfolio() {
  const navigate = useNavigate();
  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Investment
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <div
          className="flex items-center gap-[6px] mb-8 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img src={LeftArrow} alt="Back" />
          <p className="font-bold text-sm text-gs-subText">Back</p>
        </div>
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <PortfolioValue />
      </div>

      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <StatisticsSection />
      </div>

      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <PortfolioLetter />
      </div>
    </AppLayout>
  );
}

export default InvestmentPortfolio;
