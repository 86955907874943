import emptyTransactionIcon from "../../../../../assets/icons/no-transaction.svg";
import { TransactionTable } from "../../../components";

function CardTransactions({ data }: any) {
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="mb-[2.5rem]">
        <h2 className="text-[18px] font-semibold text-gs-text leading-[27px]">
          Transactions
        </h2>
      </div>
      <TransactionTable
        data={data}
        emptyIcon={emptyTransactionIcon}
        emptyText="You have not made any transaction yet"
        emptyTextMain="No Transactions"
      />
    </div>
  );
}

export default CardTransactions;
