import NoInvestment from "../../../../../assets/icons/NoInvestment.svg";
import EmptyState from "../../../../../components/ui/EmptyState";
import InvestmentLogo1 from "../../../../../assets/icons/InvestmentLogo1.svg";
import InvestmentLogo2 from "../../../../../assets/icons/InvestmentLogo2.svg";
import InvestmentLogo3 from "../../../../../assets/icons/InvestmentLogo3.svg";
import InvestmentLogo4 from "../../../../../assets/icons/InvestmentLogo4.svg";
import SlantArrow from "../../../../../assets/icons/SlantArrow.svg";
import FireIcon from "../../../../../assets/icons/FireIcon.svg";
import ModerateFireIcon from "../../../../../assets/icons/ModerateFireIcon.svg";
import VeryHighFireIcon from "../../../../../assets/icons/VeryHighFireIcon.svg";

interface Investment {
  logo?: string;
  company?: string;
  amount?: string;
  returnPercent?: string;
  riskIcon?: string;
  risk?: string;
}

function MyInvestment() {
  // REUSABLE STYLING
  const headings = "font-medium text-gs-subText";

  const myInvestment: any = [
    {
      logo: InvestmentLogo1,
      company: "Saphore Inc Capital Money Market Fund",
      amount: "₦0.00",
      returnPercent: "+10.1%",
      riskIcon: FireIcon,
      risk: "Conservative",
    },
    {
      logo: InvestmentLogo2,
      company: "Saphore Inc Capital Money Market Fund",
      amount: "₦0.00",
      returnPercent: "+10.1%",
      riskIcon: ModerateFireIcon,
      risk: "Moderate",
    },
    {
      logo: InvestmentLogo3,
      company: "Saphore Inc Capital Money Market Fund",
      amount: "₦0.00",
      returnPercent: "+10.1%",
      riskIcon: VeryHighFireIcon,
      risk: "Very High",
    },
    {
      logo: InvestmentLogo4,
      company: "Saphore Inc Capital Money Market Fund",
      amount: "₦0.00",
      returnPercent: "+10.1%",
      riskIcon: VeryHighFireIcon,
      risk: "Very High",
    },
  ];
  return (
    <div>
      {myInvestment.length ? (
        <div className="flex flex-col gap-4">
          {myInvestment.map((investment: Investment, i: number) => (
            <div
              key={i}
              className="border border-[rgba(11,124,66,0.20)] py-4 pl-6 pr-4 rounded-lg flex items-center justify-between"
            >
              <img src={investment.logo} alt="Investment Logo" />
              <p className="max-w-[286px] w-full text-gs-text font-bold">
                {investment.company}
              </p>
              <div className="max-w-[146px] w-full">
                <p className={headings}>Current value</p>
                <p className="text-gs-text font-bold">{investment.amount}</p>
              </div>
              <div className="max-w-[445px] w-full flex items-center justify-between">
                <div className="max-w-[90px] w-full">
                  <p className={headings}>Returns</p>
                  <div className="flex">
                    <img src={SlantArrow} alt="arrow" />
                    <p className="text-gs-primary500 text-sm font-semibold">
                      {investment.returnPercent}
                    </p>
                  </div>
                </div>
                <div className="max-w-[130px] w-full">
                  <p className={headings}>Risk</p>
                  <div className="flex items-center gap-2">
                    <img src={investment.riskIcon} alt="risk level" />
                    <p
                      className={`${
                        investment.risk === "Very High"
                          ? "!text-[#C84308]"
                          : "text-gs-subSubText"
                      } font-medium text-xs `}
                    >
                      {investment.risk}
                    </p>
                  </div>
                </div>
                <button className="text-gs-subSubText border border-gs-greyscaleGrey200 px-5 py-[10px] rounded-[100px]">
                  view
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyState
          emptyIcon={NoInvestment}
          emptyText="You have not made any investment yet"
          emptyTextMain="No Investment"
        />
      )}
    </div>
  );
}

export default MyInvestment;
