import arrowWhite from "../../../../../assets/icons/arrowWhite.svg";
import arrowInverse from "../../../../../assets/icons/arrowInverse.svg";
import signalWhite from "../../../../../assets/icons/signalWhite.svg";
import signalInverse from "../../../../../assets/icons/signalInverse.svg";
import AddIcon from "../../../../../assets/icons/addIcon.svg";
import { Button } from "../../../../../components/ui";
import { useNavigate } from "react-router-dom";

function ProjectAnalysisSection() {
  const navigate = useNavigate();
  const boxStyle =
    "col-span-1 p-[21.5px] h-[170px] flex flex-col justify-between items-start rounded-[14.5px]";
  const boxBottomItem = "w-full flex justify-between items-center";
  const textStyle = "text-[16px] font-semibold";
  const textBiggerStyle = "text-[27px] font-bold";

  return (
    <div className="w-full h-fit overflow-x-auto">
      <div className="flex justify-end mb-[19px]">
        <Button
          isInverted={false}
          category="button"
          text="New project"
          btnIcon={AddIcon}
          btnStyle="flex gap-[10px] item-center py-3 px-[14px]"
          handleClick={() => navigate("/app/projects/new-project")}
        />
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[2rem]">
        <div className={`${boxStyle} bg-gs-primary card-bg-img text-white`}>
          <div className="flex items-center gap-x-3">
            <img src={signalWhite} alt="signal-icon" />
            <p className={`${textStyle} font-semibold`}>Total Project</p>
          </div>
          <div className={`${boxBottomItem}`}>
            <p className={`${textBiggerStyle}`}>0</p>
            <img src={arrowWhite} alt="arrow" />
          </div>
        </div>

        <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
          <div className="flex items-center gap-x-3">
            <img src={signalInverse} alt="signal-icon" />
            <p className={`${textStyle} text-gs-subSubText`}>Total Pending</p>
          </div>
          <div className={`${boxBottomItem}`}>
            <p className={`${textBiggerStyle}`}>0</p>
            <img src={arrowInverse} alt="arrow" />
          </div>
        </div>

        <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
          <div className="flex items-center gap-x-3">
            <img src={signalInverse} alt="signal-icon" />
            <p className={`${textStyle} text-gs-subSubText`}>Total Approved</p>
          </div>
          <div className={`${boxBottomItem}`}>
            <p className={`${textBiggerStyle}`}>0</p>
            <img src={arrowInverse} alt="arrow" />
          </div>
        </div>

        <div className={`${boxStyle} bg-white border border-gs-borderInput`}>
          <div className="flex items-center gap-x-3">
            <img src={signalInverse} alt="signal-icon" />
            <p className={`${textStyle} text-gs-subSubText`}>Total Completed</p>
          </div>
          <div className={`${boxBottomItem}`}>
            <p className={`${textBiggerStyle}`}>0</p>
            <img src={arrowInverse} alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectAnalysisSection;
