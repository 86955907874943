import { useState } from "react";
import AccountSetupFormStepTwo from "./AccountSetupFormStepTwo";
import AccountSetupFormStepThree from "./AccountSetupFormStepThree";
import AccountSetupFormStepFour from "./AccountSetupFormStepFour";

function AccountSetupSteps({ 
  handleNextStepColor, 
  handlePreviousStepColor, 
  handleSkipPreviousStepColor,
  handleSubmit,
  stepOneData, 
  stepTwoData,
  loading,
  values 
}: any) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
    handleNextStepColor();
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    handlePreviousStepColor();
  };

  const handleDblPreviousSkip = () => {
    setActiveStep((prevStep) => prevStep - 2);
    handleSkipPreviousStepColor();
  }

  const renderFormSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <AccountSetupFormStepTwo 
            setStepOneData={stepOneData} 
            onNextStep={handleNextStep} 
            />
        );
      case 1:
        return (
          <AccountSetupFormStepThree 
            setStepTwoData={stepTwoData} 
            onNextStep={handleNextStep} 
            onPreviousStep={handlePreviousStep}
            />
        );
      case 2:
        return (
          <AccountSetupFormStepFour 
            setStepTwoData={stepTwoData} 
            onNextStep={handleNextStep} 
            onPreviousStep={handlePreviousStep}
            onDblPreviousSkip={handleDblPreviousSkip}
            values={values}
            handleSubmit={handleSubmit}
            loading={loading}
            />
        );
      default:
        return null;
    }
  };
  return <>{renderFormSteps()}</>;
}
export default AccountSetupSteps;
