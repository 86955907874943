import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import BackArrow from "../../../assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "../components/ResetPasswordForm";

// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function ResetPassword() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div data-aos="fade-left"  className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <img
            src={BackArrow}
            alt="Back"
            className="cursor-pointer w-6 h-6"
            onClick={() => navigate(-1)}
          />
          <AuthFormHeader
            title="Reset password"
            subtitle="Enter your email address to get OTP for reset password"
          />
          <ResetPasswordForm />
        </div>
      </div>
    </AuthLayout>
  );
}

export default ResetPassword;
