import { Link } from "react-router-dom";
import SignalIcon from "../../../../../assets/icons/SignalIcon.svg";
import ShowInvestment from "../../../../../assets/icons/eye.svg";

function TotalInvestment() {
  // REUSABLE STYLING
  const investmentContainer = "max-w-[458px] flex gap-4 w-full";
  const investmentVerticalAlignment =
    "flex flex-col justify-between space-y-12";
  const investmentHeader = "font-medium text-gs-subSubText";
  const investmentNumbers =
    "text-[28px] font-extrabold leading-[30px] text-gs-text";

  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="flex items-center gap-[76px] h-[109px]">
        <div className={investmentContainer}>
          <div>
            <img src={SignalIcon} alt="Signal" />
          </div>
          <div className={investmentVerticalAlignment}>
            <p className={investmentHeader}>Total investment</p>
            <div className="flex items-center space-x-[141px]">
              <div className="flex items-center gap-2">
                <p className={investmentNumbers}>&#8358;0.00</p>
                <img src={ShowInvestment} alt="" />
              </div>
              <button className="px-6 py-[5px] text-sm font-semibold text-gs-primary bg-[rgba(11,124,66,0.10)] rounded-[14px]">
                <Link to="/app/investment/view-portfolio">View Portfolio</Link>
              </button>
            </div>
          </div>
        </div>
        <hr className="max-w-[1px] w-full h-[109px] border border-[#F4F4F5] rotate-180" />
        <div className={investmentContainer}>
          <div>
            <img src={SignalIcon} alt="Signal" />
          </div>
          <div className={investmentVerticalAlignment}>
            <p className={investmentHeader}>Total number of investment</p>
            <p className={investmentNumbers}>0</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalInvestment;
