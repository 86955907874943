import closeIcon from "../../assets/icons/closeIcon.svg";
import Success from "../../assets/icons/Success.svg";

interface ModalProps {
  closeModal: () => void;
  title?: string;
  subTitle?: string;
}

function SubmissionSuccess({ closeModal, title, subTitle }: ModalProps) {
  return (
    <div>
      <div className="flex justify-end">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => closeModal()}
          className="cursor-pointer"
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-[19px]">
        <img src={Success} alt="Success" />
        <p className="text-gs-text font-bold text-lg">{title}</p>
        <p className="text-gs-subSubText text-lg">{subTitle}</p>
      </div>
    </div>
  );
}

export default SubmissionSuccess;
