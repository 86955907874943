import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from '../../../assets/icons/gs-logo.svg';
import logoNotext from '../../../assets/icons/logo-notext.svg';
import angleRight from '../../../assets/icons/angle.svg';
import angleDown from '../../../assets/icons/angleDown.svg';
import { navlinks } from "../../../utils/constants";

function LeftPane(){
    const dropdownValue = localStorage.getItem('dropdown');
    const dx = dropdownValue && JSON.parse(dropdownValue)

    const location = useLocation();
    const [toggleDropdown, setToggleDropdown] = useState<boolean>(dx || false);
    const [imageSrc, setImageSrc] = useState({ 
        show: false,
        iconSrc: ''
    });

    // store the value of the dropdown nav in localStorage when change is made
    useEffect(() => {
        const dropdownValue = JSON.stringify(toggleDropdown)
        localStorage.setItem('dropdown', dropdownValue)
    },[toggleDropdown]);
    

    const handleHover = (src: string) => {
        setImageSrc({ show: true, iconSrc: src })
    }
    const handleHoverOut = (src: string) => {
        setImageSrc({ show: false, iconSrc: src })
    }
    const handleDropdownToggle = () => {
        setToggleDropdown(!toggleDropdown)
    }

   

    return (
        <aside>
            {/* logo  */}
            <img src={logo} alt="logo" className="mt-[30px] w-[150px] mx-auto md:block hidden" /> {/* displays from screenwidth 768px  */}
            <img src={logoNotext} alt="logo_" className="mt-[30px] md:hidden block mx-auto" />  {/* stops displaying at screenwidth 767px  */}
            {/* nav links  */}
            <div className="mt-[50px]">
            {navlinks.map((item: any, index) => { 
                const targetRoute = item.to;
                const isTargetRoute = location.pathname.includes(targetRoute);

                    if(!item.sub_links){   
                        // links without drop downs       
                        return (
                            <NavLink
                                to={`/app/${item.to}`}
                                key={index}
                                onMouseOver={() => handleHover(item.name)}
                                onMouseOut={() => handleHoverOut(item.name)}
                                className={ isTargetRoute ?
                                    "text-gs-primary  w-[80%] border border-gs-primary/[.4] rounded-[12px] bg-gs-primary/[.1] tracking-[0.03rem] flex items-center md:justify-normal justify-center  gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2.5 mb-3  hover:text-gs-primary hover:font-[400]" :  
                                    'text-black w-[80%] font-light tracking-[0.03rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7  px-2 md:py-3 py-2 md:mb-2.5 mb-3  hover:text-gs-primary hover:font-[400] hover:border hover:border-gs-primary/[.4] hover:rounded-[12px] hover:bg-gs-primary/[.1]'}
                            >
                                <img src={isTargetRoute || (imageSrc.show && imageSrc.iconSrc === item.name) ? 
                                    require(`../../../assets/icons/on.${item.icon}`) : 
                                    require(`../../../assets/icons/${item.icon}`)}
                                    alt={item.name} 
                                    />
                                <span className="lg:block hidden text-[14px]">{item.name}</span>
                            </NavLink>
                        )
                    }else {
                        // links with dropdown content 
                        return (
                            <>
                                {/* the parent link  */}
                                <div 
                                    key={index}
                                    onClick={handleDropdownToggle}
                                    onMouseOver={() => handleHover(item.name)}
                                    onMouseOut={() => handleHoverOut(item.name)}
                                    className={`text-black w-[80%] font-light tracking-[0.03rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7  px-2 md:py-3 py-2 md:mb-2.5 mb-2 hover:cursor-pointer`}
                                    >
                                        <img src={require(`../../../assets/icons/${item.icon}`)}
                                            alt={item.name} 
                                            />
                                        <span className="lg:flex justify-between hidden text-[14px] w-full ">
                                            {item.name} 
                                            {!toggleDropdown ? <img src={angleRight} alt="angle" className="w-[15px]" /> : <img src={angleDown} alt="angle" className="w-[15px]" />}</span>
                                </div>
                                {toggleDropdown &&
                                // sub links 
                                <div className="relative">
                                    {item.sub_links && item.sub_links?.map((sub_link: any) => { 
                                    const targetRoute = sub_link.to;
                                    const isTargetRoute = location.pathname.includes(targetRoute);
                                    return ( 
                                        <NavLink
                                            to={`/app/${sub_link.to}`}
                                            key={index}
                                            onMouseOver={() => handleHover(sub_link.name)}
                                            onMouseOut={() => handleHoverOut(sub_link.name)}
                                            className={ isTargetRoute ?
                                                "text-gs-primary  w-[80%] border border-gs-primary/[.4] rounded-[12px] bg-gs-primary/[.1] tracking-[0.03rem] flex items-center md:justify-normal justify-center  gap-x-3 md:ml-7 px-2 md:py-3 py-2 md:mb-2.5 mb-3  hover:text-gs-primary hover:font-[400]" :  
                                                'text-black w-[80%] font-light tracking-[0.03rem] flex items-center md:justify-normal justify-center gap-x-3 md:ml-7  px-2 md:py-3 py-2 md:mb-2.5 mb-3  hover:text-gs-primary hover:font-[400] hover:border hover:border-gs-primary/[.4] hover:rounded-[12px] hover:bg-gs-primary/[.1]'}
                                        >
                                            <img src={isTargetRoute || (imageSrc.show && imageSrc.iconSrc === sub_link.name) ? 
                                                require(`../../../assets/icons/on.${sub_link.icon}`) : 
                                                require(`../../../assets/icons/${sub_link.icon}`)}
                                                alt={sub_link.name} 
                                                />
                                            <span className="lg:block hidden text-[13.5px]">{sub_link.name}</span>
                                        </NavLink>
                                    )})}
                                </div>
                                }
                            </>
                        )
                    }
                })} 
            </div>
        </aside>

    )
}

export default LeftPane;