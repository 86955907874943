import { Field, Form, Formik } from "formik";
import { Button } from "../../../../../components/ui";
// import * as Yup from "yup";

interface Values {
  amount: string;
  roi: string;
  requirement: string;
}

// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-sm leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";

// const ProjectInformationSchema = Yup.object({
//   amount: Yup.string().required("Project ID is required"),
//   roi: Yup.string().required("Project name is required"),
//   requirement: Yup.string().required("Enter a requirement"),
// });

const initialValues: Values = {
  amount: "",
  roi: "",
  requirement: "",
};

interface Props {
  onNextStep: () => void;
  onPrevStep: () => void;
}

function ProjectFundingDetailsForm({ onNextStep, onPrevStep }: Props) {
  const handleNext = (values: Values) => {
    // alert(values);
    onNextStep();
  };

  const handlePrev = () => {
    onPrevStep();
  };

  return (
    <div className="max-w-[720px] m-auto w-full border border-gs-borderInput rounded-[14.5px] bg-white py-[50px] px-8">
      <Formik
        initialValues={initialValues}
        // validationSchema={ProjectInformationSchema}
        onSubmit={handleNext}
      >
        {({ errors, touched, values }) => (
          <Form className="py-8 flex flex-col gap-6">
            {/* REQUIREMENTS */}
            <div className={labelContainerStyling}>
              <label htmlFor="requirement" className={labelStyling}>
                Project requirements
              </label>
              {errors.requirement && touched.requirement ? (
                <p className={errorStyling}>{errors.requirement}</p>
              ) : null}
              <div className={inputContainerStyling}>
                <Field
                  as="textarea"
                  id="requirement"
                  name="requirement"
                  placeholder="Enter funding requirements"
                  className={`${fieldStyling} resize-none`}
                  maxLength={100}
                  rows={5}
                  cols={42}
                />
                <div className="mt-[10px] text-right text-sm text-[#CACACE]">
                  {values.requirement.length}/100
                </div>
              </div>
            </div>

            {/* FUNDING AMOUNT */}
            <div className={labelContainerStyling}>
              <label htmlFor="amount" className={labelStyling}>
                Funding Amount
              </label>
              {errors.amount && touched.amount ? (
                <p className={errorStyling}>{errors.amount}</p>
              ) : null}
              <div className={`${inputContainerStyling} flex gap-3`}>
                <p className="font-bold text-gs-subText">₦</p>
                <Field
                  id="amount"
                  name="amount"
                  placeholder="0.00"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>

            {/* ROI */}
            <div className={labelContainerStyling}>
              <label htmlFor="roi" className={labelStyling}>
                ROI
              </label>
              {errors.roi && touched.roi ? (
                <p className={errorStyling}>{errors.roi}</p>
              ) : null}
              <div className={inputContainerStyling}>
                <Field
                  id="roi"
                  name="roi"
                  placeholder="e.g 9.6%"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>

            <div className="flex justify-end gap-[15px]">
              <Button
                isInverted={true}
                text="Back"
                category="link"
                btnStyle="py-3 px-[51px] text-gs-text"
                handleClick={handlePrev}
              />
              <Button
                isInverted={false}
                type="submit"
                text="Next"
                category="button"
                btnStyle="py-3 px-[51px]"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProjectFundingDetailsForm;
