import React from "react";
import ProjectFundingDetailsForm from "./ProjectFundingDetailsForm";

interface Props {
  onNextStep: () => void;
  onPrevStep: () => void;
}

function ProjectFundingDetails({ onNextStep, onPrevStep }: Props) {
  return (
    <div>
      <h3 className="text-center text-gs-text text-2xl font-bold mb-8">
        Funding details
      </h3>
      <ProjectFundingDetailsForm
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
      />
    </div>
  );
}

export default ProjectFundingDetails;
