import NotificationService from "../../services/notification.service";

const handleCopy = (text: any, close?: any) => {
    if(!text.current) return;

    navigator.clipboard.writeText(text.current.innerHTML)
    .then(() => {
        NotificationService.success({ message: 'Copied to clipboard' });
    })
    .catch((err) => {
        NotificationService.warn({  message: err.message });
    });

    if(close){
        close();
    }
}


export default handleCopy;