import { useNavigate } from "react-router-dom";
import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import VerificationForm from "../components/VerificationForm";
import ls from 'localstorage-slim';
import AuthService from "../../../services/auth.service";
import { Values } from "../components/SignUpForm";
import NotificationService from "../../../services/notification.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux/reducers/authReducer";
import { useState } from "react";

// COMPONENT
ls.config.encrypt = true;

function OtpVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const authService = new AuthService();

  const payload: any = ls.get('gs-reg');
  const _reg_otp = ls.get('gs-reg-ot');


  const handleVerification = async(_arg: string | number) => {
    if( _reg_otp?.toString() === _arg){
      setLoading(true);
      try{
        // register decrypted user data 
        const response = await authService.register(payload);
        setLoading(false);
        if(!response?.status){
          NotificationService.error({
            message: 'Registration Failed!',
            addedText: response?.message
          })
        }
        // clear inital localStorage content 
        setTimeout(() => {
          localStorage.clear();
        }, 1000);
        // set local storage with token 
        setTimeout(() => {
          ls.set('gs-token', response?.data?.token);
        }, 3000);
  
        NotificationService.success({
          message: 'Registration successful!'
        })
        dispatch(setUserInfo(response?.data));
        navigate("/auth/account-setup");

      }catch(err: any){
        setLoading(false);
        NotificationService.error({
          message: err?.message
        })
      }
    }
  };

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader
            title=" Verify your email"
            subtitle="Enter the verification code sent to your email address"
          />
          <VerificationForm
            text={loading ? 'Verifying OTP...' : 'Continue'}
            handleVerification={(data) => handleVerification(data)}
          />
        </div>
      </div>
    </AuthLayout>
  );
}

export default OtpVerification;
