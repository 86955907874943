import { useNavigate } from "react-router-dom";
import { getDateTimeExtended, getDateTimeFormatExt } from "../../../../../components/custom-hooks";
import backIcon from "../../../../../assets/icons/arrow-left-grey.svg";
import { useState } from "react";
import { CustomModal } from "../../../../../components/ui";
import RemoveDeviceModal from "./RemoveDeviceModal";

const login_activity = [
    { id: 0, device_name: 'Galaxy S10', last_login: new Date()},
    { id: 1, device_name: 'Galaxy S10', last_login: new Date()},
    { id: 2, device_name: 'Google chrome • Mac OS 10.15.7', last_login: new Date()},
]
interface LoginActivityType {
    id: string | number;
    device_name: string;
    last_login: string | Date;
}

interface ActivityPageProps {
    setPage: (_arg: number) => void;
}

function LoginActivityPage({ setPage }: ActivityPageProps) {
    const [selectedDevice, setSelectedDevice] = useState<null | LoginActivityType>(null);
    const [toggle, setToggle] = useState(false);


    const handleRemove = (_arg: LoginActivityType) => {
        setToggle(true);
        setSelectedDevice(_arg);
    }

    return (
        <>
            <div className="h-fit mx-h-[78vh] overflow-y-auto">
                <div onClick={() => setPage(0)} className="flex items-center gap-x-1 hover:cursor-pointer w-fit">
                    <img src={backIcon} alt="back" />
                    <p className="font-[500] text-[15px] leading-[16px] text-gs-subText">Back</p>
                </div>
                <h2 className="text-[18px] font-[500] leading-[22.68px] text-[#1B212D] my-[3rem]">Login Activity</h2>
                <ul>
                    {login_activity.map((activity) => (
                        <li key={activity.id} className="grid grid-cols-8 gap-x-[8rem] items-center mb-[3rem]">
                            <div className="lg:col-span-3 grid gap-y-1">
                                <p className="text-[18px] font-[500] leading-[22.68px] text-[#1B212D]">{activity.device_name}</p>
                                <p className="text-[14px] font-[400] leading-[20px] text-[#929EAE]">Last login: {getDateTimeFormatExt(activity.last_login)}</p>
                            </div>
                            <div className="col-span-5">
                                <button onClick={() => handleRemove(activity)} className="bg-none border-0 text-[#FF2116] text-[16px] font-[500] leading-[25.5px]">Remove</button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            
            { toggle &&
                <CustomModal
                    modalStyle="lg:w-[30%] w-[80%] mx-auto mt-[10%] bg-white rounded-[16px] p-[25px]"
                    closeModal={() => setToggle(false)}
                >
                    <RemoveDeviceModal 
                        data={selectedDevice}
                        closeModal={() => setToggle(false)}
                    />
                </CustomModal>
            }
        </>
    )
}

export default LoginActivityPage;