import useOnClickOutside from "./useOnClickOutside";
import getDateString from "./getDateString";
import getTimeFormat from "./getTimeFormat";
import getDateTimeExtended from "./getDateTimeExtended";
import getDate12HoursFormat from "./getDate12hoursFormat";
import getDateTimeFormatExt from "./getDateTimeFormatExt";
import generateUniqueNumber from "./genrateUniqueNumber";
import handleCopy from "./copyTextToClipboard";
import truncateText from "./truncateText";

export {
    useOnClickOutside,
    getDateString,
    getTimeFormat,
    getDateTimeExtended,
    getDate12HoursFormat,
    getDateTimeFormatExt,
    generateUniqueNumber,
    handleCopy,
    truncateText
}