const getDate12HoursFormat = (date: Date) => {
  const options: any = { hour: 'numeric', minute: 'numeric', hour12: true };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default getDate12HoursFormat;
  
  // Example usage
  // const currentDate = new Date();
  // const formattedTime = formatDateTo12HourClock(currentDate);
  // console.log(formattedTime); 