import React, { useState } from 'react';
import UserService from '../../../services/user.service';
import AuthService from '../../../services/auth.service';


// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const inputContainerStyling =
"w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
"w-full outline-none text-sm leading-[14px] text-gs-subText";
const errorStyling = "text-[12px] leading-[14px] text-red-700";

export default function WalletTagField({ field, form }: any) {
    const authService = new AuthService();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState<any>(null);

    const handleInputChange = (_arg: any) => {
        if(_arg === '') return;

        const payload = { username: _arg }
        
        setIsLoading(true)
        try{
            const response: any = authService.verifyTag(payload);
            setIsLoading(false);
            if(!response.status){
                setError(response?.message);
            }else{
                setError(null);
            }
        }catch(err: any){
            return;
        }
    }

    return (
        <div className={labelContainerStyling}>
            {/* Wallet Tag */}
            {/* <div className="flex gap-x-3"> */}
                <label htmlFor="walletTag" className={labelStyling}>
                    WalletTag
                </label>
                <p className={errorStyling}>{form.errors.walletTag}</p>
                {error && <p>{error}</p>}
            {/* </div> */}
            <div className={`${inputContainerStyling} flex`}>
                <input
                    id="walletTag"
                    name={field.name}
                    type="text"
                    placeholder="JohnDoe"
                    className={fieldStyling}
                    value={form.values[field.name]}
                    onKeyUp={(e: any) =>
                        handleInputChange(e.target.value)
                    }
                    onChange={({ target }) => form.setFieldValue(field.name, target.value)}
                />
                {isLoading &&  <div className="w-6 h-6 cursor-pointer border-2 border-l-0 border-gray-400 animate-spin"></div>}
            </div>
        </div>
    )
}
