import { NavLink } from "react-router-dom";
import { TransactionTable } from "../../../components";

interface Props {
  data: [];
  title?: string;
  emptyIcon?: string;
  emptyText: string;
  emptyTextMain?: string;
}

function Transactions({
  data,
  title,
  emptyText,
  emptyIcon,
  emptyTextMain,
}: Props) {
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="flex justify-between mb-[2.5rem]">
        <h2 className="text-[18px] font-semibold text-gs-text leading-[27px]">
          {title}
        </h2>
        {data?.length > 0 &&
        <NavLink
          to="/app/wallet/transaction-history"
          className="text-gs-primary font-[400] text-[15px] tracking-wide"
        >
          View all
        </NavLink>}
      </div>
      <TransactionTable
        data={data}
        emptyIcon={emptyIcon}
        emptyText={emptyText}
        emptyTextMain={emptyTextMain}
      />
    </div>
  );
}

export default Transactions;
