import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import { Button } from "../../../../../components/ui";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border  rounded-[15px] p-[12px]";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
}

function FundCardModal({ closeModal }: TransferModalProps) {

    const handleFundCard = () => {}

    return (
        <form onSubmit={handleFundCard}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem] ">Fund card</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>


            <div className="mt-4">
                <label className={`${labelStyle}`}>Fund from</label>
                <div className={`${inputStyle} border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">Wallet</p>
                    <p className={`${balanceStyle}`}>&#8358;0.00</p>
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Amount to Add</label>
                <div className={`${inputStyle} border-gs-borderInput gap-x-3`}>
                    <div>&#8358;</div>
                    <input 
                        type="text" 
                        className="w-full outline-none text-gs-subText" 
                        placeholder="0.00" 
                        required
                        pattern="[0-9]*"
                    />
                </div>
            </div>

            <div className="flex flex-wrap lg:justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                    />
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}  
                    type="submit"    
                    text="Proceed" 
                    />

            </div>
        </form>
    )
}

export default FundCardModal;