import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui";
import UploadButton from "../../../assets/icons/UploadButton.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";




const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-sm leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";



function AccountSetupFormStepThree({ onNextStep, onPreviousStep, setStepTwoData }: any) {
  const [imageContent, setImageContent] = useState<any>(null);
  const [imageError, setImageError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const handleForm = async(e: any) => {
    e.preventDefault();

    if(!imageContent){
      setImageError('Image is required!');
      return;
    }

    const file = imageContent;
    const formData: any = new FormData();

    setLoading(true)
    try {
      formData.append("file", file);
      formData.append("upload_preset", "ize5x0kg");
      const res = await axios.post("https://api.cloudinary.com/v1_1/okafohenrie/image/upload", formData);
      setLoading(false);

      if(res?.statusText.toLowerCase() != 'ok') return;
        
       
      setStepTwoData(res?.data?.secure_url)
      onNextStep();

    }catch(err){
      setLoading(false);
      return;
    }
  } 

  return (
    <div className="py-8 flex flex-col items-center gap-6 max-w-[467px] w-full">
      <div>
        <p className="font-light leading-[33px]">
          This step will require that you upload government issued ID.
          <br />
          This may include
        </p>
        <ul className="py-3 px-[43px] list-disc text-gs-text text-sm leading-[27.5px] font-light">
          <li>National ID</li>
          <li>International Passport</li>
          <li>Driver’s Licence</li>
        </ul>
      </div>
      <div className="bg-gs-shadesOfBlue98 py-3 px-[43px] rounded-lg border border-dashed border-gs-mainColorPrimary w-full">
        <h3 className="text-gs-text text-sm font-bold leading-[18.2px]">
          Note
        </h3>
        <ul className="list-disc text-gs-text text-sm leading-[27.5px] font-light">
          <li>
            Photo of your ID on another screen not supported e.g (laptop e.t.c)
          </li>
          <li>Must be original copy not a copy of the original</li>
          <li>
            Show face clearly ( don't take ID far way from the camera lens)
          </li>
          <li>Not blurry ensure your camera is in focus </li>
        </ul>
      </div>


      <form 
      onSubmit={handleForm} 
      className="w-full"
      >
          <div className="w-full py-8 flex flex-col gap-6">

            {/* IMAGE UPLOAD */}
            <div className={`${labelContainerStyling} w-full`}>
              <label htmlFor="image" className={labelStyling}>
                Upload government issued ID (.Png,Pdf,Jpg,)
                {imageError ? (
                  <p className={errorStyling}>{imageError}</p>
                ) : null}
                <div className={`${inputContainerStyling} mt-[10px]`}>
                  <input
                    name="image"
                    id="image"
                    accept="image/*"
                    type="file"
                    className="hidden"
                    onChange={(e: any) => setImageContent(e.target.files[0])}
                  />
                  <div className="flex items-center justify-between">
                    <p className={fieldStyling}>{ imageContent ? imageContent?.name : "Upload ID" }</p>
                    <img
                      src={UploadButton}
                      alt="upload"
                      className="w-[78px] h-[33px]"
                    />
                  </div>
                </div>
              </label>
            </div>
            <div className="lg:flex grid gap-4">
              <Button 
                category="button"
                isInverted={true} 
                text="Previous"
                handleClick={onPreviousStep} 
                btnStyle="w-full py-2.5 px-6" 
                />
              <Button
                isInverted={false}
                type="submit"
                text={loading ? <ScaleLoader color='#fff' height={12} width={4} /> : "Continue"}
                category="button"
                btnStyle="w-full py-3 px-6"
                // handleClick={onNextStep}
              />
            </div>
          </div>
      </form>
    </div>
  );
}

export default AccountSetupFormStepThree;
