function getDateTimeFormatExt(_arg: string | Date) {
    const options: any = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
    
      const formattedDate = new Date(_arg).toLocaleDateString('en-US', options);
      return formattedDate;
}

export default getDateTimeFormatExt;