import {
  getDateString,
  getTimeFormat,
} from "../../../../../components/custom-hooks";
import ArrowRight from "../../../../../assets/icons/arrowright.svg";
import EmptyState from "../../../../../components/ui/EmptyState";

interface TransactionProps {
  productName: string;
  productId: string;
  milestone: string;
  date: string;
  type: string;
  amount: number;
  reference_no: string;
  status: string;
}

interface DataProps {
  data: TransactionProps[];
  emptyIcon: any;
  emptyText: string;
  emptyTextMain?: string;
  onClick?: boolean;
}

const tableHeaderStyle =
  "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";

function ProjectTransactionTable({
  data,
  emptyIcon,
  emptyText,
  emptyTextMain,
  onClick,
}: DataProps) {
  return (
    <div className="relative">
      <>
        {data?.length > 0 ? (
          <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5 ">
            {/* table header */}
            <li className="flex py-2.5">
              <div className={`${tableHeaderStyle} w-[20%]`}>PROJECT NAME</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>TYPE</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>DATE</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>STATUS</div>
              <div className={`${tableHeaderStyle} w-[15%]`}>MILESTONE</div>
              <div
                className={`${tableHeaderStyle} w-[5%] flex justify-center`}
              />
            </li>
            {data.map((item: TransactionProps, index: number) => {
              const statusStyle =
                item.status.toLowerCase() === "pending"
                  ? "text-gs-amber bg-gs-amberLess1"
                  : item.status.toLowerCase() === "success"
                  ? "text-gs-primary bg-gs-primaryLess1"
                  : item.status.toLowerCase() === "approved"
                  ? "bg-[rgba(236,248,240,0.80)] text-gs-primary600"
                  : "text-gs-red bg-gs-redLess1";
              return (
                <li
                  key={index}
                  className={`flex items-center py-2.5 border-t border-[#F5F5F5] ${
                    onClick && "hover:cursor-pointer"
                  }`}
                >
                  <div className={`${tableBodyStyle} w-[20%]`}>
                    <p className="text-gs-text font-semibold">
                      {item.productName}
                    </p>
                    <p className="text-gs-subText font-normal">
                      {item.productId}
                    </p>
                  </div>
                  <div className={`${tableBodyStyle} w-[20%] capitalize `}>
                    <p className="w-fit px-[30px] py-[6px] text-gs-subSubText bg-[#EDF1F3] rounded-[4px]">
                      {item.type}
                    </p>
                  </div>
                  <div className={`${tableBodyStyle} w-[20%]`}>
                    <p className="text-gs-text font-semibold">
                      {getDateString(item.date)}
                    </p>
                    <p className="text-gs-subText font-normal">
                      {getTimeFormat(item.date)}
                    </p>
                  </div>
                  <div
                    className={`${tableBodyStyle} w-[20%] capitalize font-semibold `}
                  >
                    <p
                      className={`${statusStyle} w-fit px-[30px] py-[6px] rounded-[4px]`}
                    >
                      {item.status}
                    </p>
                  </div>
                  <div
                    className={`${tableBodyStyle} w-[15%] capitalize font-semibold`}
                  >
                    {item.milestone}
                  </div>
                  <div
                    className={`${tableBodyStyle} w-[5%] flex justify-center capitalize font-semibold cursor-pointer`}
                  >
                    <img src={ArrowRight} alt="arrow" />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <EmptyState
            emptyIcon={emptyIcon}
            emptyText={emptyText}
            emptyTextMain={emptyTextMain}
          />
        )}
      </>
    </div>
  );
}

export default ProjectTransactionTable;
