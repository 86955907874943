import { useState } from "react";
import SecurityIndexPage from "./components/SecurityIndexPage";
import LoginActivityPage from "./components/LoginActivityPage";

function SecurityTab() {
    const [page, setPage] = useState(0);

    return (   
        <div className="pt-[2.2rem] pb-[4rem] px-[1.8rem] bg-white border border-gs-borderInput rounded-[8px] grid gap-y-[2.1rem]">
            { page === 0 &&
                <SecurityIndexPage 
                  setPage={(data) => setPage(data)}  
                />
            }
            { page === 1 &&
                <LoginActivityPage 
                    setPage={(data) => setPage(data)} 
                    />
            }
        </div>
    )
}


export default SecurityTab;