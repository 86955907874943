import { useEffect, useState } from "react";
import { CustomModal, CustomSwitch } from "../../../../../components/ui";
import UpdatePasswordModal from "./UpdatePasswordModal";
import CloseAccountModal from "./CloseAccountModal";
import GetStartedModal from "./GetStartedModal";
import SetupModal from "./SetupModal";
import ScanQRCodeModal from "./ScanQRCodeModal";
import CopyCodeModal from "./CopyCodeModal";
import FinalizeModal from "./FinalizeModal";


interface SecurityPageProps {
    setPage: (_arg: number) => void;
}

function SecurityIndexPage({ setPage }: SecurityPageProps) {
    const [passwordChangeToggle, setPasswordChangeToggle] = useState(false);
    const [closeAccountToggle, setCloseAccountToggle] = useState(false);
    const [ authEnabledChecked, setAuthEnabledChecked ] = useState(false);
    const [faToggle, setFaToggle] = useState(false);
    const [activeFaTab, setActiveFaTab] = useState(0);

    const h2Style = "text-[18px] font-[600]leading-[22.65px] text-gs-subText mb-10";
    const gridStyle = "grid grid-cols-6 gap-x-[7rem] items-center";
    const pStyle = "text-[18px] font-[600] leading-[22.68px] text-[#1B212D]";
    const labelStyle = "text-[14px] font-[400] leading-[20px] text-[#929EAE] tracking-wide";

    const closeFaToggle = () => {
        setActiveFaTab(0);
        setFaToggle(false);
        setAuthEnabledChecked(false);
    }

    const handleAuthenticationToggle = () => {
       setAuthEnabledChecked(!authEnabledChecked);
    }

    useEffect(() => {
        if(authEnabledChecked){
            setFaToggle(true);
        }
    }, [authEnabledChecked])

    return (
        <>
            <div className="grid gap-y-[4rem]">
            {/* account protection  */}
                <div>
                    <h2 className={`${h2Style}`}>Account Protection</h2>
                    <div className={`${gridStyle}`}>
                        <div className="lg:col-span-2">
                            <p className={`${pStyle}`}>Two-Factor Authentication</p>
                            <label className={`${labelStyle}`}>
                                Adds an extra layer of security to your account to protect you from unauthorized transactions
                            </label>
                        </div>

                        <div className="col-span-4">
                            <CustomSwitch 
                                handleChange={handleAuthenticationToggle} 
                                checked={authEnabledChecked}
                                />
                        </div>
                    </div>
                </div>

            {/* password  */}
                <div>
                    <h2 className={`${h2Style}`}>Password</h2>
                    <div className={`${gridStyle}`}>
                        <div className="lg:col-span-2">
                            <p className={`${pStyle}`}>Update Password</p>
                            <label className={`${labelStyle}`}>
                                Change your old password to a new one
                            </label>
                        </div>
                        <div className="col-span-4">
                            <p onClick={() => setPasswordChangeToggle(true)} className="text-gs-primary font-[500] hover:cursor-pointer text-[16px] leading-[25.5px] tracking-wide">Change Password</p>
                        </div>
                    </div>
                    <div className={`${gridStyle} mt-[4rem]`}>
                        <div className="lg:col-span-2">
                            <p className={`${pStyle}`}>Manage Device</p>
                            <label className={`${labelStyle}`}>
                                Track your device login history
                            </label>
                        </div>
                        <div className="col-span-4">
                            <p onClick={() => setPage(1)} className="text-gs-primary font-[500] hover:cursor-pointer text-[16px] leading-[25.5px] tracking-wide">Login Activity</p>
                        </div>
                    </div>
                </div>

            {/* termination  */}
                <div>
                    <h2 className={`${h2Style}`}>Termination</h2>
                    <div className="lg:col-span-2">
                        <p onClick={() => setCloseAccountToggle(true)} className="text-[#FF2116] font-[500] hover:cursor-pointer text-[18px] leading-[22.68px] tracking-wide">Close my account</p>
                    </div>
                </div>
            </div>


            { passwordChangeToggle &&
                <CustomModal
                    modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[7%] bg-white rounded-[16px] p-[30px]"
                    closeModal={() => setPasswordChangeToggle(false)}
                >
                    <UpdatePasswordModal 
                        // data={selectedDevice}
                        closeModal={() => setPasswordChangeToggle(false)}
                    />
                </CustomModal>
            }

            { closeAccountToggle &&
                <CustomModal
                    modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[7%] bg-white rounded-[16px] p-[30px]"
                    closeModal={() => setCloseAccountToggle(false)}
                >
                    <CloseAccountModal 
                        closeModal={() => setCloseAccountToggle(false)}
                    />
                </CustomModal>
            }

            { faToggle &&
                <CustomModal
                    modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[7%] bg-white rounded-[16px] p-[30px]"
                    closeModal={closeFaToggle}
                >
                   { activeFaTab === 0 &&
                    <GetStartedModal
                        closeModal={closeFaToggle}
                        setActiveFaTab={(_arg) => setActiveFaTab(_arg)}
                    />
                    }
                    { activeFaTab === 1 &&
                    <SetupModal 
                        closeModal={closeFaToggle}
                        setActiveFaTab={(_arg) => setActiveFaTab(_arg)}
                    />
                    }
                    { activeFaTab === 2 &&
                    <ScanQRCodeModal
                        closeModal={closeFaToggle}
                        setActiveFaTab={(_arg) => setActiveFaTab(_arg)}
                    />
                    }
                    { activeFaTab === 3 &&
                    <CopyCodeModal
                        closeModal={closeFaToggle}
                        setActiveFaTab={(_arg) => setActiveFaTab(_arg)}
                    />
                    }
                    { activeFaTab === 4 &&
                    <CloseAccountModal 
                        closeModal={closeFaToggle}
                    />
                    }
                    { activeFaTab === 5 &&
                    <FinalizeModal
                        closeModal={closeFaToggle}
                    />
                    }
                </CustomModal>
            }
        </>
    )
}

export default SecurityIndexPage;