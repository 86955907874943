/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import logo from "../../../assets/icons/gs-logo.svg";
import menu from "../../../assets/icons/menu.svg";
import { Button, CustomModal } from "../../../components/ui";

function Nav() {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  return (
    <>
      <nav className="w-full bg-white shadow py-2.5 flex justify-between items-center md:px-[5rem] px-[2.5rem] fixed z-[999] top-0">
        <div className="flex items-center md:gap-x-[4rem]">
          <div className="h-[4rem] w-[9rem] flex items-center">
            <img src={logo} alt="logo" />
          </div>
          <a
            href="#"
            className="md:block hidden hover:text-gs-primary tracking-wide"
          >
            Help Center
          </a>
        </div>
        <div className="flex items-center md:gap-x-3">
          <Button
            category="link"
            to="/auth/signin"
            btnStyle="text-gs-black border-gs-black px-8 py-3 text-center md:block hidden"
            isInverted={true}
            text="Login"
          />
          <Button
            category="link"
            to="/auth/signup"
            btnStyle="px-4 py-3 text-center border-gs-primary md:block hidden"
            isInverted={false}
            text="Get started-for free"
          />
          <div className="block md:hidden" onClick={() => setToggleModal(true)}>
            <img src={menu} alt="dropdown" />
          </div>
        </div>
      </nav>
      
      {toggleModal && (
        <CustomModal
          modalStyle="relative bg-white w-[80%] mx-auto rounded-[6px] mt-[20px]"
          closeModal={() => setToggleModal(false)}
        >
          <div className="flex justify-between items-center border-b-[1px] border-b-gray-100 px-5 py-2">
            <div className="h-[4rem] w-[9rem] flex items-center">
              <img src={logo} alt="logo" />
            </div>
            <div
              className={`absolute right-5  text-[32px] leading-[42px]`}
              onClick={() => setToggleModal(false)}
            >
              <button> &times; </button>
            </div>
          </div>

          <a
            href="#"
            className="flex justify-between text-xl font-[500] tracking-wide items-center border-b-2 border-dashed border-b-gray-100 px-5 py-6"
          >
            Help Center
          </a>

          <div className="flex flex-col gap-y-4 w-[85%] mx-auto  px-5 py-5">
            <Button
              category="link"
              to="/auth/signin"
              btnStyle="w-full text-gs-black border-gs-black px-8 py-5 text-center"
              isInverted={true}
              text="Login"
            />
            <Button
              category="link"
              to="/auth/signup"
              btnStyle="px-4 py-5 text-center border-gs-primary"
              isInverted={false}
              text="Get started-for free"
            />
          </div>
        </CustomModal>
      )}
    </>
  );
}

export default Nav;
