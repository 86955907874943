import { ChangeEvent } from "react";
import searchIcon from "../../assets/icons/search-normal.svg";

interface SearchInputProps {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void; 
    placeholder?: string;
}

function SearchInput({ onChange, placeholder }: SearchInputProps) {
    const boxStyle = "w-full p-3 bg-[#D9D9D9]/[.15] border border-[#D9D9D9]/[.15] flex items-center gap-x-4 rounded-[12px]";

    return (
        <div className={`${boxStyle}`}>
            <img src={searchIcon} alt="search icon" />
            <input 
                className="bg-transparent border-none outline-none w-full text-gs-subText font-[300]" 
                type="search" 
                placeholder={placeholder}
                onChange={onChange}
                />
        </div>
    )
}


export default SearchInput;