import playstore from "../../../assets/icons/playstore.svg";
import appstore from "../../../assets/icons/appstore.svg";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function SectionSix() {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section
            className="bg-gs-primary md:px-[4rem] px-[2rem] md:py-[8rem] py-[3rem] w-full grid justify-center"
        >
            <p data-aos="fade-up" className="md:text-[20px] text-[16px] text-center text-white font-semibold tracking-wide mb-3">Unlock Exclusive Access</p> 
            <h2 data-aos="fade-up" className="font-bold md:text-[50px] text-[30px] text-white md:leading-[80px] leading-[50px] text-center mb-3 tracking-normal">Download Our App</h2>
            <p data-aos="fade-up" className="md:text-[20px] text-[16px] font-[300] text-[#DCE4E8] leading-8 text-center">Enhance your experience with our app. Download for exclusive features. </p>
            <div className="md:flex grid justify-center items-center gap-x-5 gap-y-4 md:mt-[5rem] mt-[2rem]">
                <a data-aos="fade-up" href="/">
                    <img src={playstore} alt="playstore" />
                </a>
                <a data-aos="fade-up" href="/">
                    <img src={appstore} alt="appstore" />
                </a>
            </div>
        </section>
    )
}

export default SectionSix;

// Discover, Download, Enjoy