import { Link } from "react-router-dom";
import MLogo from "../../../../../assets/icons/Mlogo.svg";
import {
  Button,
  CustomModal,
  CustomSwitch,
} from "../../../../../components/ui";
import Info from "../../../../../assets/icons/info-circle.svg";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import SubmissionSuccess from "../../../../../components/ui/SubmissionSuccess";

interface Props {
  onNextStep: () => void;
  onPrevStep: () => void;
}

const labelStyle = "font-medium text-gs-dark";

function ProjectPreview({ onNextStep, onPrevStep }: Props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <h3 className="text-center text-gs-text text-2xl font-bold mb-8">
        Project preview
      </h3>
      <div className="max-w-[720px] m-auto w-full border border-gs-borderInput rounded-[14.5px] bg-white py-5 px-8">
        <div className="preview-bg bg-gs-primary p-7 rounded-[10px] flex justify-between items-center">
          <div className="flex gap-[9px] items-start">
            <img src={MLogo} alt="logo" />
            <div>
              <p className="text-white text-lg font-bold">Maglo Project</p>
              <p className="manrope text-white text-sm font-medium mt-[13px]">
                Project ID : #PROJ -1234
              </p>
            </div>
          </div>
          <div className="text-sm font-medium text-white kumbh space-y-[7px]">
            <p>State date : 30/07/2023</p>
            <p>End date : 30/08/2023</p>
            <p>Type : individual</p>
          </div>
        </div>
        <div className="py-8 space-y-[18px]">
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Project type</p>
            <p className="py-[10px] text-center text-gs-subSubText text-sm font-semibold w-32 rounded-[10px] bg-[rgba(239,243,250,0.50)]">
              Individual
            </p>
          </div>
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Project milestone</p>
            <p className="manrope font-bold text-gs-text text-[15px]">
              4 milestones
            </p>
          </div>
          <div className="border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Project description</p>
            <p className="manrope text-[15px] mt-[10px] text-gs-subSubText">
              Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio
              nunc ullamcorp er mauris commodo .Lorem ipsum dolor sit amet
              consectetur. Blandit vitae sociis odio nunc ullamcorp er mauris
              commodo.
            </p>
          </div>
          <div className="border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Funding requirement</p>
            <p className="manrope text-[15px] mt-[10px] text-gs-subSubText">
              Lorem ipsum dolor sit amet consectetur. Blandit vitae sociis odio
              nunc ullamcorp er mauris commodo .
            </p>
          </div>
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Funding amount</p>
            <p className="font-extrabold text-gs-text">₦5,000,000.00</p>
          </div>
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Returns</p>
            <p className="font-bold text-gs-text">%6.69</p>
          </div>
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={labelStyle}>Project document</p>
            <p className="font-bold text-gs-text">3 document upload</p>
          </div>
          <div className="flex justify-between items-center border-b border-[rgba(220,228,232,0.40)] pt-[10px] pb-[17px]">
            <p className={`${labelStyle} flex gap-4`}>
              Green Hub Project management
              <img src={Info} alt="info" />
            </p>
            <CustomSwitch />
          </div>
          <div className="border border-dashed border-gs-blue bg-gs-blueLess1 rounded-[8px] py-3 pl-[43px] pr-[80px]">
            <p className="text-xs font-bold mb-3 tracking-wide text-gs-text">
              Note
            </p>
            <ul className="text-xs text-gs-text font-extralight list-disc ml-5">
              <li>Once project is submitted changes can no longer be made.</li>
              <li>
                Once project is approved you can borrow funds to carryout your
                project.
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "6px",
                color: "#087139",
                "&.Mui-checked": {
                  color: "#087139",
                },
              }}
              name="termsAndConditions"
              id="termsAndCondition"
            />
            <label
              htmlFor="termsAndConditions"
              className="text-gs-text text-xs leading-[22px]"
            >
              I have read and accept the{" "}
              <Link to="/terms-&-conditions" className="text-gs-primary">
                Terms & Conditions
              </Link>{" "}
              of this project
            </label>
          </div>
        </div>
        {showModal && (
          <CustomModal
            modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
            closeModal={() => setShowModal(false)}
          >
            <SubmissionSuccess
              title="Project  Added Successfully"
              subTitle="You have successfully added a new project"
              closeModal={() => setShowModal(false)}
            />
          </CustomModal>
        )}
        <div className="flex justify-end gap-[15px]">
          <Button
            isInverted={true}
            text="Back"
            category="link"
            btnStyle="py-3 px-[51px] text-gs-text"
            handleClick={onPrevStep}
          />
          <Button
            isInverted={false}
            type="submit"
            text="Submit"
            category="button"
            btnStyle="py-3 px-[51px]"
            handleClick={() => setShowModal(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectPreview;
