import DocumentIcon from "../../../../../assets/icons/DocumentIcon.svg";
import DownloadDocument from "../../../../../assets/icons/document-download.svg";

function PortfolioLetter() {
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <img src={DocumentIcon} alt="Documents" />
          <div>
            <p className="text-gs-text font-semibold mb-[10px]">
              Get your portfolio Letter
            </p>
            <p className="text-gs-subSubText text-sm font-medium">
              Download your portfolio letter for official use.
            </p>
          </div>
        </div>
        <div className="flex gap-3 py-[10px] px-5 items-center border border-gs-greyscaleGrey200 rounded-[100px] cursor-pointer">
          <img src={DownloadDocument} alt="Download" />
          <p className="text-gs-subSubText font-medium text-sm">Download</p>
        </div>
      </div>
    </div>
  );
}

export default PortfolioLetter;
