import { useState } from "react";
import angleUp from "../../assets/icons/angleUp.svg";
import angleDown from "../../assets/icons/angleDown.svg";

interface FilterProps {
    options: { 
        name: string;
        value: string;
    }[]
}

interface Option {
    name: string;
    value: string;
}

function FilterInput(props: FilterProps) {
    const { options } = props;
    const [ selected, setSelected ] = useState<null | Option>(null);
    const [ toggle, setToggle ] = useState(false);

    const handleClick = (_arg: Option) => {
        setSelected(_arg);
        setToggle(false);
    }

    return (
        <div className="relative text-[14px] w-full">
            <p onClick={() => setToggle(!toggle)} className="w-full lg:w-[130px] relative border flex justify-between items-center hover:cursor-pointer border-gs-borderInput rounded-[10px] pl-3 pr-2 py-2 ">
                {selected?.name || 'Filter'}
                <img src={toggle ? angleUp : angleDown} alt="angle" className="w-[15px]" />
            </p>

            { toggle &&
                <ul className="lg:w-[150px] w-full absolute bg-white z-10 shadow rounded border border-gs-borderInput py-1">
                    { options?.map((option: any, index: number) => (
                        <li className="px-3 py-1 hover:cursor-pointer hover:bg-gray-50/[.9]" onClick={() => handleClick(option)} key={index}>
                            {option.name}
                        </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default FilterInput;