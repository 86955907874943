import React, { useState } from "react";
import MyInvestment from "./MyInvestment";
import RecommendedFunds from "./RecommendedFunds";
import AllFunds from "./AllFunds";

function InvestmentTabs() {
  const [currentTab, setCurrentTab] = useState(0);

  const Tabs = () => {
    return (
      <div className="flex gap-10 border-b border-[#f3f3f3]">
        <div
          className={`${
            currentTab === 0 ? "border-b border-gs-primary" : ""
          } max-w-[150px] pb-6 w-full cursor-pointer`}
          onClick={() => setCurrentTab(0)}
        >
          <p
            className={`${
              currentTab === 0 ? "!text-gs-text" : ""
            } font-medium text-center text-gs-subText`}
          >
            My Investment
          </p>
        </div>
        <div
          className={`${
            currentTab === 1 ? "border-b border-gs-primary" : ""
          } max-w-[168px] pb-6 w-full cursor-pointer`}
          onClick={() => setCurrentTab(1)}
        >
          <p
            className={`${
              currentTab === 1 ? "!text-gs-text" : ""
            } font-medium text-center text-gs-subText`}
          >
            Recommended funds
          </p>
        </div>
        <div
          className={`${
            currentTab === 2 ? "border-b border-gs-primary" : ""
          } max-w-[94px] pb-6 w-full cursor-pointer`}
          onClick={() => setCurrentTab(2)}
        >
          <p
            className={`${
              currentTab === 2 ? "!text-gs-text" : ""
            } font-medium text-center text-gs-subText`}
          >
            All funds
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
      <Tabs />
      <div className="mt-8">
        {currentTab === 0 && <MyInvestment />}
        {currentTab === 1 && <RecommendedFunds />}
        {currentTab === 2 && <AllFunds />}
      </div>
    </div>
  );
}

export default InvestmentTabs;
