import { Field, Form, Formik } from "formik";
import { Button } from "../../../../../components/ui";
// import * as Yup from "yup";

interface Values {
  projectId: string;
  projectName: string;
  projectType: string;
  milestone: string;
  startDate: string;
  endDate: string;
  description: string;
}

// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-sm leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";
const rowContainerStyling =
  "flex flex-col md:flex-row max-w-[656px] w-full gap-6 item-center";

// const ProjectInformationSchema = Yup.object({
//   projectId: Yup.string().required("Project ID is required"),
//   projectName: Yup.string().required("Project name is required"),
//   projectType: Yup.string().required("Project Type is required"),
//   mileStone: Yup.string().required("Please select an option"),
//   startDate: Yup.string().required("Enter a start date"),
//   endDate: Yup.string().required("Enter a end date"),
//   description: Yup.string().required("Enter a description"),
// });

const initialValues: Values = {
  projectId: "",
  projectName: "",
  projectType: "",
  milestone: "",
  startDate: "",
  endDate: "",
  description: "",
};

interface Props {
  onNextStep: () => void;
}

function ProjectInformationForm({ onNextStep }: Props) {
  const handleNext = (values: Values) => {
    // alert(values);
    onNextStep();
  };

  return (
    <div className="max-w-[720px] m-auto w-full border border-gs-borderInput rounded-[14.5px] bg-white py-[50px] px-8">
      <Formik
        initialValues={initialValues}
        // validationSchema={ProjectInformationSchema}
        onSubmit={handleNext}
      >
        {({ errors, touched, values }) => (
          <Form className="py-8 flex flex-col gap-6">
            {/* ROW 1 */}
            <div className={rowContainerStyling}>
              {/* PROJECT ID */}
              <div
                className={`${labelContainerStyling} md:max-w-[324px] w-full`}
              >
                <label htmlFor="projectId" className={labelStyling}>
                  Project ID
                </label>
                {errors.projectId && touched.projectId ? (
                  <p className={errorStyling}>{errors.projectId}</p>
                ) : null}
                <div className={inputContainerStyling}>
                  <Field
                    id="projectId"
                    name="projectId"
                    placeholder="Enter project id"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>

              {/* PROJECT NAME */}
              <div
                className={`${labelContainerStyling} md:max-w-[324px] w-full`}
              >
                <label htmlFor="projectName" className={labelStyling}>
                  Project Name
                </label>
                {errors.projectName && touched.projectName ? (
                  <p className={errorStyling}>{errors.projectName}</p>
                ) : null}
                <div className={inputContainerStyling}>
                  <Field
                    id="projectName"
                    name="projectName"
                    placeholder="Enter project name"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>
            </div>

            {/* ROW 2 */}
            <div className={rowContainerStyling}>
              {/* PROJECT TYPE */}
              <div
                className={`${labelContainerStyling} md:max-w-[324px] w-full`}
              >
                <label htmlFor="projectType" className={labelStyling}>
                  Project Type
                </label>
                {errors.projectType && touched.projectType ? (
                  <p className={errorStyling}>{errors.projectType}</p>
                ) : null}
                <div className={inputContainerStyling}>
                  <Field
                    id="projectType"
                    name="projectType"
                    placeholder="Enter project type"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>

              {/* MILESTONE */}
              <div className={rowContainerStyling}>
                {/* Milestone */}
                <div
                  className={`${labelContainerStyling} md:max-w-[324px] w-full`}
                >
                  <label htmlFor="milestone" className={labelStyling}>
                    Milestone
                  </label>
                  {errors.milestone && touched.milestone ? (
                    <p className={errorStyling}>{errors.milestone}</p>
                  ) : null}
                  <div className={inputContainerStyling}>
                    <Field
                      as="select"
                      id="milestone"
                      name="milestone"
                      className={fieldStyling}
                    >
                      <option value="" disabled selected hidden>
                        Select milestone
                      </option>
                      <option value="stage 0/4">Stage 0/4</option>
                      <option value="Stage 1/4">Stage 1/4</option>
                      <option value="Stage 2/4">Stage 2/4</option>
                      <option value="Stage 3/4">Stage 3/4</option>
                      <option value="Stage 4/4">Stage 4/4</option>
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            {/* ROW 3 */}
            <div className={rowContainerStyling}>
              {/* START DATE */}
              <div
                className={`${labelContainerStyling} md:max-w-[324px] w-full`}
              >
                <label htmlFor="startDate" className={labelStyling}>
                  Start Date
                </label>
                {errors.startDate && touched.startDate ? (
                  <p className={errorStyling}>{errors.startDate}</p>
                ) : null}
                <div className={inputContainerStyling}>
                  <Field
                    id="startDate"
                    name="startDate"
                    placeholder="Insert start date"
                    type="date"
                    className={`${fieldStyling} uppercase`}
                  />
                </div>
              </div>

              {/* MILESTONE */}
              <div className={rowContainerStyling}>
                {/* END DATE */}
                <div
                  className={`${labelContainerStyling} md:max-w-[324px] w-full`}
                >
                  <label htmlFor="endDate" className={labelStyling}>
                    End Date
                  </label>
                  {errors.endDate && touched.endDate ? (
                    <p className={errorStyling}>{errors.endDate}</p>
                  ) : null}
                  <div className={inputContainerStyling}>
                    <Field
                      id="endDate"
                      name="endDate"
                      placeholder="Insert end date"
                      type="date"
                      className={`${fieldStyling} uppercase`}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* DESCRIPTION */}
            <div className={labelContainerStyling}>
              <label htmlFor="description" className={labelStyling}>
                Project description
              </label>
              {errors.description && touched.description ? (
                <p className={errorStyling}>{errors.description}</p>
              ) : null}
              <div className={inputContainerStyling}>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Enter project description"
                  className={`${fieldStyling} resize-none`}
                  maxLength={500}
                  rows={10}
                  cols={62}
                />
                <div className="mt-[10px] text-right text-sm text-[#CACACE]">
                  {values.description.length}/500
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                isInverted={false}
                type="submit"
                text="Next"
                category="button"
                btnStyle="py-3 px-[51px]"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProjectInformationForm;
