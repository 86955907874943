import { ChangeEvent, useState } from "react";
import { SearchInput } from "../../../../../components/ui";
import ChatItem, { ChatProps } from "./ChatItem";

function ChatList({ chats, handleSearch, isSelected, setIsSelected  }: any) {
    return (
        <div className="px-[2rem] lg:border-r lg:border-[#EAEDF2] h-[70vh]">
            <SearchInput 
                placeholder="Search for messages"
                onChange={(e:ChangeEvent<HTMLInputElement> ) => handleSearch(e)} 
                />

            <div className="h-[85%] overflow-y-auto mt-7">
                {chats?.map((chat: ChatProps) => (
                    <ChatItem 
                        isSelected={isSelected}
                        setIsSelected={(data: string) => setIsSelected(data)}
                        chat={chat}
                    />
                ))}
            </div>
        </div>
    )
}

export default ChatList;