import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import backIcon from "../../../../../assets/icons/arrow-left.svg";
import {Button} from "../../../../../components/ui";
import {useEffect, useState} from "react";
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import ls from 'localstorage-slim';
import {ScaleLoader} from "react-spinners";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border rounded-[15px]";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
    setTransferTab: (data: number) => void;
    setTransferType: (data: string) => void;
}

interface TransferDataProps {
    amount: number,
    walletTag: string,
    serviceType: string,
    narration?: string,
    pin: string
}

const initialValues = {
    amount: 0,
    walletTag: "",
    serviceType: "payment",
    narration: "",
    pin: ""
}

// LOCAL
ls.config.encrypt = true;

function TransferToUser({closeModal, setTransferTab, setTransferType}: TransferModalProps) {
    const userService = new UserService();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);

    const [formData, setFormData] = useState(initialValues);
    const [isAmountValid, setIsAmountValid] = useState(false);
    const [transactionCharge, setTransactionCharge] = useState(0.00);
    const [loading, setLoading] = useState(false);
    const [tagError, setTagError] = useState<string | null>(null);
    const [isTagVerified, setIsTagVerified] = useState(false);
    const [tagUser, setTagUser] = useState("");
    const [verifyingTag, setVerifyingTag] = useState(false); // Loader state for verifying tag

    const handleTagChange = async (e: any) => {
        setTagError(null);
        setIsTagVerified(false);
        setVerifyingTag(true); // Start verifying

        const tag = e.target.value;
        if (tag === '') return;

        try {
            const response = await userService.verifyTag(tag);
            if (!response.status) {
                setTagError(response.message);
            } else {
                setTagUser(response.data.fullName);
                setIsTagVerified(true);
                setTagError(null);
            }
            setFormData({...formData, walletTag: tag});
        } catch (err) {
            setTagError("Failed to verify tag.");
        } finally {
            setVerifyingTag(false); // End verifying
        }
    };

    const handleAmountChange = async (event: any) => {
        const value = event.target.value;
        const pattern = /^\d+(\.\d+)?$/;

        if (pattern.test(value) || value === '') {
            setIsAmountValid(true);
            setFormData({...formData, amount: value});
            try {
                const response = await userService.transactionCharge({amount: value});
                if (!response) return;
                setTransactionCharge(response.data.amount);
            } catch (err) {
                console.log(err);
            }
        } else {
            setIsAmountValid(false);
        }
    };

    const handlePay = async (e: any) => {
        e.preventDefault();

        if (Math.round(formData.amount) === 0) {
            NotificationService.warn({message: 'Amount must be significant'});
            return;
        }

        setLoading(true);
        try {
            await userService.sendPinAsOTP().then((res) => {
                setLoading(false);
                if (!res.status) {
                    NotificationService.error({message: 'Failed!', addedText: res.message});
                    return;
                }

                ls.set('_tix', formData);
                NotificationService.success({message: res.message});
                setTransferTab(4);
                setTransferType('user');
            }).catch((err) => {
                setLoading(false);
                NotificationService.error({
                    message: 'Transfer failed!',
                    addedText: err?.message
                });
            });
        } catch (err: any) {
            setLoading(false);
            NotificationService.error({
                message: 'Transfer failed!',
                addedText: err?.message
            });
        }
    };

    return (
        <form onSubmit={handlePay}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <div className="text-[18px] font-bold leading-[1rem] flex items-center gap-x-3">
                    <img src={backIcon} alt="back" onClick={() => setTransferTab(0)} className="hover:cursor-pointer"/>
                    back
                </div>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()}
                    className="hover:cursor-pointer"/>
            </div>

            <h2 className="text-[18px] font-[700] tracking-wide">Send to GreenSwift User</h2>

            <div className="mt-7">
                <label className={`${labelStyle}`}>Wallet Balance</label>
                <div className={`${inputStyle} p-[12px] border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">Wallet Balance</p>
                    <p className={`${balanceStyle}`}>&#8358;{userInfo.walletBalance}</p>
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Recipient GreenSwift Tag</label>
                <input
                    type="text"
                    placeholder="Enter username"
                    required
                    onChange={handleTagChange}
                    className={`${inputStyle} px-[12px] py-[15px] border-gs-borderInput outline-none text-[14px] font-[300] text-gs-subText`}
                />
                {verifyingTag && <ScaleLoader color='#098543' height={12} width={4}/>} {/* Loader */}
                {tagError && <p className="text-red-600 text-sm">{tagError}</p>} {/* Error */}
                {isTagVerified && !tagError && <p className="text-green-600 text-sm">{tagUser}</p>} {/* Success */}
            </div>

            <div className="mt-4">
                <div className="flex justify-between items-end mb-1.5">
                    <label className={`${labelStyle}`}>Amount to Send</label>
                    <label
                        className="px-3 py-[0.2rem] bg-[#E4B46C]/[.3] text-[12px] tracking-wide rounded-[16px]">{userInfo.localCurrencySymbol}{transactionCharge}</label>
                </div>
                <div
                    className={`${inputStyle} p-[12px] ${isAmountValid ? 'border-gs-borderInput' : 'border-red-600'} gap-x-3`}>
                    <div className="text-gs-subText">{userInfo.localCurrencySymbol}</div>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        required
                        onChange={handleAmountChange}
                        placeholder="0.00"/>
                </div>
            </div>

            <div className="mt-4">
                <label className={`${labelStyle}`}>Description</label>
                <textarea
                    rows={1}
                    placeholder="Enter description here"
                    required
                    onChange={(e) => setFormData({...formData, narration: e.target.value})}
                    className={`${inputStyle} resize-none w-full px-[12px] py-[15px] border-gs-borderInput outline-none text-[14px] font-[300] `}
                ></textarea>
            </div>

            <div className="flex flex-wrap lg:justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] order-2 lg:order-1 py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted={true}
                    handleClick={() => closeModal()}
                    text="Cancel"
                />
                <Button
                    btnStyle="px-[50px] order-1 lg:order-2 py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    isInverted={false}
                    type="submit"
                    disabled={!isTagVerified || loading}
                    text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Transfer"}
                />
            </div>
        </form>
    );
}

export default TransferToUser;
