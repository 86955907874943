import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import successImg from "../../../../../assets/icons/success-img.svg";

interface RemoveModalProps {
    closeModal: () => void;
}

function FinalizeModal({ closeModal }: RemoveModalProps) {
    const btnStyle =  "px-[35px] py-[12px] font-[500] text-[16px] leading-[25.5px] rounded-[10px]";


    return (
        <div>
            <div className="w-fit float-right mb-[1.2rem]">
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <img src={successImg} alt="remove" className="mx-auto clear-both" />

            <h3 className="text-[18px] text-center font-[700] leading-[32px] text-gs-text my-2">You ‘re good to go!</h3>

            <p className="text-center text-[16px] font-[400] tracking-wide leading-[27.5px] text-gs-subSubText mt-2.5">
            When prompted for a 2FA code, copy generated in your authenticator app to approve transactions
            </p>

            <div className="lg:w-[75%] w-full mx-auto  mt-7">
                <button onClick={() => closeModal()} className={`${btnStyle} bg-gs-primary text-white w-full`}>Okay</button>
            </div>
        </div>
    )
}


export default FinalizeModal;