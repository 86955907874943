import { useEffect } from "react";
import jumbotronImg from "../../../assets/images/jumbotronImg.png";
import scan from "../../../assets/icons/jumbotron-scan.svg";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';

function Jumbotron(){
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section>
            <div className="grid md:grid-cols-7 grid-cols-1 h-[89vh] mt-[5.35rem] w-full">
                <div className="md:col-span-4 col-span-1 bg-gs-primaryLess1 md:pl-[4rem] pl-[2rem] md:pr-[9rem] pr-[2rem] md:pb-0 pb-[3rem]">
                    <div data-aos="fade-up" className="md:w-[60%] w-[80%] bg-white rounded-[80px] pl-1.5 pr-5 py-1.5 flex gap-x-3 items-center mt-[5rem] mb-5">
                        <div className="rounded-[80px] bg-gs-primary px-5 py-1.5 text-white md:text-[15px] text-[12px]">News!</div>
                        <p className="md:text-[16px] text-[12px]">Blandit vitae sociis odio nunc  ✨</p>
                    </div>
                    <h1 data-aos="fade-up" className=" md:text-[72px] text-[3rem] md:leading-[90px] leading-[60px] font-[700] mb-6">Financial <span className="text-gs-primary">innovation</span> meets <span className="text-gs-primary">trusted</span> banking.</h1>
                    <p data-aos="fade-up" className="text-[18px] font-lighter leading-[30px] mb-[2rem]">From seamless digital transactions to personalized financial advice, we're here to redefine your banking experience.</p>
                    <div data-aos="fade-up">
                        <img src={scan} alt="scan" />
                    </div>
                </div>

                <div className="md:col-span-3 col-span-1 bg-gs-primary">
                    <img data-aos="fade-up" src={jumbotronImg} alt="jbtrnImg" className="h-full w-full aspect-[1/1]" />
                </div>
            </div>
        </section>
    )
}

export default Jumbotron;