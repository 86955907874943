import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui";
import editIcon from "../../../assets/icons/editIcon.svg";
import UploadButton from "../../../assets/icons/UploadButton.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";




// const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const pStyling = "font-light leading-[20px] text-sm leading-[14px] text-gs-subText capitalize";
const headerStyling = "font-[25px] font-bold"


function AccountSetupFormStepFour({ handleSubmit, onPreviousStep, onDblPreviousSkip, values, loading }: any) {

  return (
    <div className="py-8 flex flex-col items-center gap-4 max-w-[467px] w-full">
        {/* identity Verification display section  */}
        <div className="w-full">
            <div className="flex flex-wrap gap-5 items-center mb-5">
                <h2 className={`${headerStyling}`}>Identity Verification Information</h2>
                <div className="hover:cursor-pointer hover:bg-gray-50 p-2 rounded-full" onClick={onDblPreviousSkip}>
                    <img src={editIcon} alt="edit"  className="h-[15px] w-[15px]" />
                </div>
            </div>
            <div className="w-full flex gap-10">
                <div>
                    <label className={`${labelStyling}`}>Country</label>
                    <p className={`${pStyling}`}>{values?.country}</p>
                </div>
                <div>
                    <label className={`${labelStyling}`}>State</label>
                    <p className={`${pStyling}`}>{values?.state}</p>
                </div>
            </div>

            <div className="w-full flex gap-10 mt-5">
                <div>
                    <label className={`${labelStyling}`}>City</label>
                    <p className={`${pStyling}`}>{values?.city}</p>
                </div>
                <div>
                    <label className={`${labelStyling}`}>Zip</label>
                    <p className={`${pStyling}`}>{values?.zipCode}</p>
                </div>
            </div>

            <div className="w-full flex gap-10 mt-5">
                <div>
                    <label className={`${labelStyling}`}>Gender</label>
                    <p className={`${pStyling}`}>{values?.gender}</p>
                </div>
                <div>
                    <label className={`${labelStyling}`}>Date of birth</label>
                    <p className={`${pStyling}`}>{values?.dateOfBirth}</p>
                </div>
            </div>

            <div className="w-full grid lg:flex lg:flex-wrap mt-5 gap-10">
                <div>
                    <label className={`${labelStyling}`}>Address</label>
                    <p className={`${pStyling}`}>{values?.address}</p>
                </div>
                <div>
                    <label className={`${labelStyling}`}>Occupation</label>
                    <p className={`${pStyling}`}>{values?.occupation}</p>
                </div>
            </div>
            <div className="w-full grid gap-10 mt-5">
                <label className={`${labelStyling}`}>Utility Bill</label>
                <img 
                    src={values?.utilityBillUrl} 
                    alt="utility bill" 
                    className="h-[130px] w-[150px] -mt-6" 
                />
            </div>
        </div>




{/* government issued ID display section */}
        <div className="w-full">
            <div className="flex flex-wrap gap-6 mt-10 items-center">
                <h2 className={`${headerStyling}`}>Identity Verification Information</h2>
                <div className="hover:cursor-pointer hover:bg-gray-50 p-2 rounded-full" onClick={onPreviousStep}>
                    <img src={editIcon} alt="edit" className="h-[15px] w-[15px]" />
                </div>
            </div>
            <div className="w-full grid gap-10 mt-5">
                <label className={`${labelStyling}`}>Government-issued ID</label>
                <img src={values?.idImgUrl} alt="gov't-issued ID" className="h-[150px] w-[150px] -mt-6" />
            </div>
        </div>

        <div className="w-full lg:flex grid gap-4 mt-7">
            <Button 
            category="button"
            isInverted={true} 
            text="Previous"
            handleClick={onPreviousStep} 
            btnStyle="w-full py-2.5 px-6" 
            />
            <Button
            isInverted={false}
            // type="submit"
            text={loading ? "Submitting..." : "Submit"}
            category="button"
            btnStyle="w-full py-3 px-6"
            handleClick={handleSubmit}
            />
        </div>
    </div>
  );
}

export default AccountSetupFormStepFour;
