import { ReactNode } from "react";
import LeftCol from "./LeftCol";

interface AuthLayoutProps {
  children: ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div className="w-full h-[100vh] grid lg:grid-cols-5 grid-cols-1">
      <div className="lg:col-span-2 lg:block hidden">
        <LeftCol />
      </div>
      <div className="lg:col-span-3 col-span-1 bg-gs-grayLess2 h-full overflow-y-auto py-[71.5px]">
        {children}
      </div>
    </div>
  );
}

export default AuthLayout;
