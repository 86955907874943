interface DataProps {
  emptyIcon?: any;
  emptyText: string;
  emptyTextMain?: string;
  className?: string;
}

function EmptyState({
  emptyIcon,
  emptyText,
  emptyTextMain,
  className,
}: DataProps) {
  const noTsxTextMainStyle =
    "w-full mx-auto text-center text-gs-text mt-5 -mb-4 text-[16px] font-bold";

  const noTsxTextStyle =
    " w-full mx-auto text-center text-gs-subText mt-5 text-[16px]";

  return (
    <div className="flex flex-col justify-center my-[5rem]">
      {emptyIcon && (
        <img src={emptyIcon} alt="transaction-icon" className="mx-auto" />
      )}
      {emptyTextMain && (
        <p className={`${noTsxTextMainStyle}`}>{emptyTextMain}</p>
      )}
      {emptyText && (
        <p className={`${noTsxTextStyle} ${className}`}>{emptyText}</p>
      )}
    </div>
  );
}

export default EmptyState;
