import { useRef } from "react";
import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import copyGreenIcon from "../../../../../assets/icons/copyGreen.svg";
import { handleCopy } from "../../../../../components/custom-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const labelStyle = "text-[12px] tracking-wide leading-[16px] font-[500] text-gs-subText";
const pStyle = "text-[13.5px] leading-[22px] font-bold flex items-center gap-x-1";

interface AccountDetailsModalProps {
    data: any,
    closeModal: () => void
}


function AccountDetailsModal({ data, closeModal }: AccountDetailsModalProps) {
    const acctNumberRef: any = useRef(null);
    const bankNameRef: any = useRef(null);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);

    return (
        <div>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem] ">Bank Account details </h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <p className="text-[14px] font-[500] leading-[2rem] text-gs-subSubText mb-[1.2rem]  mt-[1.5]">Top up your wallet by making a transfer to your Bank account</p>
            
            <div className="grid gap-y-[1.5rem]">
                <div className="grid">
                    <label className={`${labelStyle}`}>ACCOUNT HOLDER</label>
                    <p className={`${pStyle} capitalize`}>{userInfo.fullName}</p>
                </div>
                <div className="grid">
                    <label className={`${labelStyle}`}>ACCOUNT NUMBER</label>
                    <p className={`${pStyle}`}>
                        <p ref={acctNumberRef}>{userInfo.Accounts[0].accountNumber || "*********"}</p>
                        <img
                         src={copyGreenIcon} 
                         alt="copy" 
                         onClick={() => handleCopy(acctNumberRef)}
                         className="hover:cursor-pointer" 
                        />
                    </p>
                </div>
                <div className="grid">
                    <label className={`${labelStyle}`}>BANK NAME</label>
                    <p className={`${pStyle}`}>
                        <p ref={bankNameRef}>{userInfo.Accounts[0].bankName || data.bank}</p>
                        <img 
                            src={copyGreenIcon} 
                            alt="copy" 
                            onClick={() => handleCopy(bankNameRef)}
                            className="hover:cursor-pointer" 
                        />
                    </p>
                </div>
            </div>
            <div className="border border-dashed border-gs-blue bg-gs-blueLess1 rounded-[8px] mt-5 p-3.5">
                <p className="text-[15px] font-bold mb-3 tracking-wide">Note</p>
                <ul className="text-[12px] list-disc ml-3">
                    <li className="font-extralight">The account can only receive funds in <span className="font-semibold">Nigerian Naira (NGN).</span></li>
                    <li className="font-extralight">Payments will take a few mins to reflect. </li>
                    <li className="font-extralight">There are no additional fees on deposits.</li>
                </ul>
            </div>
        </div>
    )
}

export default AccountDetailsModal;