import { useEffect, useState } from "react";
import { CustomSwitch } from "../../../../components/ui";



function NotificationsTab() {
    const [loginChecked, setLoginChecked] = useState({
        push: true,
        email: false
    });
    const [investmentChecked, setInvestmentChecked] = useState({
        push: true,
        email: false
    });
    const [projectsChecked, setProjectsChecked] = useState({
        push: true,
        email: false
    });
    const [transactionChecked, setTransactionChecked] = useState({
        push: true,
        email: false
    });

    useEffect(() => {
    }, []);

    useEffect(() => {
        // make request to API on Change
    }, [loginChecked]);

    useEffect(() => {
        // make request to API on Change
    }, [investmentChecked]);

    useEffect(() => {
        // make request to API on Change
    }, [projectsChecked]);

    useEffect(() => {
        // make request to API on Change
    }, [transactionChecked]);


    const pStyle = "text-[18px] leading-[22px] font-[600] text-[#1B212D]";
    const labelStyle = "text-[14px] leading-[20px] font-[300] text-[#929EAE] tracking-wide";
    return (
        <div className="pt-[2.2rem] pb-[4rem] px-[1.8rem] bg-white border border-gs-borderInput rounded-[8px] grid gap-y-[2.1rem]">
            
            <div className="grid lg:grid-cols-6 grid-cols-1">
                <div className="col-span-1">
                    <p className={`${pStyle}`}>General Notification</p>
                    <label className={`${labelStyle}`}>Select where you want to be notified </label>
                </div>

                <div className="lg:col-span-1"></div>

                <div className="lg:col-span-4 col-span-1 grid gap-y-[3rem]">            
                    <div className="grid grid-cols-5 gap-x-[6rem] items-center">
                        <div className="col-span-2">
                            <p className={`${pStyle} mb-2`}>Login Alerts</p>
                            <label className={`${labelStyle}`}>Notifications on successful logins to your account</label>
                        </div>
                        <div className="col-span-3 flex gap-x-[3rem] items-center">
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                    checked={loginChecked.push}
                                    handleChange={() => setLoginChecked({...loginChecked, push: !loginChecked.push})}
                                />
                                <label className={`${labelStyle}`}>Push up</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                    checked={loginChecked.email}
                                    handleChange={() => setLoginChecked({...loginChecked, email: !loginChecked.email})}
                                />
                                <label className={`${labelStyle}`}>Email</label>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-5 gap-x-[6rem] items-center">
                        <div className="col-span-2">
                            <p className={`${pStyle} mb-2`}>Transaction Alerts</p>
                            <label className={`${labelStyle} mt-1`}>Notifications on the successful status of your transactions</label>
                        </div>
                        <div className="col-span-3 flex gap-x-[3rem] items-center">
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                 checked={transactionChecked.push}
                                 handleChange={() => setTransactionChecked({...transactionChecked, push: !transactionChecked.push})}
                                />
                                <label className={`${labelStyle}`}>Push up</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                 checked={transactionChecked.email}
                                 handleChange={() => setTransactionChecked({...transactionChecked, email: !transactionChecked.email})}
                                />
                                <label className={`${labelStyle}`}>Email</label>
                            </div>
                        </div>
                    </div>


                    <div className="grid grid-cols-5 gap-x-[6rem] items-center">
                        <div className="col-span-2">
                            <p className={`${pStyle} mb-2`}>Investment Alerts</p>
                            <label className={`${labelStyle}`}>Notifications on the successful status of your investments</label>
                        </div>
                        <div className="col-span-3 flex gap-x-[3rem] items-center">
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                 checked={investmentChecked.push}
                                 handleChange={() => setInvestmentChecked({...investmentChecked, push: !investmentChecked.push})}
                                />
                                <label className={`${labelStyle}`}>Push up</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                 checked={investmentChecked.email}
                                 handleChange={() => setInvestmentChecked({...investmentChecked, email: !investmentChecked.email})}
                                />
                                <label className={`${labelStyle}`}>Email</label>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-5 gap-x-[6rem] items-center">
                        <div className="col-span-2">
                            <p className={`${pStyle} mb-2`}>Projects Alerts</p>
                            <label className={`${labelStyle}`}>Notifications on the successful status of your projects</label>
                        </div>
                        <div className="col-span-3 flex gap-x-[3rem] items-center">
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                    checked={projectsChecked.push}
                                    handleChange={() => setProjectsChecked({...projectsChecked, push: !projectsChecked.push})}
                                />
                                <label className={`${labelStyle}`}>Push up</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                                <CustomSwitch 
                                    checked={projectsChecked.email}
                                    handleChange={() => setProjectsChecked({...projectsChecked, email: !projectsChecked.email})}
                                />
                                <label className={`${labelStyle}`}>Email</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsTab