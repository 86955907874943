import { useState } from "react";
import { getDateString, getTimeFormat } from "../../../components/custom-hooks";
import { CustomModal } from "../../../components/ui";
import TransactionPopup from "./TransactionPopup";
import EmptyState from "../../../components/ui/EmptyState";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface TransactionProps {
  Date: string | Date;
  statusType: string;
  serviceType: string;
  amount: number | string;
  reference: string;
  status: string;
  isForeign: boolean;
}

interface DataProps {
  data: TransactionProps[];
  emptyIcon: any;
  emptyText: string;
  emptyTextMain?: string;
  onClick?: boolean;
}

const tableHeaderStyle =
  "text-[#929EAE] text-[14px] leading-[17.5px] font-semibold";
const tableBodyStyle = "text-[14px] leading-[21px]";

function TransactionTable({
  data,
  emptyIcon,
  emptyText,
  emptyTextMain,
  onClick,
}: DataProps) {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo)
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const handleClick = (_arg: any) => {
    if (!onClick) return;
    setToggle(true);
    setSelected(_arg);
  };

  return (
    <div className="relative">
      <>
        {data?.length > 0 ? (
          <ul className="grid lg:w-full md:w-[1024px] overflow-x-auto mt-5 ">
            {/* table header */}
            <li className="flex py-2.5">
              <div className={`${tableHeaderStyle} w-[15%]`}>DATE</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>TYPE</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>AMOUNT</div>
              <div className={`${tableHeaderStyle} w-[20%]`}>SERVICE</div>
              <div className={`${tableHeaderStyle} w-[25%]`}>REFERENCE</div>
              <div
                  className={`${tableHeaderStyle} w-[20%] flex justify-center`}
              >
                STATUS
              </div>
            </li>
            {data.slice(0, 5).map((item: TransactionProps, index: number) => {
              const statusStyle =
                item.status.toLowerCase() === "pending"
                  ? "text-gs-amber bg-gs-amberLess1"
                  : item.status.toLowerCase() === "successful"
                  ? "text-gs-primary bg-gs-primaryLess1"
                  : "text-gs-red bg-gs-redLess1";

              return (
                  <li
                      key={index}
                      className={`flex items-center py-2.5 border-t border-[#F5F5F5] ${
                          onClick && "hover:cursor-pointer"
                      }`}
                      onClick={() => handleClick(item)}
                  >
                    <div className={`${tableBodyStyle} w-[15%]`}>
                      <p className="text-gs-text font-semibold">
                        {getDateString(item.Date)}
                      </p>
                      <p className="text-gs-subText font-normal">
                        {getTimeFormat(item.Date)}
                      </p>
                    </div>
                    <div className={`${tableBodyStyle} w-[20%] capitalize `}>
                      <p className="w-fit px-[30px] py-[6px] text-gs-subSubText bg-[#EDF1F3] rounded-[4px]">
                        {item.statusType}
                      </p>
                    </div>
                    <div
                        className={`${tableBodyStyle} w-[20%] text-gs-text font-semibold`}
                    >
                      {!item.isForeign ? userInfo.localCurrencySymbol : userInfo.foreignCurrencySymbol} {item.amount}
                    </div>
                    <div className={`${tableBodyStyle} w-[20%] capitalize `}>
                      <p className="w-fit px-[30px] py-[6px] text-gs-subSubText bg-[#EDF1F3] rounded-[4px]">
                        {item.serviceType}
                      </p>
                    </div>
                    <div className={`${tableBodyStyle} w-[25%]`}>
                      {item.reference}
                    </div>
                    <div
                        className={`${tableBodyStyle} w-[20%] flex justify-center capitalize font-semibold `}
                    >
                      <p
                          className={`${statusStyle} w-fit px-[30px] py-[6px] rounded-[4px]`}
                      >
                        {item.status}
                      </p>
                    </div>
                  </li>
              );
            })}
          </ul>
        ) : (
            <EmptyState
                emptyIcon={emptyIcon}
                emptyText={emptyText}
                emptyTextMain={emptyTextMain}
            />
        )}
      </>
      {toggle && (
          <CustomModal
          modalStyle="lg:w-[35%] w-[85%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setToggle(false)}
        >
          <TransactionPopup
            setToggle={() => setToggle(false)}
            data={selected}
          />
        </CustomModal>
      )}
    </div>
  );
}

export default TransactionTable;
