import React, {useState} from "react";
import {Button} from "../../../../../components/ui"; // Ensure you have these components or adjust imports
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import {ScaleLoader} from "react-spinners"; // Adjust if needed

interface ModaRequestLoanProps {
    closeModal: () => void;
}

const inputStyle = "flex items-center w-full border rounded-[15px]";
const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const formatNumber = (value: number | string) => {
    if (value === '' || isNaN(Number(value))) return '';
    return Number(value).toLocaleString('en-US');
};

const parseNumber = (value: string) => {
    return Number(value.replace(/,/g, ''));
};

const ModaRequestLoan = ({closeModal}: ModaRequestLoanProps) => {
    const [amount, setAmount] = useState<number>(0);
    const [reason, setReason] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const userService = new UserService(); // Initialize your user service here

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = parseNumber(value);
        setAmount(numericValue);
    };

    const handleRequestLoan = async () => {
        if (Number(amount) <= 0) {
            NotificationService.error({message: "Please fill in all fields correctly."});
            return;
        }

        setLoading(true);

        try {
            const response = await userService.requestLoan({amount});
            if (response.status) {
                NotificationService.success({message: response.message});
                closeModal(); // Close the modal on success
            } else {
                NotificationService.error({message: response.message});
                closeModal();
            }
        } catch (error) {
            NotificationService.error({message: 'An error occurred while requesting the loan.'});
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <div>
            <h2 className="text-[18px] font-bold">Request Loan</h2>
            <div className="mt-7">
                <label className={`${labelStyle}`}>Loan amount</label>
                <div
                    className={`${inputStyle} p-[12px] 'border-gs-borderInput' gap-x-3`}>
                    <div className="text-gs-subText">&#8358;</div>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        placeholder="0.00"
                        value={formatNumber(amount)}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="flex justify-end gap-4 mt-6">
                <Button
                    btnStyle="py-2 px-4"
                    isInverted={true}
                    handleClick={closeModal}
                    text="Cancel"
                    category="button"
                />
                <Button
                    btnStyle="py-2 px-4"
                    isInverted={false}
                    handleClick={handleRequestLoan}
                    text={loading ? <ScaleLoader color='#fff' height={12} width={4}/> : "Request"}
                    disabled={loading}
                    category="button"
                />
            </div>
        </div>
    );
};

export default ModaRequestLoan;
