import { useState } from "react";
import arrowDown from "../../../../../assets/icons/arrow-down.svg";
import polygonIcon from "../../../../../assets/icons/polygon.svg";
import { months } from "../../../../../utils/constants";

interface SelectProps {
    selectStyle?: string,
    setMonth: (data: string) => void
}

function MonthSelect({ selectStyle, setMonth }: SelectProps) {
    const [ toggle, setToggle ] = useState(false);

    const listItemStyle = "capitalize flex font-normal gap-x-3 text-[#9096A2] items-center hover:bg-gs-grayLess1 hover:cursor-pointer  px-4 py-1.5 text-[13px] tracking-wide";
    return (
        <div className="relative">
            <div 
                onClick={() => setToggle(!toggle)}
                className={`${selectStyle} flex justify-between items-center tracking-wide  gap-x-1.5 bg-gray-100 border border-gray-200 p-[10px] text-[12px] text-gs-subSubText rounded-[14.5px] hover:cursor-pointer`}>
                    <p>This month</p>
                    <img src={arrowDown} alt="down" className="h-[18px] w-[18px]" />
            </div>

            { toggle &&
                <div className="absolute z-[999] w-[170px] top-8 right-0">
                 <img
                     src={polygonIcon}
                     alt="angle"
                     className="relative z-2 justify-center h-[12px] mx-auto top-1 mb-0" 
                     />
                 <ul className="border-[.5px] border-[#E4E4E7] py-2 w-full bg-white shadow-md h-[200px] overflow-y-auto rounded-[12px]">
                    {months.map((month, index) => (
                        <li key={index} className={`${listItemStyle}`} onClick={() => setMonth(month)}>{month}</li>
                    ))}
                 </ul>
             </div>
            }
        </div>
    )
}

export default MonthSelect;