import closeIcon from "../../../../../assets/icons/closeIcon.svg";

const labelStyle = "text-[16px] tracking-wide leading-[20.8px] font-[700] text-gs-text";
const pStyle = "text-[14px] leading-[22px] font-[300] flex items-center gap-x-1 text-gs-subSubText";
const boxStyle = "border rounded-[8px] border-gs-primaryLess1 p-[20px] mt-5 hover:cursor-pointer";

interface TransferModalProps {
    data?: any,
    closeModal: () => void;
    setTransferTab: (data: number) =>  void;
}

function TransferModal({ closeModal, setTransferTab }: TransferModalProps) {
    return (
        <div>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <h2 className="text-[18px] font-bold leading-[1rem] ">Transfer</h2>
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>
            <div onClick={() => setTransferTab(1)} className={`${boxStyle}`}>
                <label className={`${labelStyle}`}>Send to loan wallet</label>
                <p className={`${pStyle}`}>Swap fund to your loan wallet</p>
            </div>
            <div onClick={() => setTransferTab(2)} className={`${boxStyle}`}>
                <label className={`${labelStyle}`}>Send to GreenSwift user</label>
                <p className={`${pStyle}`}>Send funds to your friend using GreenSwift</p>
            </div>
            <div onClick={() => setTransferTab(3)} className={`${boxStyle}`}>
                <label className={`${labelStyle}`}>Send to Bank Account</label>
                <p className={`${pStyle}`}>Enter bank account details to withdraw funds </p>
            </div>
        </div>
    )
}


export default TransferModal;