import emptyTransactionIcon from '../../../../assets/icons/emptyTransaction.svg';
import { getDateString, getTimeFormat } from '../../../../components/custom-hooks';
import { TransactionTable } from '../../components';

interface TransactionProps {
    date: string;
    type: string,
    amount: number,
    reference_no: string,
    status: string;
}

function TransactionSection({ data }: any) {
    const containerStyle = "w-full bg-white border border-gs-borderInput rounded-[14.5px] p-[2rem]";
    const h2Style = 'text-[#1A1C1E] text-[18px] leading-[27px] font-bold';


    return (
        <div className={containerStyle}>
            <h2 className={h2Style}>Recent Transactions</h2>
            <TransactionTable
                data={data}
                emptyIcon={emptyTransactionIcon}
                emptyText="You have not made any transaction yet"
             />
        </div>
    )
}


export default TransactionSection;