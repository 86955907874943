import { AppLayout } from "../../../../components/layouts";
import NewProjectFormStep from "./components/NewProjectFormStep";

function NewProject() {
  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Add new project
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <NewProjectFormStep />
      </div>
    </AppLayout>
  );
}

export default NewProject;
