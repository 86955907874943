import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import CardType from "./components/CardType";
import CardTransactions from "./components/CardTransactions";
import { useDispatch } from "react-redux";
import UserService from "../../../../services/user.service";
import { setUserInfo } from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";

const transactions: any = [
  // {
  //     date: new Date(),
  //     type: 'deposit',
  //     amount: 244.80,
  //     reference_no: 'NMCC5HLQC2U',
  //     status: 'pending'
  // },
  // {
  //     date: new Date(),
  //     type: 'deposit',
  //     amount: 244.80,
  //     reference_no: 'NMCC5HLQC2U',
  //     status: 'success'
  // },
];

function Transfer() {
  const [tab, setTab] = useState(0);

  
  const dispatch = useDispatch(); 
  const userService = new UserService();

  const getUserInfo = async() => {
    try {
      const response = await userService.getUserInfo();
      if(response.status){
          dispatch(setUserInfo(response.data));
      }
    }catch(err: any){
      NotificationService.error({
          message: err.message
      })
    }
  }

  useEffect(() => {
      getUserInfo();
  }, [])

  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Transfer
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <CardType tab={tab} setTab={setTab} />
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <CardTransactions data={transactions} />
      </div>
    </AppLayout>
  );
}

export default Transfer;
