import { useEffect, useState } from "react";
import { AppLayout } from "../../../../components/layouts";
import WalletBalance from "./WalletBalance";
import Transactions from "./components/Transactions";
import emptyTransactionIcon from "../../../../assets/icons/no-transaction.svg";
import UserService from "../../../../services/user.service";
import NotificationService from "../../../../services/notification.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../../redux/reducers/authReducer";



function Wallet() {
  const userService = new UserService();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<any>([]);



  const getTransactions = async() => {
    setLoading(true)
    try{
      const response = await userService.allTransactions();
      setLoading(false);
      if(!response.status){
          NotificationService.error({  message: response?.message })
          return;
      }
      if(response.data.length > 0){
        const trimmedTransactions = response?.data?.data?.slice(0, 7)
        setTransactions(trimmedTransactions);
      }else{
        setTransactions(response?.data?.data)
      }
    }catch(err: any){
      setLoading(false);
      NotificationService.error({
          message: err?.message
      });
    }
  }

  useEffect(() => {
    getTransactions();
  }, []);
 

  return (
    <AppLayout
      text={
        <h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">
          Wallet
        </h2>
      }
    >
      <div className="px-[2rem] py-[4rem] md:w-full w-fit">
        <WalletBalance />
      </div>
      <div className="px-[2rem] py-[4rem] w-full -mt-[6rem]">
        <Transactions
          data={transactions}
          title="Recent transactions"
          emptyText="You have not made any transaction yet"
          emptyTextMain="No Transactions"
          emptyIcon={emptyTransactionIcon}
        />
      </div>
    </AppLayout>
  );
}

export default Wallet;
