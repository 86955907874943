import Home from "./home/Home";
import Investment from "./investment/Investment";
import P2P from "./p2p/P2P";
import Marketplace from "./projects/Marketplace";
import MyProjects from "./projects/MyProjects";
import Settings from "./settings/Settings";
import Transfer from "./transfer/Transfer";
import Wallet from "./wallet/Wallet";
import Messages from "./messages/Messages";
import SingleInvestment from "./investment/SingleInvestment";
import InvestmentPortfolio from "./investment/InvestmentPortfolio";
import InvestMoreSingle from "./investment/InvestMoreSingle";
import NewProject from "../pages/projects/NewProject";
import SingleMarketInvestment from "../pages/projects/SingleMarketInvestment";

export {
  Home,
  Investment,
  P2P,
  Marketplace,
  MyProjects,
  Settings,
  Transfer,
  Wallet,
  Messages,
  SingleInvestment,
  InvestmentPortfolio,
  InvestMoreSingle,
  NewProject,
  SingleMarketInvestment,
};
