import trfIcon from "../../../assets/icons/transfer.svg";
import savingsIcon from "../../../assets/icons/stack.svg";
import walletIcon from "../../../assets/icons/wallet.svg";
import scanIcon from "../../../assets/icons/scan.svg";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function SectionThree() {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section
            className="pt-[4rem] px-[2rem] md:px-[4rem] md:mt-0 -mt-[4rem] mb-[8rem]"
            >
             <div data-aos="fade-up" className="w-fit bg-gs-grayLess2 rounded-[80px] px-7 py-1.5 flex items-center mt-[3rem] mb-5 font-extralight">
                <p>Our Services ✨</p>
            </div>
            <h2 data-aos="fade-up" className="lg:w-[40%] w-full font-bold mb-[1.5rem] md:text-[45px] text-[40px] leading-[60px]">Discover Our Range of Exceptional Services</h2>
            <p data-aos="fade-up" className="text-[18px] font-[300] md:mb-[2.7rem] mb-[1.7rem] leading-8">At GreenSwift, we stay ahead of the curve by offering:</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[3rem] gap-y-[2rem]">

                <div data-aos="fade-up" className="rounded-[19px] bg-gs-primary px-7 pt-7 pb-[3rem]">
                    <img src={trfIcon} alt="transfer" />
                    <h3 className="text-[20px] font-bold px-3 py-1.5 border-l-[2px] border-white text-white mt-[3rem]">Secured Transactions</h3> 
                    <p className=" text-white/[.8] text-[16px] font-extralight leading-[32px] mt-[2rem]">Every transaction is safeguarded with advanced encryption & authentication.</p>
                </div>

                <div data-aos="fade-up" className="rounded-[19px] bg-gs-grayLess1 px-7 pt-7 pb-[3rem]">
                    <img src={savingsIcon} alt="savings" />
                    <h3 className="text-[20px] font-bold px-3 py-1.5 border-l-[2px] border-gs-red mt-[3rem]">Financial Wellness</h3>
                    <p className=" text-gs-black/[.8] text-[16px] font-extralight leading-[32px] mt-[2rem]">Cultivate the discipline of consistency with our user-friendly savings scheme.</p>
                </div>

                <div data-aos="fade-up" className="rounded-[19px] bg-gs-grayLess1 px-7 pt-7 pb-[3rem]">
                    <img src={walletIcon} alt="wallet" />                    
                    <h3 className="text-[20px] font-bold px-3 py-1.5 border-l-[2px] border-gs-red mt-[3rem]">Smart Investments</h3>
                    <p className=" text-gs-black/[.8] text-[16px] font-extralight leading-[32px] mt-[2rem]">Unlock the potential of your money with our tailored investment options.</p>
                </div>

                <div data-aos="fade-up" className="rounded-[19px] bg-gs-grayLess1 px-7 pt-7 pb-[3rem]">
                    <img src={scanIcon} alt="scan" />       
                    <h3 className="text-[20px] font-bold px-3 py-1.5 border-l-[2px] border-gs-red mt-[3rem]">Scan & Transact</h3>             
                    <p className=" text-gs-black/[.8] text-[16px] font-extralight leading-[32px] mt-[2rem]">Transact with our cutting-edge scan feature; convenience is at your fingertips</p>
                </div>
            </div>         
        </section>
    )
}

export default SectionThree;