import { useParams } from "react-router-dom";
import funds from "../../../../../utils/funds";
import { Button, CustomModal } from "../../../../../components/ui";
import SlantArrow from "../../../../../assets/icons/SlantArrow.svg";
import { useState } from "react";
import InvestmentModal from "./InvestmentModal";
import Show from "../../../../../assets/icons/eye.svg";

function SingleInvestMoreItem() {
  const [showModal, setShowModal] = useState(false);
  const { investmentId }: any = useParams();
  const selectedFund = funds.find(
    (fund) => fund.id === parseInt(investmentId, 10)
  );
  if (!selectedFund) {
    return <div>Fund not found</div>;
  }

  // REUSABLE STYLING
  const headings = "font-medium text-gs-subText";
  const growthYear = "font-bold text-gs-text mb-[9px]";
  const growthYearPercent = "text-[#6fd25e] font-bold";
  const descHeading = "font-bold text-2xl text-gs-text";
  const desc = "text-gs-subSubText leading-[39.5px]";

  return (
    <>
      <div className="w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem]">
        <div>
          <p className="font-medium text-gs-subSubText mb-[37px]">
            {selectedFund.shortName}
          </p>
          <div className="flex items-center gap-[149px]">
            <div className="flex gap-[15px] mt-5">
              <p className="text-gs-text text-2xl font-extrabold">₦0.00</p>
              <img src={Show} alt="Eye" />
            </div>
            <Button
              handleClick={() => setShowModal(true)}
              isInverted={false}
              category="button"
              text="Invest more"
              btnStyle="max-w-[143px]  w-full py-3 px-6 font-semibold text-normal !rounded-[14px]"
            />
          </div>
        </div>
        <hr className="border border-gs-borderInput mt-6 mb-[41px]" />
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-[25px]">
            <img
              src={selectedFund.logo}
              alt="company logo"
              className="w-[77px] h-[77px]"
            />
            <div>
              <h4 className="text-2xl font-bold text-gs-text">
                {selectedFund.company}
              </h4>
              <p className="font-medium text-sm text-gs-subSubText">
                {selectedFund.companyDesc}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-[41px]">
          <div>
            <p className={headings}>Returns</p>
            <p className="font-bold text-[32px] text-[#6fd25e]">
              {selectedFund.returnPercent}
            </p>
          </div>
          <div className="max-w-[148px] w-full">
            <p className={`${headings} -mt-3 mb-3`}>Risks level</p>
            <div className="flex items-center gap-2">
              <img src={selectedFund.riskIcon} alt="risk level" />
              <p
                className={`${
                  selectedFund.risk === "Very High"
                    ? "!text-[#C84308]"
                    : "text-gs-subSubText"
                } font-medium text-xs`}
              >
                {selectedFund.risk}
              </p>
            </div>
          </div>
          <div className="max-w-[275px] w-full">
            <p className={headings}>Growth evaulation</p>
            <div className="flex gap-7">
              <div>
                <p className={growthYear}>{selectedFund.growthYear1}</p>
                <p className={growthYearPercent}>
                  {selectedFund.growthYearPercent}
                </p>
              </div>
              <div>
                <p className={growthYear}>{selectedFund.growthYear2}</p>
                <p className={growthYearPercent}>
                  {selectedFund.growthYearPercent}
                </p>
              </div>
              <div>
                <p className={growthYear}>{selectedFund.growthYear3}</p>
                <p className={growthYearPercent}>
                  {selectedFund.growthYearPercent}
                </p>
              </div>
            </div>
          </div>
          <div className="p-[15px] rounded-[10px] max-w-[315px] w-full bg-gs-blueLess1">
            <p className="text-sm text-gs-text">
              Lorem ipsum dolor sit amet consectetur. Risus morbi ultrices sit
              hac condimentum adipiscing. Risus auctor.
            </p>
          </div>
        </div>
        <div className="mt-[41px] flex flex-col gap-[41px]">
          <div>
            <h4 className={descHeading}>About this fund</h4>
            <p className={desc}>{selectedFund.about}</p>
          </div>
          <div>
            <h4 className={descHeading}>Suitability</h4>
            <p className={desc}>{selectedFund.suitability}</p>
          </div>
          <div>
            <h4 className={descHeading}>Terms Of Use</h4>
            <p className={desc}>{selectedFund.termOfUse}</p>
          </div>
        </div>
        <div className="border border-[rgba(11,124,66,0.20)] py-4 pl-6 pr-4 mt-[41px] rounded-lg">
          <div className="flex justify-between items-center">
            <p>Details</p>
            <Button
              isInverted={false}
              category="button"
              text="Download Prospectus"
              btnStyle="py-3 px-6 font-medium text-xs !rounded-[100px]"
            />
          </div>
          <hr className="border-gs-greyscaleGrey200 my-[25px]" />
          <div className="flex justify-between">
            <div>
              <p className={headings}>Current</p>
              <p className="font-bold text-gs-text">{selectedFund.current}</p>
            </div>
            <div>
              <p className={headings}>Current value</p>
              <p className="font-bold text-gs-text">
                {selectedFund.currentValue}
              </p>
            </div>
            <div>
              <p className={headings}>Current value</p>
              <p className="font-bold text-gs-text">
                {selectedFund.currentValue}
              </p>
            </div>
            <div>
              <p className={headings}>Returns</p>
              <div className="flex items-center">
                <img src={SlantArrow} alt="arrow" />
                <p className="font-semibold text-gs-primary500 text-sm">
                  {selectedFund.returnPercent}
                </p>
              </div>
            </div>
            <div className="max-w-[148px] w-full">
              <p className={headings}>Risks</p>
              <div className="flex items-center gap-2">
                <img src={selectedFund.riskIcon} alt="risk level" />
                <p
                  className={`${
                    selectedFund.risk === "Very High"
                      ? "!text-[#C84308]"
                      : "text-gs-subSubText"
                  } font-medium text-xs`}
                >
                  {selectedFund.risk}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowModal(false)}
        >
          <InvestmentModal closeModal={() => setShowModal(false)} />
        </CustomModal>
      )}
    </>
  );
}

export default SingleInvestMoreItem;
