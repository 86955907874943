import MasterCardLogo from "../../../../../assets/icons/MasterCardLogo.svg";
import CardLogo from "../../../../../assets/icons/CardLogo.svg";
import swapIcon from "../../../../../assets/icons/swapIcon.svg";
import addIcon from "../../../../../assets/icons/addIcon.svg";
import { useState } from "react";
import SwapFundModal from "./SwapFundModal";
import { CustomModal } from "../../../../../components/ui";
import FundCardModal from "./FundCardModal";

interface Props {
  currency?: string;
  className?: string;
}

function Card({ currency, className }: Props) {
  const [ showFundCard, setShowFundCard ] = useState(false);
  const [ showSwapFunds, setShowSwapFunds ] = useState(false);

  const btnStyle = "flex items-center gap-x-2 px-[20px] py-[10px] border border-gs-primary rounded-[14px] text-[14.5px] tracking-wide";

  return (
    <div className="flex flex-col items-center">
      <div
        className={`${className} card-bg-img max-w-[438px] w-full h-[256px] rounded-[14.5px] pt-[19px] pl-[21px] pb-6 pr-[23px]`}
      >
        <div className="space-y-32">
          <div>
            <img src={CardLogo} alt="Logo" />
          </div>
          <div className="flex justify-between">
            <p className="font-bold text-4xl font-['Manrope'] text-white">
              {currency}
            </p>
            <img src={MasterCardLogo} alt="Card type logo" />
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-x-7 mt-[30px]">
        <button onClick={() => setShowFundCard(true)} className={`${btnStyle} bg-gs-primary text-white`}>
          <p>Fund card</p>
          <img src={addIcon} alt="add" />
        </button>
        <button onClick={() => setShowSwapFunds(true)} className={`${btnStyle} bg-white text-gs-primary`}>
          <p>Swap funds</p>
          <img src={swapIcon} alt="swap" />
        </button>
      </div>

      {showFundCard && 
       <CustomModal
          modalStyle="lg:w-[30%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowFundCard(false)}
        >
          <FundCardModal closeModal={() => setShowFundCard(false)} />
      </CustomModal>
      }

      {showSwapFunds && 
       <CustomModal
          modalStyle="lg:w-[30%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
          closeModal={() => setShowSwapFunds(false)}
        >
          <SwapFundModal closeModal={() => setShowSwapFunds(false)} />
      </CustomModal>
      }
    </div>
  );
}

export default Card;
