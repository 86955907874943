import { Link } from "react-router-dom";
import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import SignInForm from "../components/SignInForm";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function SignIn() {
    useEffect(() => {
        AOS.init();
      }, []);

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div data-aos="fade-left" className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader title="Jump right back in!" />
          <SignInForm />
          <div>
            <p className="text-center font-semibold text-gs-subSubText leading-5">
              Are you a new user?{" "}
              <Link to="/auth/signup" className="text-gs-primary">
                Create an account{" "}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SignIn;
