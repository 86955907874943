import { useEffect } from "react";
import phoneImg from "../../../assets/images/mobile.png";
import { Button } from "../../../components/ui";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';

function SectionTwo() {

    useEffect(() => {
        AOS.init();
      }, []);

    return  (
        <section    
            className=" md:mt-0 mt-[53.5rem] pt-[8rem] pb-[4rem] md:px-[4rem] px-[1.5rem] w-full"
            >
            <div className="grid md:grid-cols-2 grid-cols-1 ">
                <div className="md:order-1 order-2 col-span-1">
                    <div data-aos="fade-up" className="bg-gs-grayLess2 w-fit px-[5rem] py-[4rem] rounded-[30px] flex items-center justify-center">
                        <img src={phoneImg} alt="phone" />
                    </div>
                </div>
                <div className="md:order-2 order-1 col-span-1 ">
                    <div className="w-fit h-fit block items-center md:px-[5rem]  md:pt-[5rem] md:pb-[5rem] pt-[5rem] pb-[3rem] ">
                        <div data-aos="fade-up" className="w-fit bg-gs-grayLess2 rounded-[80px] px-7 py-1.5 flex items-center mt-[3rem] mb-5 font-extralight">
                            <p>Our best features ✨</p>
                        </div>
                        <h2 data-aos="fade-up" className="font-bold mb-[1.5rem] md:text-[45px] text-[40px] leading-[60px]">Swift and seamless transaction</h2>
                        <p data-aos="fade-up" className="text-[18px] font-[300] md:mb-[2.7rem] mb-[1.7rem] leading-8">Time is of the essence in a fast-paced world. The pursuit of swift and seamless solutions has become a universal goal.  GreenSwift is a tapestry of innovation woven with the threads of speed and smoothness.</p>
                        <div data-aos="fade-left" className="lg:w-[40%] w-[60%]">
                            <Button 
                                category="link"
                                to="/"
                                btnStyle="lg:px-6 md:px-10 px-5 py-4 text-center border-gs-primary tracking-wider"
                                isInverted={false}
                                text="Get started"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionTwo;