import { useEffect, useState } from "react";
import ShowPassword from "../../../assets/icons/eye-slash.svg";
import HidePassword from "../../../assets/icons/eye.svg";

// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";
const errorStyling = "text-[12px] leading-[14px] text-red-700";

// COMPONENT

function PasswordField({ field, form }: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [indicator, setIndicator] = useState(new Array(4).fill(false));

  const calculatePasswordStrength = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password) ? 1 : 0;
    const hasLowercase = /[a-z]/.test(password) ? 1 : 0;
    const hasNumber = /\d/.test(password) ? 1 : 0;
    const length = password.length;

    return { hasUppercase, hasLowercase, hasNumber, length };
  };

  const getStrengthLabel = () => {
    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(form.values.password);
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    if (length < 8) {
      return "Very Weak";
    } else if (strength < 0.6) {
      return "Weak";
    } else if (strength < 0.8) {
      return "Strong";
    } else {
      return "Very Strong";
    }
  };

  useEffect(() => {
    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(form.values.password);

    // Calculated the password strength
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    // Updated the indicator state based on the calculated strength
    const filledIndicators = Array.from(
      { length: 4 },
      (_, i) => i < Math.ceil(strength * 4) && length >= (i + 1) * 2
    );
    setIndicator(filledIndicators);
    if (length <= 12) {
    }
  }, [form.values.password]);

  return (
    <>
      {/* PASSWORD */}
      <div className={labelContainerStyling}>
        <label htmlFor="password" className={labelStyling}>
          Password
        </label>
        <p className={errorStyling}>{form.errors.password}</p>
        <div className={`${inputContainerStyling} flex`}>
          <input
            id="password"
            name={field.name}
            type={showPassword ? "text" : "password"}
            placeholder="*******"
            className={fieldStyling}
            value={form.values[field.name]}
            onChange={({ target }) =>
              form.setFieldValue(field.name, target.value)
            }
          />
          <img
            src={showPassword ? ShowPassword : HidePassword}
            alt="Show Password"
            className="w-6 h-6 cursor-pointer"
            onClick={() => setShowPassword((prev: boolean) => !prev)}
          />
        </div>
      </div>

      {/* PASSWORD STRENGTH */}

      <div>
        <p className="text-xs text-gs-subSubText">
          Min 8 Characters with a combination of letters and numbers
        </p>
        <div className="flex gap-1 mt-[13px]">
          {indicator.map((isFilled, i) => (
            <div
              key={i}
              className={`w-[90.3px] h-[5px] bg-gs-passwordIndicator rounded-[10px] ${
                isFilled ? "bg-gs-primary" : ""
              }`}
            />
          ))}
        </div>
        {form.values.password && (
          <p className="text-xs text-gs-subSubText mt-[10px]">
            {getStrengthLabel()}
          </p>
        )}
      </div>
    </>
  );
}

export default PasswordField;
