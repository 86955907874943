import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import QrImg from "../../../../../assets/images/qr.png";

interface RemoveModalProps {
    setActiveFaTab: (_arg:number) => void,
    closeModal: () => void;
}

function ScanQRCodeModal({ closeModal, setActiveFaTab }: RemoveModalProps) {
    const btnStyle =  "w-full px-[20px] py-[12px] font-[500] text-[15px] leading-[25.5px] rounded-[10px]";

    const handleDownload = () => {
        // link to download then close Modal
        closeModal()
    }

    return (
        <div>
            <div className="w-fit float-right mb-[1.2rem]">
                <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => closeModal()} 
                    className="hover:cursor-pointer" />
            </div>

            <img src={QrImg} alt="remove" className="mx-auto clear-both mt-3" />

            <h3 className="text-[18px] text-center font-[700] leading-[32px] text-gs-text my-2">Scan QRCode</h3>

            <p className=" w-full text-center text-[16px] font-[400] tracking-wide leading-[27.5px] text-gs-subSubText mt-2.5">
            From your device, launch Google Authenticator, and scan the QRCode above. Hit done, when you’ve added the key above successfully.
            </p>

            <div className="lg:w-[80%] mx-auto lg:flex grid gap-x-5 mt-7">
                <button onClick={() => setActiveFaTab(3)} className={`${btnStyle} bg-gs-primaryLess1 text-gs-primary`}>Set up manually</button>
                <button onClick={handleDownload} className={`${btnStyle} bg-gs-primary text-white`}>Done</button>
            </div>
        </div>
    )
}


export default ScanQRCodeModal;