import { AuthLayout } from "../../../components/layouts";
import AuthFormHeader from "../components/AuthFormHeader";
import CreateNewPasswordForm from "../components/CreateNewPasswordForm";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from "react";

function CreateNewPassword() {
  useEffect(() => {
    AOS.init();
  }, []); 
  
  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div data-aos="fade-left" className="bg-white px-10 pt-5 py-8 max-w-[547px] w-full rounded-2xl">
          <AuthFormHeader
            title="Create New Password"
            subtitle="Enter your new password that is unique  to you"
          />
          <CreateNewPasswordForm />
        </div>
      </div>
    </AuthLayout>
  );
}

export default CreateNewPassword;
