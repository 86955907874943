import React, { useEffect, useRef } from 'react';
import Chart, { ChartConfiguration, ChartOptions } from 'chart.js/auto';

interface GraphProps {
  data: number[];
}

const GraphLine: React.FC<GraphProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);


  const createGradient = (ctx: CanvasRenderingContext2D, colors: string[]) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    colors.forEach((color, index) => {
      gradient.addColorStop(index / (colors.length - 1), color);
    });
    return gradient;
  };
  

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          const chartConfig: ChartConfiguration = {
            type: 'line',
            data: {
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
              datasets: [{
                label: 'Money',
                data: data,
                borderColor: '#087139',
                borderWidth: 1,
                
                fill: true, // Fill the area under the line
                backgroundColor: createGradient(ctx, [ '#fff','#0871393b','#0871391c']),
              }],
            },
            options: {
              scales: {
                x: {
                  type: 'category',
                  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'], 
                  border: {
                    display: false,
                  },
                  ticks: {
                    font: {
                      size: 10
                    }
                  },
                  grid: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  labels: false,
                  border: {
                    display: false,
                  },
                  ticks: {
                    display: false
                  },
                  grid: {
                    display: false,
                  },
                },
              },
              elements: {
                line: {
                    tension: .08 // Set tension to 0 to remove curves in line chart
                  }
              },
              plugins: {
                legend: {
                  display: false, // Set to false to hide the legend
                },
              },
            } as ChartOptions,
          };

          // Destroy existing chart instance with a small delay
          if (chartInstance.current) {
            setTimeout(() => {
              chartInstance.current?.destroy();
            }, 1000000000);
          }

          // Create a new chart instance
          chartInstance.current = new Chart(ctx, chartConfig);
        }
      }
    };

    createChart();

    // Cleanup function to destroy the chart instance when the component is unmounted
    return () => {
      destroyChart();
    };
  }, [data]);

  // Add a destroyChart method
  const destroyChart = () => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
  };

  return (  
    <div style={{ width: '100%', height: '100%' }}>
      <canvas ref={chartRef}></canvas>
    </div>
   
  );
};

export default GraphLine;