import React,{useEffect} from "react";
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css';
import recievedGreen from "../../../assets/icons/money-recieved-green.svg";
import recievedRed from "../../../assets/icons/money-recieved-red.svg";
import recievedGray from "../../../assets/icons/money-recieved-gray.svg";

function SectionFive() {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <section
            className="md:px-[4rem] px-[2rem] md:py-[5rem] py-[3.7rem] w-full "
            >
            <h2 data-aos="fade-up" className="lg:w-[40%] w-full font-bold mb-[1.5rem] md:text-[45px] text-[40px] leading-[60px] ">Lorem ipsum dolor sit amet consect</h2>
            <p  data-aos="fade-up" className="text-[18px] font-[300] md:mb-[2.7rem] mb-[1.7rem] leading-8 ">Lorem ipsum dolor sit amet consectetur. Blandit vitae </p>   
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-x-[1.7rem] gap-y-[1.7rem]">

                <div data-aos="fade-up" className="col-span-1 bg-gs-primaryLess1 px-[1.2rem] py-[3.5rem] rounded-[30px] border-[1px] border-gs-primary/[.2]">
                    <div className="flex gap-x-4 items-center justify-center mb-4">
                        <img src={recievedGreen} alt="green" />
                        <h4 className="md:text-[30px] text-[20px] text-gs-primary leading-[58px] tracking-wide font-bold">550K</h4>
                    </div>
                    <p className="text-center text-gs-primary md:text-[18px] text-[15px] font-extralight">Lorem ipsum dolor sit amet consectetur. Blandit vitae socii</p>
                </div>

                <div data-aos="fade-up" className="col-span-1 bg-gs-red/[.1] px-[1.2rem] py-[3.5rem] rounded-[30px] border-[1px] border-gs-red/[.2]">
                    <div className="flex gap-x-4 items-center justify-center mb-4">
                        <img src={recievedRed} alt="red" />
                        <h4 className="md:text-[30px] text-[20px] text-gs-red leading-[58px] tracking-wide font-bold">550K</h4>
                    </div>
                    <p className="text-center text-gs-red md:text-[18px] text-[15px] font-extralight">Lorem ipsum dolor sit amet consectetur. Blandit vitae socii</p>
                </div>

                <div data-aos="fade-up" className="col-span-1 bg-[#1E2836]/[.08] px-[1.2rem] py-[3.5rem] rounded-[30px] border-[1px] border-[#1E2836]/[.2]">
                    <div className="flex gap-x-4 items-center justify-center mb-4">
                        <img src={recievedGray} alt="gray" />
                        <h4 className="md:text-[30px] text-[20px] text-[#1E2836] leading-[58px] tracking-wide font-bold">550K</h4>
                    </div>
                    <p className="text-center md:text-[18px] text-[15px] text-[#1E2836] font-extralight">Lorem ipsum dolor sit amet consectetur. Blandit vitae socii</p>
                </div>
            </div>    
        </section>
    )
}

export default SectionFive;