import React from 'react'

interface TabProps {
    tab: number;
    setTab: (data: number) => void;
  }

export default function Tabs({ tab, setTab }: TabProps) {

    const btnStyle = `text-[14px] leading-[25px] py-[6px] px-[20px] rounded-[14px]`;
    
    // const handleTabClick = (e: any, _arg: number) => {
    //   e.preventDefault();
    //   setTab(_arg);
    // };

    return (
        <div className="bg-gs-grayLess1 rounded-[14px] flex p-1">
          <button
            className={`${
              tab === 0
                ? "text-gs-primary font-[500] bg-white"
                : "text-gs-subText font-light bg-transparent"
            } ${btnStyle}`}
            onClick={() => setTab(0)}
          >
            Incoming
          </button>
          <button
            className={`${
              tab === 1
                ? "text-gs-primary font-[500] bg-white"
                : "text-gs-subText font-light bg-transparent"
            } ${btnStyle}`}
            onClick={() => setTab(1)}
          >
            Outgoing
          </button>
        </div>
    );
  
}
