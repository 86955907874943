import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "../../../components/ui";
import * as Yup from "yup";
import ShowPassword from "../../../assets/icons/eye-slash.svg";
import HidePassword from "../../../assets/icons/eye.svg";
import PasswordField from "./PasswordField";
import { useNavigate } from "react-router-dom";
import ls from 'localstorage-slim';
import NotificationService from "../../../services/notification.service";
import AuthService from "../../../services/auth.service";
import { ScaleLoader } from "react-spinners";

interface Values {
  password?: string;
  confirmPassword?: string;
}

// FORM VALIDATION
const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

const ValidationSchema = Yup.object({
  password: Yup.string()
    .required("Please enter a password")
    // check minimum characters
    .min(8, "Password must have at least 8 characters")
    // different error messages for different requirements
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
  confirmPassword: Yup.string()
    .required("Please re-type your password")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});


// LOCAL
ls.config.encrypt = true;


// COMPONENT
function CreateNewPasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authService = new AuthService();

  const initialValues: Values = { password: "", confirmPassword: "" };
  const labelContainerStyling = "flex flex-col gap-[10px]";
  const labelStyling = "text-sm leading-[14px] text-gs-text";
  const errorStyling = "text-sm leading-[14px] text-red-700";
  const inputContainerStyling =
    "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
  const fieldStyling =
    "w-full outline-none text-sm leading-[14px] text-gs-subText";

  const _email = ls.get('gs-u-email');

  const handleSubmit = async(values: Values) => {
    if(values.password && values.password === values.confirmPassword){
      const newPassword = values.password;
      const email: any = _email && _email?.toString();

      setLoading(true);
      try{
        const response = await authService.passwordReset({ email, newPassword})
        setLoading(false);
        if(!response.status){
          NotificationService.error({
            message: 'Password reset failed!',
            addedText: response?.message
          });
          return;
        }

        NotificationService.success({
          message: response?.message
        })
        navigate("/auth/signin");
      }catch(err){
        setLoading(false)
        console.log(err);
      }
    }

  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form className="py-8 flex flex-col gap-6">
          {/* PASSWORD */}
          <Field id="password" name="password" component={PasswordField} />

          {/* PASSWORD */}
          <div className={labelContainerStyling}>
            <label htmlFor="confirmPassword" className={labelStyling}>
              Confirm Password
            </label>
            {errors.confirmPassword && touched.confirmPassword ? (
              <p className={errorStyling}>{errors.confirmPassword}</p>
            ) : null}
            <div className={`${inputContainerStyling} flex`}>
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                placeholder="*******"
                className={fieldStyling}
              />
              <img
                src={showPassword ? ShowPassword : HidePassword}
                alt="Show Password"
                className="w-6 h-6"
                onClick={() => setShowPassword((prev: boolean) => !prev)}
              />
            </div>
          </div>

          <Button
            isInverted={false}
            type="submit"
            text={loading ? <ScaleLoader color='#fff' height={12} width={4} /> : "Continue"}
            category="button"
            btnStyle="w-full py-3 px-6"
          />
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewPasswordForm;
