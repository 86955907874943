import React, { ReactNode, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import ls from 'localstorage-slim';
import NotificationService from '../services/notification.service';
import { useDispatch } from 'react-redux';
import UserService from '../services/user.service';
import { setUserInfo } from '../redux/reducers/authReducer';


interface Props {
    children: ReactNode
}

ls.config.encrypt = true;


export default function AppMiddleware({ children }: Props) {
    const dispatch = useDispatch(); 
    const userService = new UserService();
    const authRoute = window.location.pathname.includes('/app/');
    let token = ls.get('gs-token');

  
    const getUserInfo = async() => {
      try {
        const response = await userService.getUserInfo();
        if(response.status){
          dispatch(setUserInfo(response?.data));
        }
      }catch(err: any){
        NotificationService.error({
          message: err.message
        })
      }
    }

    useEffect(() => {
        getUserInfo();
      }, [])

    // check for user authentication
    useEffect(() => {
        if (!token) {
            if(authRoute){
                NotificationService.error({ message: 'Session Expired! Login' }); 
                window.location.href = "/auth/signin";
                localStorage.clear();
            }
        }
    }, [token]);

    return (
        <div>{children}</div>
    )
}
